import { useState } from "react";
import { useFormik } from "formik";
import * as z from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  selectedPatientAtom,
  selectedTaskAtom,
  getLoggedUser,
  selectedPatientId,
  userListAtom,
} from "../../../services/state.service";
import {
  ITask,
  TaskType,
  ITaskStatus,
  TaskStatusType,
  EPatientTaskTypes,
  patientTaskTypes,
  staffTaskTypes,
  generalTaskTypes,
  EGeneralTaskTypes,
} from "../../../interfaces/task.interface";
import {
  HTTP_SERVICE,
  ErrorResponse,
  isErrorResponse,
} from "../../../services/http.service";
import { localFormat } from "../../../services/datetime.service";
import { ListOptions, readable } from "../../../interfaces/utils";
import {
  EFormType,
  FormType,
  formTypeOptions,
} from "../../../interfaces/questionnaire.interface";
import { IUser } from "../../../interfaces/user.interface";

interface TaskFormProps {
  closeForm: () => void;
}

type ITaskForm = Omit<ITask, "status"> & {
  status: TaskStatusType;
  formType?: FormType;
  sets?: number;
};

type TaskOption = {
  key: TaskType;
  value: string;
};

const getStatusObject = (
  status: TaskStatusType,
  user: string
): ITaskStatus => ({
  type: status,
  datetime: new Date().toISOString(),
  user,
});

const getTaskInitialValues = (task: Partial<ITask>): ITaskForm => {
  const { status, type, ...rest } = task;
  return {
    type: type ?? EGeneralTaskTypes.general as TaskType,
    start: "",
    end: "",
    user: "",
    patient: "",
    note: "",
    status: status ? status.type : "active",
    ...rest,
  } as ITaskForm;
};

export const TaskForm = ({ closeForm }: TaskFormProps) => {
  const user = useRecoilValue(getLoggedUser);
  const patientId = useRecoilValue(selectedPatientId);
  const users = useRecoilValue(userListAtom);
  const [patient, setPatient] = useRecoilState(selectedPatientAtom);
  const [selectedTask, setSelectedTask] = useRecoilState(selectedTaskAtom);
  const [selectedTaskType, setSelectedTaskType] = useState<
    TaskType | undefined
  >(selectedTask?.type ?? undefined);
  const [selectedTaskFormType, setSelectedTaskFormType] = useState<
    FormType | undefined
  >(
    selectedTask?.type === EPatientTaskTypes.submit_form
      ? selectedTask?.formType
      : undefined
  );

  const handleUpdateResponse = (task: ITask) => {
    if (task) {
      closeForm();
      setSelectedTask(null);
      setPatient({ ...patient, tasks: [...patient.tasks, task] });
    }
  };

  const checkValidDates = (
    type: "startDate" | "endDate",
    start: string,
    end: string
  ) => {
    if (type === "endDate" && start <= end) {
      return true;
    }
    if (type === "startDate" && start >= end) {
      return true;
    }
    return false;
  };

  const schema = z
    .object({
      type: z.string(),
      start: z.coerce
        .date()
        .refine(
          (data) =>
            checkValidDates(
              "startDate",
              localFormat(data.toISOString()),
              localFormat(new Date().toISOString())
            ),
          { message: "Start date must be current/future date." }
        ),
      end: z.coerce.date(),
      status: z.string(),
      note: z.string().min(10),
    })
    .refine(
      (data) =>
        checkValidDates(
          "endDate",
          localFormat(data.start.toISOString()),
          localFormat(data.end.toISOString())
        ),
      { message: "End date must be greater then start date.", path: ["end"] }
    );

  const formik = useFormik({
    initialValues: getTaskInitialValues(
      selectedTask ?? { patient: patientId as string, user: user?._id }
    ),
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values: ITaskForm) => {
      if (selectedTask) {
        const updateTask: ITask = {
          ...values,
          status: getStatusObject(values.status, user?._id as string),
        } as ITask;
        HTTP_SERVICE.updateTask(selectedTask._id as string, updateTask).then(
          (result: ITask | ErrorResponse): void => {
            if (!isErrorResponse(result)) {
              handleUpdateResponse(result);
            }
          }
        );
      } else {
        const newTask: ITask = {
          ...values,
          status: getStatusObject(values.status, user?._id as string),
        } as ITask;
        HTTP_SERVICE.createTask(newTask).then(
          (result: ITask | ErrorResponse): void => {
            if (!isErrorResponse(result)) {
              handleUpdateResponse(result);
            }
          }
        );
      }
    },
  });

  return (
    <div>
      <Box
        sx={{
          mt: 4,
          height: "50px",
          width: "100%",
        }}
      >
        <Typography variant="h6">
          {selectedTask ? `Edit Task` : `Create Task`}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Type"
              variant="outlined"
              size="small"
              name="type"
              select
              defaultValue={formik.initialValues.type}
              onChange={(e) => {
                setSelectedTaskType(e.target.value as TaskType);
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
              fullWidth
            >
              <MenuItem key="patient-tasks" value="" disabled>
                Patient Tasks
              </MenuItem>
              {[...patientTaskTypes].map((o: TaskOption) => (
                <MenuItem key={o.key} value={o.key}>
                  {readable(o.value)}
                </MenuItem>
              ))}
              <MenuItem key="staff-tasks" value="" disabled>
                Staff Tasks
              </MenuItem>
              {[...staffTaskTypes].map((o: TaskOption) => (
                <MenuItem key={o.key} value={o.key}>
                  {readable(o.value)}
                </MenuItem>
              ))}

              <MenuItem key="other-tasks" value="" disabled>
                General Tasks
              </MenuItem>
              {[...generalTaskTypes].map((o: TaskOption) => (
                <MenuItem key={o.key} value={o.key}>
                  {readable(o.value)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {selectedTaskType === EPatientTaskTypes.submit_form && (
            <Grid item xs={4}>
              <TextField
                label="Form Type"
                variant="outlined"
                size="small"
                name="formType"
                select
                defaultValue={formik.initialValues.formType}
                onChange={(e) => {
                  setSelectedTaskFormType(e.target.value as FormType);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                fullWidth
              >
                <MenuItem key="empty" value="" disabled>
                  Form Type
                </MenuItem>
                {[...formTypeOptions()].map((o: ListOptions<FormType>) => (
                  <MenuItem key={o.key} value={o.key}>
                    {readable(o.value)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {selectedTaskFormType === EFormType.blood_pressure_reading_form && (
            <Grid item xs={4}>
              <TextField
                label="Set Required"
                variant="outlined"
                size="small"
                name="sets"
                type="number"
                defaultValue={formik.initialValues.sets}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                fullWidth
              />
            </Grid>
          )}

          <Grid item xs={4}>
            <TextField
              label="Start Date"
              variant="outlined"
              size="small"
              type="date"
              name="start"
              defaultValue={localFormat(formik.initialValues.start)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputLabelProps={{ shrink: true }}
              error={formik.touched.start && Boolean(formik.errors.start)}
              helperText={formik.touched.start && formik.errors.start}
              inputProps={{
                min: `${localFormat(new Date().toISOString())}`,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="End Date"
              variant="outlined"
              size="small"
              type="date"
              name="end"
              defaultValue={localFormat(formik.initialValues.end)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputLabelProps={{ shrink: true }}
              error={formik.touched.end && Boolean(formik.errors.end)}
              helperText={formik.touched.end && formik.errors.end}
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Assign"
              variant="outlined"
              size="small"
              name="assigne"
              select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.initialValues.assigne}
              fullWidth
            >
              <MenuItem key="empty" value="">
                Not Selected
              </MenuItem>
              {users &&
                users.map((assigne: IUser) => (
                  <MenuItem key={assigne._id} value={assigne._id}>
                    {assigne?.name?.first} {assigne?.name?.last}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Status"
              variant="outlined"
              size="small"
              name="status"
              select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.initialValues.status}
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
              fullWidth
            >
              <MenuItem key="empty" value="">
                Not Selected
              </MenuItem>
              <MenuItem key="active" value="active">
                Active
              </MenuItem>
              <MenuItem key="draft" value="draft">
                Draft
              </MenuItem>
              <MenuItem key="complete" value="complete">
                Complete
              </MenuItem>
              <MenuItem key="canceled" value="canceled">
                Canceled
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Task Notes
            </Typography>
            <TextField
              label="Add your notes here..."
              multiline
              rows={4}
              name="note"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.initialValues.note}
              error={formik.touched.note && Boolean(formik.errors.note)}
              helperText={formik.touched.note && formik.errors.note}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ mt: 2 }} />
          </Grid>

          <Grid item xs={6}>
            <Button type="submit" variant="contained" size="small">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default TaskForm;
