import { useState } from "react";
import { useRecoilState } from "recoil";
import { Grid, Stack, Button, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { LabelMapper } from "../../../shared/constants";
import { fromDB } from "../../../services/datetime.service";
import {
  IConsultationEngagement,
  IConsultationTemplate,
} from "../../../interfaces/engangement.interface";
import { TimelineContentItem } from "./TimelineContentItem";
import {
  activeFormAtom,
  selectedConsultationAtom,
  patientForms,
} from "../../../services/state.service";
import { ClinicalConsultationAudit } from "./ClinicalConsultationAudit";
import { IAudit } from "../../../interfaces/common.interface";

type ClinicalConsultationProps = {
  consultation: IConsultationEngagement;
  content: IConsultationTemplate;
  bgColour: string;
};

export const ClinicalConsultation = ({
  consultation,
  bgColour,
  content,
}: ClinicalConsultationProps) => {
  const [revisionVisibility, setRevisionVisibility] = useState<boolean>();
  const [, setSelectedConsultation] = useRecoilState(selectedConsultationAtom);
  const [, setActiveForm] = useRecoilState(activeFormAtom);

  const toggleRevisionVisibility = () => {
    setRevisionVisibility(!revisionVisibility);
  };

  return (
    <Stack spacing="4px">
      <Grid container>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem
              color={bgColour}
              label="Appointment"
              value={LabelMapper[content.appointment as string]}
            />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack spacing="6px" direction="row" alignItems="center" gap={2}>
            <TimelineContentItem
              color={bgColour}
              label="Date"
              value={
                content.occurredAtDatetime
                  ? `${fromDB(content.occurredAtDatetime as string)}`
                  : `${"-"}`
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem
              color={bgColour}
              label="Patient Attendance"
              value={LabelMapper[content.patientAttendance as string]}
            />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem
              color={bgColour}
              label="Identity Confirmed"
              value={content.patientIdentityConfirmed ? `${"Yes"}` : `${"No"}`}
            />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem
              color={bgColour}
              label="Representative Present"
              value={
                content.isPatientRepresentativePresent ? `${"Yes"}` : `${"No"}`
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem
              color={bgColour}
              label="Failed Encounter"
              value={content.isFailedEncounter ? `${"Yes"}` : `${"No"}`}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem
              color={bgColour}
              label="Video Call"
              value={content.isVideoCall ? `${"Yes"}` : `${"No"}`}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>
          <Typography>
            <strong> Tags </strong>
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem
              color={bgColour}
              label="Prescription Issued"
              value={content?.tags?.prescriptionIssued ? `${"Yes"}` : `${"No"}`}
            />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem
              color={bgColour}
              label="Resistant Hypertension"
              value={
                content?.tags?.resistantHypertension ? `${"Yes"}` : `${"No"}`
              }
            />
          </Stack>
        </Grid>
        {revisionVisibility && (
          <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>
            <ClinicalConsultationAudit
              audit={consultation?.audit as IAudit[]}
              bgColour={bgColour}
            />
          </Grid>
        )}
        <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>
          <Box sx={{ display: "flex" }}>
            <Typography paddingRight={2}>
              <Button
                onClick={() => {
                  setActiveForm(patientForms.ConsultationForm);
                  setSelectedConsultation(consultation);
                }}
              >
                Edit Consultation
              </Button>
            </Typography>

            <Typography>
              <Button onClick={toggleRevisionVisibility}>
                {!revisionVisibility
                  ? `Show Revisions (${consultation.audit?.length})`
                  : `Hide Revisions (${consultation.audit?.length})`}
              </Button>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
