import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import Card from "@mui/material/Card";
import { Box, Button, Grid } from "@mui/material";
import { ArrowRight } from "@mui/icons-material";
import {
  activeFormAtom,
  patientForms,
} from "../../../../services/state.service";
import {
  IClinicalReadingTemplate,
  IEngagement,
} from "../../../../interfaces/engangement.interface";
import { DiabetesQuestionnaire } from "../../../../interfaces/questionnaire.interface";
import {
  getLastDiabetesSubmission,
  readingsToQuestionnaire,
} from "../../utils";
import { readable } from "../../../../interfaces/utils";
import { localFormat } from "../../../../services/datetime.service";
import { CardListItem } from "../../../../shared/components";

type DiabetesCardProps = {
  engagements: IEngagement[];
};

const DiabetesCard = ({ engagements }: DiabetesCardProps) => {
  const [diabetesReadings, setDiabetesReadings] =
    useState<DiabetesQuestionnaire>();

  const getLastLifeStyleReadings = () => {
    setDiabetesReadings(
      readingsToQuestionnaire<IClinicalReadingTemplate, DiabetesQuestionnaire>(
        getLastDiabetesSubmission(engagements)
      )
    );
  };

  useEffect(() => {
    if (engagements.length) getLastLifeStyleReadings();
  }, [engagements]);

  const setActiveForm = useSetRecoilState(activeFormAtom);
  const handleAdd = () => setActiveForm(patientForms.DiabetesReadingForm);

  return (
    <Card sx={{ p: 2, mt: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handleAdd}>Add Diabetes Reading</Button>
      </Box>
      <Box>
        <Grid container>
          <Grid item md={12} xs={12}>
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              label="Home Monitering"
              value={
                readable(diabetesReadings?.doesHomeGlucose as string) ?? "-"
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              label="Glucose Below 4mmol"
              value={readable(diabetesReadings?.glucoseBelow4 as string) ?? "-"}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              label="Glucose below 2p8mmol"
              value={
                readable(diabetesReadings?.glucoseBelow2p8 as string) ?? "-"
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              label="Experiences Hypos"
              value={
                readable(diabetesReadings?.experiencesHypos as string) ?? "-"
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              label="Symptoms During Hypos"
              value={
                readable(diabetesReadings?.symptomsDuringHypos as string) ?? "-"
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              label="Times Help Needed"
              value={
                readable(diabetesReadings?.timesHelpDuringHypos as string) ??
                "-"
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              label="Last Hypo"
              value={
                localFormat(diabetesReadings?.dateLastHypo as string) ?? "-"
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              label="Blood Sugar To Treat"
              value={
                readable(diabetesReadings?.bloodSugarToTreat as string) ?? "-"
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              label="Num Times Sugar Less 4mmol"
              value={
                readable(diabetesReadings?.numTimesSugarLess4 as string) ?? "-"
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              label="Eat/DrinK"
              value={
                readable(diabetesReadings?.eatDrinkToTreat as string) ?? "-"
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              label="Check Suger Before Driving"
              value={
                readable(diabetesReadings?.checkSugarBeforeDriving as string) ??
                "-"
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              label="Fasting At Time Of Hypo"
              value={
                readable(diabetesReadings?.fastingAtTimeOfHypo as string) ?? "-"
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default DiabetesCard;
