import { Box, Grid, Alert } from "@mui/material";
import { uniqueId } from "lodash";
import { IAlert } from "../../../../interfaces/patient.interface";

interface AlertProps {
  alerts: IAlert[] | undefined;
}

const Alerts = ({ alerts }: AlertProps) => (
  <Box
    sx={{
      height: "auto",
      width: "100%",
    }}
  >
    <Grid container>
      <Grid item md={12}>
        {alerts &&
          alerts.map((a: IAlert) => (
            <Alert key={uniqueId()} sx={{ width: "100%", mb: 1 }}>{a.message}</Alert>
          ))}
      </Grid>
    </Grid>
  </Box>
);

export default Alerts;
