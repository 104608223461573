import { Button } from "@mui/material";
import { useSetRecoilState } from "recoil";
import {
  activeFormAtom,
  FormType,
  selectedAssessmentAtom,
  selectedConsultationAtom,
} from "../../../../services/state.service";

interface FormButtonProps {
  title: string | undefined;
  form: FormType;
}

const FormButton = ({ title, form }: FormButtonProps) => {
  const setActiveForm = useSetRecoilState(activeFormAtom);
  // For set Assessment null
  // TODO: Review this code, remove assessment logic fro here to model close (or something general)
  const setEditAssessment = useSetRecoilState(selectedAssessmentAtom);
  // TODO: Review this code, remove consultation logic fro here to model close (or something general)
  const setEditConsultation = useSetRecoilState(selectedConsultationAtom);

  const handleClick = () => {
    if(form === "AssessmentForm"){
      setEditAssessment(undefined);
      setActiveForm(form);
    }
    if(form === "ConsultationForm"){
      setEditConsultation(null);
      setActiveForm(form);
    }
    setActiveForm(form);
  }

  return (
    <Button variant="contained" onClick={handleClick} sx={{ mr: 1 }}>
      {title ? `${title}` : ""}
    </Button>
  );
};

export default FormButton;
