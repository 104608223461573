import { Stack, Box, Divider, Typography } from "@mui/material";
import MuiTheme from "../../../shared/theme/MuiTheme";
import { readable } from "../../../interfaces/utils";

type CardListItemProps = {
  color: string | undefined;
  label: string;
  value: string;
};

export const TimelineContentItem = ({ color = MuiTheme.palette.primary.main, label, value }: CardListItemProps) => (
  <>
    <Box>
      <Divider
        orientation="vertical"
        style={{ minHeight: "5px", width: "5px", background: `${color}`, borderRadius: "1px", marginBottom: "2px" }}
      />
      <Divider
        orientation="vertical"
        style={{ minHeight: "30px", width: "5px", background: `${color}`, borderRadius: "2px", marginBottom: "2px" }}
      />
      <Divider
        orientation="vertical"
        style={{ minHeight: "5px", width: "5px", background: `${color}`, borderRadius: "1px" }}
      />
    </Box>
    <Stack spacing="6px">
      <Typography><strong>{readable(label)}</strong></Typography>
      <Typography fontSize={16}> {readable(value)}</Typography>
    </Stack>
  </>
);
