import { uniqueId } from "lodash";
import { Stack, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IAudit, IDifference } from "../../../interfaces/common.interface";
import { fromDB, humanReadable } from "../../../services/datetime.service";
import { TimelineContentItemAudit } from "./TimelineContentItemAudit";
import MuiTheme from "../../../shared/theme/MuiTheme";
import { LabelMapper } from "../../../shared/constants";

type AuditProps = {
  bgColour: string;
  audit: IAudit[];
};

export const ClinicalConsultationAudit = ({ bgColour, audit }: AuditProps) => (
  <Stack>
    {audit &&
      audit.map(({ user, updates, datetime }: IAudit) => {
        const content = updates?.content as IDifference;
        return updates?.content ? (
          <Accordion key={uniqueId()}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: MuiTheme.palette.colors.grey.main }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: MuiTheme.palette.colors.grey.main }}>
                {`Updated by ${user?.name?.first} ${user?.name
                  ?.last} on ${humanReadable(datetime)}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing="4px">
                <Grid container>
                  {content?.appointment && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Appointment"
                          oldValue={
                            LabelMapper[content?.appointment.oldValue as string]
                          }
                          newValue={
                            LabelMapper[content?.appointment.newValue as string]
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.occurredAtDatetime && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack
                        spacing="6px"
                        direction="row"
                        alignItems="center"
                        gap={2}
                      >
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Date"
                          oldValue={
                            content?.occurredAtDatetime.oldValue
                              ? `${fromDB(
                                  content?.occurredAtDatetime.oldValue as string
                                )}`
                              : `${"-"}`
                          }
                          newValue={
                            content?.occurredAtDatetime.newValue
                              ? `${fromDB(
                                  content?.occurredAtDatetime.newValue as string
                                )}`
                              : `${"-"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.patientAttendance && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Patient Attendance"
                          oldValue={
                            LabelMapper[
                              content?.patientAttendance.oldValue as string
                            ]
                          }
                          newValue={
                            LabelMapper[
                              content?.patientAttendance.newValue as string
                            ]
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.patientIdentityConfirmed && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Identity Confirmed"
                          oldValue={
                            content?.patientIdentityConfirmed.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.patientIdentityConfirmed.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.isPatientRepresentativePresent && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Representative Present"
                          oldValue={
                            content?.isPatientRepresentativePresent.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.isPatientRepresentativePresent.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.isFailedEncounter && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Failed Encounter"
                          oldValue={
                            content?.isFailedEncounter.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.isFailedEncounter.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.isVideoCall && (
                    <Grid item xs={12} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Video Call"
                          oldValue={
                            content?.isVideoCall.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.isVideoCall.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.tags && (
                    <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>
                      <Typography>
                        <strong> Tags </strong>
                      </Typography>
                    </Grid>
                  )}

                  {(content?.tags as IDifference)?.prescriptionIssued && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Prescription Issued"
                          oldValue={
                            (content?.tags as IDifference)?.prescriptionIssued
                              ?.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.tags as IDifference)?.prescriptionIssued
                              ?.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}
                  {(content?.tags as IDifference)?.resistantHypertension && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Resistant Hypertension"
                          oldValue={
                            (content?.tags as IDifference)
                              ?.resistantHypertension?.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.tags as IDifference)
                              ?.resistantHypertension?.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </AccordionDetails>
          </Accordion>
        ) : null;
      })}
  </Stack>
);
