import { Stack, Grid } from "@mui/material";
import { uniqueId } from "lodash";
import {
  IOtherEngagementTemplate,
  ISmsEngagementTemplate,
} from "../../../interfaces/engangement.interface";
import { readable } from "../../../interfaces/utils";
import { TimelineContentItem } from "./TimelineContentItem";

type OtherEnagegementProps = {
  content: IOtherEngagementTemplate | ISmsEngagementTemplate;
  bgColour?: string | undefined;
};

export const OtherContent = ({ bgColour, content }: OtherEnagegementProps) => (
  <Stack spacing="4px">
    <Grid container>
      {Object.entries(content).map(([key, value]) => (
        <Grid item xs={4} key={uniqueId()} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem
              color={bgColour}
              label={readable(key)}
              value={readable(value)}
            />
          </Stack>
        </Grid>
      ))}
    </Grid>
  </Stack>
);
