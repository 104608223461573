import { useRecoilValue } from "recoil";
import { Grid, TextField, Typography } from "@mui/material";
import { Field, FieldProps, useFormikContext } from "formik";
import { FieldsSubset } from "../../../../services/form-config.service";
import { selectedTemplateFieldsAtom } from "../../../../services/state.service";
import { InputField, SelectField } from "../../../../shared/components";
import { IClinicalAssessmentTemplate, medicationChangeOptions } from "../../../../interfaces/engangement.interface";

export const BloodPressureCaseFinding = () => {
    const templateFields = useRecoilValue<FieldsSubset>(
        selectedTemplateFieldsAtom
    );

    const { values } = useFormikContext<IClinicalAssessmentTemplate>();

    return (
        <Grid item container alignItems="flex-end" xs={12} rowSpacing={4} spacing={1}>

            {templateFields?.bloodPressureHistory && (
                <Grid item xs={12}>
                    <Typography variant="h6">Blood Pressure</Typography>
                </Grid>
            )}
            {templateFields?.bloodPressureHistory && (
                <Field name="bloodPressureHistory" >
                    {(fieldProps: FieldProps<string[]>) => {
                        const { handleChange, handleBlur } = fieldProps.form;
                        const { error, touched } = fieldProps.meta;
                        return (
                            <Grid item xs={12}>
                                <TextField
                                    label="History"
                                    multiline
                                    rows={3}
                                    name="bloodPressureHistory"
                                    value={values.bloodPressureHistory}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched && Boolean(error)}
                                    helperText={touched && error}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }}
                </Field>
            )}

            {templateFields?.bloodPressurePlan && (
                <Field name="bloodPressurePlan" >
                    {(fieldProps: FieldProps<string[]>) => {
                        const { handleChange, handleBlur } = fieldProps.form;
                        const { error, touched } = fieldProps.meta;
                        return (
                            <Grid item xs={12}>
                                <TextField
                                    label="Clinical Management Plan"
                                    multiline
                                    rows={3}
                                    name="bloodPressurePlan"
                                    value={values.bloodPressurePlan}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched && Boolean(error)}
                                    helperText={touched && error}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }}
                </Field>
            )}

            {templateFields?.bloodPressureMedicationChanges && (
                <Grid item xs={12}>
                    <SelectField
                        label="Medication Changes"
                        name="bloodPressureMedicationChanges"
                        selectedValue={values?.bloodPressureMedicationChanges ? values?.bloodPressureMedicationChanges : 'not_recorded'}
                        options={medicationChangeOptions()}
                    />
                </Grid>
            )}

            {templateFields?.bloodPressureMedicationChangeDetail && (
                <Grid item xs={12}>
                    <InputField
                        label="Medication Change Details"
                        name="bloodPressureMedicationChangeDetail"
                        defaultValue={values?.bloodPressureMedicationChangeDetail}
                    />
                </Grid>
            )}
        </Grid>
    );

} 