
import { useRecoilValue } from "recoil";
import { Divider, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { FieldsSubset } from "../../../../services/form-config.service";
import { selectedTemplateFieldsAtom } from "../../../../services/state.service";
import { CheckBoxField } from "../../../../shared/components";
import { IClinicalAssessmentTemplate, onwardReferralCompletedOptions } from "../../../../interfaces/engangement.interface";

export const OnwardReferral = () => {
    const templateFields = useRecoilValue<FieldsSubset>(
        selectedTemplateFieldsAtom
    );

    const { values } = useFormikContext<IClinicalAssessmentTemplate>();

    return (
        <Grid item container alignItems="flex-end" xs={12} rowSpacing={4} spacing={1}>

            {templateFields?.onwardReferralCompleted && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}

            {templateFields?.onwardReferralCompleted && (
                <Grid item xs={12}>
                        <CheckBoxField
                            label="Onward Referral Completed"
                            name="onwardReferralCompleted"
                            position="horizontal"
                            selectedValue={values.onwardReferralCompleted.map((i: unknown) => (i))}
                            options={onwardReferralCompletedOptions()}
                        />
                </Grid>
            )}
        </Grid>
    );
}