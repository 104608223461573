import { createTheme, SimplePaletteColorOptions } from "@mui/material/styles";
import { basic, colors } from "./colors";

declare module "@mui/material/styles/createPalette" {
  export interface Palette {
    colors: {
      white: string;
      black: string;
      info: PaletteColor;
      success: PaletteColor;
      warning: PaletteColor;
      error: PaletteColor;
      yellow: PaletteColor;
      greenGradient: PaletteColor;
      blueGradient: PaletteColor;
      orangeGradient: PaletteColor;
      redGradient: PaletteColor;
      voiletGradient: PaletteColor;
      grey: PaletteColor;
    };
  }
  export interface PaletteOptions {
    colors: {
      white: string;
      black: string;
      info: SimplePaletteColorOptions;
      success: SimplePaletteColorOptions;
      warning: SimplePaletteColorOptions;
      error: SimplePaletteColorOptions;
      yellow: SimplePaletteColorOptions;
      greenGradient: SimplePaletteColorOptions;
      blueGradient: SimplePaletteColorOptions;
      orangeGradient: SimplePaletteColorOptions;
      redGradient: SimplePaletteColorOptions;
      voiletGradient: SimplePaletteColorOptions;
      grey: SimplePaletteColorOptions;
    };
  }
}

const MuiTheme = createTheme({
  palette: {
    primary: basic.primary as SimplePaletteColorOptions,
    secondary: basic.secondary as SimplePaletteColorOptions,
    colors,
  },
  components: {
    MuiAppBar: {},
  },
});

export default MuiTheme;
