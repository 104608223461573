import { Box, Grid } from "@mui/material";
import { EngagementTimeline } from "./Timeline";
import ProfileCard from "./ProfileCard";
import BloodPressureCard from "./BloodPressureCard";
import LifestyleCard from "./LifestyleCard";
import { IEngagement } from "../../../interfaces/engangement.interface";
import DiabetesCard from "./DiabetesCard";
import MedicalHistoryCard from "./MedicalHistory";
import { IPatientPopulated } from "../../../interfaces/patient.interface";
import ECGCard from "./ECGCard";

type DashboardProps = {
  engagements: IEngagement[];
  patient: IPatientPopulated;
};
const Dashboard = ({ patient, engagements }: DashboardProps) => (
  <Box
    sx={{
      mt: 1,
    }}
  >
    <Grid container>
      <Grid item md={9} xs={12}>
        <EngagementTimeline />
      </Grid>
      <Grid item md={3} xs={12}>
        <ProfileCard />
        <ECGCard />
        <BloodPressureCard engagements={engagements} />
        <MedicalHistoryCard medicalHistory={patient?.medicalHistory} />
        <LifestyleCard engagements={engagements} />
        <DiabetesCard engagements={engagements} />
      </Grid>
    </Grid>
  </Box>
);

export default Dashboard;
