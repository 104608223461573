import { ReactElement } from "react";
// import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
// import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import { ListRounded } from "@mui/icons-material";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import { DateTime, dateOnly } from "../../services/datetime.service";
import MuiTheme from "../../shared/theme/MuiTheme";
import {
  BloodPressureStats,
  EEngagementTypes,
  EngagementType,
  IClinicalReading,
  IClinicalReadingTemplate,
  IEngagement,
} from "../../interfaces/engangement.interface";
import {
  EBloodPressureParameter,
  EFormType,
  FormType,
  IReading,
} from "../../interfaces/questionnaire.interface";
import { TaskType } from "../../interfaces/task.interface";

export const toCamel = (s: string) =>
  s.replace(/([-_][a-z0-9])/gi, ($1) =>
    $1.toUpperCase().replace("-", "").replace("_", "")
  );

export const toSnake = (s: string) =>
  s.replace(/[A-Z]/g, (letter) => `_${letter?.toLowerCase()}`);

const sortByDatetime = (a: DateTime, b: DateTime) => {
  if (a < b) return -1;
  if (a > b) return 1;

  return 0;
};

const withinDateRange = (
  datetime: string,
  startDate?: string,
  endEnd?: string
): boolean => {
  const start = DateTime.fromISO(dateOnly(startDate as string));
  const end = DateTime.fromISO(dateOnly(endEnd as string));
  const date = DateTime.fromISO(dateOnly(datetime as string));

  if (date.isValid) {
    if (start.isValid && end.isValid) {
      return date >= start && date <= end;
    }
    return true;
  }

  return true;
};

export const getLastLifeStyleSubmission = (
  patientEngagements: IEngagement[]
): IClinicalReadingTemplate => {
  const clinical = patientEngagements.filter(
    (e) => e.type === EEngagementTypes.clinical_reading
  ) as IClinicalReading[];
  const lifeStyleSubmissions = clinical.filter(
    (c) => c.content.formType === EFormType.lifestyle_questionnaire_form
  );

  return lifeStyleSubmissions[0]?.content ?? [];
};

export const getLastDiabetesSubmission = (
  patientEngagements: IEngagement[]
): IClinicalReadingTemplate => {
  const clinical = patientEngagements.filter(
    (e) => e.type === EEngagementTypes.clinical_reading
  ) as IClinicalReading[];
  const diabetesSubmissions = clinical.filter(
    (c) => c.content.formType === EFormType.diabetes_questionnaire_form
  );

  return diabetesSubmissions[0]?.content ?? [];
};

export const getBloodPressureStatisticsForDateRange = (
  patientEngagements: IEngagement[],
  startDate?: string,
  endDate?: string
): BloodPressureStats => {
  const clinical = patientEngagements
    .filter((e) => e.type === EEngagementTypes.clinical_reading)
    .sort((r1, r2) => {
      const d1 = DateTime.fromISO(r1?.datetime as string);
      const d2 = DateTime.fromISO(r2?.datetime as string);
      return sortByDatetime(d1, d2);
    }) as IClinicalReading[];

  if (clinical.length) {
    const bloodPressureSubmissions = clinical
      .filter(
        (c) => c.content.formType === EFormType.blood_pressure_reading_form
      )
      .filter((c) =>
        withinDateRange(c?.datetime as string, startDate, endDate)
      );

    if (bloodPressureSubmissions.length) {
      const sortedReadings = bloodPressureSubmissions
        .map(({ content }) => content.readings)
        .flat()
        .flat()
        .sort((r1, r2) => {
          const d1 = DateTime.fromISO(r1?.datetime);
          const d2 = DateTime.fromISO(r2?.datetime);
          return sortByDatetime(d1, d2);
        });

        const aggregateReadings = sortedReadings.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (aggregate: any, current) => {
          if (
            current.parameter === EBloodPressureParameter.blood_pressure_systole
          )
            aggregate[0].push(parseInt(current.value, 10));
          if (
            current.parameter ===
            EBloodPressureParameter.blood_pressure_diastole
          )
            aggregate[1].push(parseInt(current.value, 10));
          if (current.parameter === EBloodPressureParameter.pulse_rate)
            aggregate[2].push(parseInt(current.value, 10));

          return aggregate;
        },
        [[], [], []]
      );

      return {
        maxSystolic: Math.max(...aggregateReadings[0]) ?? "Null",
        maxDiastolic: Math.max(...aggregateReadings[1]) ?? "Null",
        avgSystolic:
          Math.round(
            aggregateReadings[0].reduce(
              (total: number, current: number) => total + current,
              0
            ) / aggregateReadings[0].length
          ) ?? "Null",
        avgDiastolic:
          Math.round(
            aggregateReadings[1].reduce(
              (total: number, current: number) => total + current,
              0
            ) / aggregateReadings[1].length
          ) ?? "Null",
        from: bloodPressureSubmissions[0]?.datetime as string,
        to: bloodPressureSubmissions[bloodPressureSubmissions.length - 1]
          ?.datetime as string,
      };
    }
  }

  return {
    maxSystolic: "-",
    maxDiastolic: "-",
    avgSystolic: "-",
    avgDiastolic: "-",
    from: "-",
    to: "-",
  };
};

export const stages = [
  "UNKNOWN",
  "NORMAL",
  "MILD",
  "MODERATE",
  "HIGH",
] as const;

type Stage = (typeof stages)[number];

export type Scale = {
  MILD: number;
  MODERATE: number;
  HIGH: number;
};

export type BpScale = {
  SYSTOLIC: Scale;
  DIASTOLIC: Scale;
};

export const BpStage: BpScale = {
  SYSTOLIC: {
    MILD: 135, // 135-149
    MODERATE: 150, // 150-174
    HIGH: 175, // >=175
  },
  DIASTOLIC: {
    MILD: 85, // 85-94
    MODERATE: 95, // 95-114
    HIGH: 115, // >=115
  },
};

export const getStage = (scale: Scale, value: number | null): Stage => {
  if (value === null) return "UNKNOWN";
  if (value < scale.MILD) return "NORMAL";
  if (value < scale.MODERATE) return "MILD";
  if (value < scale.HIGH) return "MODERATE";
  return "HIGH";
};

export const readingsToQuestionnaire = <T extends IClinicalReadingTemplate, R>(
  content: T
): R => {
  const questionnaire: R = {} as R;

  if (content.readings)
    content.readings
      .flat()
      .flat()
      .map((reading: IReading): R => {
        questionnaire[toCamel(reading.parameter) as keyof R] =
          reading.value as R[keyof R];

        return questionnaire;
      });

  return questionnaire;
};

export const bloodPressureStyle: Record<
  Stage,
  { backgroundColor: string; color: string }
> = {
  UNKNOWN: {
    backgroundColor: "#BFC1CA",
    color: MuiTheme.palette.colors.grey.dark,
  },
  NORMAL: {
    backgroundColor: MuiTheme.palette.colors.success.main,
    color: MuiTheme.palette.colors.success.dark,
  },
  MILD: {
    backgroundColor: MuiTheme.palette.colors.yellow.main,
    color: MuiTheme.palette.colors.yellow.dark,
  },
  MODERATE: {
    backgroundColor: MuiTheme.palette.colors.warning.main,
    color: MuiTheme.palette.colors.warning.dark,
  },
  HIGH: {
    backgroundColor: MuiTheme.palette.colors.error.main,
    color: MuiTheme.palette.colors.error.main,
  },
};

export const timelineStyle = (
  engagementType: EngagementType,
  formType?: FormType
): { backgroundColor: string; icon: ReactElement } => {
  switch (engagementType) {
    case EEngagementTypes.consultation:
      return {
        backgroundColor: MuiTheme.palette.colors.warning.main,
        icon: <ConnectWithoutContactIcon />,
      };
    case EEngagementTypes.clinical_reading:
      switch (formType) {
        case EFormType.blood_pressure_reading_form:
          return {
            backgroundColor: MuiTheme.palette.colors.success.dark,
            icon: <ListRounded />,
          };
        case EFormType.diabetes_questionnaire_form:
          return {
            backgroundColor: MuiTheme.palette.colors.yellow.main,
            icon: <ListRounded />,
          };
        case EFormType.lifestyle_questionnaire_form:
          return {
            backgroundColor: MuiTheme.palette.colors.error.main,
            icon: <ListRounded />,
          };
        default:
          return {
            backgroundColor: MuiTheme.palette.colors.info.main,
            icon: <ListRounded />,
          };
      }
    case EEngagementTypes.clinical_review:
      return {
        backgroundColor: MuiTheme.palette.colors.success.main,
        icon: <TroubleshootIcon />,
      };
    default:
      return {
        backgroundColor: MuiTheme.palette.primary.main,
        icon: <TroubleshootIcon />,
      };
  }
};

export const tasKStyle: Partial<
  Record<TaskType, { backgroundColor: string; color: string }> & {
    default: { backgroundColor: string; color: string };
  }
> = {
  submit_form: {
    backgroundColor: MuiTheme.palette.colors.blueGradient.dark,
    color: MuiTheme.palette.colors.white,
  },
  urgent_clinical_assessment: {
    backgroundColor: MuiTheme.palette.colors.greenGradient.main,
    color: MuiTheme.palette.colors.white,
  },
  urgent_results_assessment: {
    backgroundColor: MuiTheme.palette.colors.voiletGradient.main,
    color: MuiTheme.palette.colors.white,
  },
  clinical_assessment: {
    backgroundColor: MuiTheme.palette.colors.info.main,
    color: MuiTheme.palette.colors.white,
  },
  results_assessment: {
    backgroundColor: MuiTheme.palette.colors.redGradient.main,
    color: MuiTheme.palette.colors.white,
  },
  default: {
    backgroundColor: MuiTheme.palette.colors.orangeGradient.dark,
    color: MuiTheme.palette.colors.white,
  },
};
