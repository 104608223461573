import { useRecoilValue } from "recoil";
import { useFormik } from "formik";
import * as z from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  EEngagementTypes,
  ConsultationType,
  IConsultationTemplate,
  IConsultationEngagement,
  IEngagement,
  consultationTypeOptions,
} from "../../../interfaces/engangement.interface";
import {
  combineDateTime,
  DateTime,
  extractTime,
  localFormat,
} from "../../../services/datetime.service";
import { OmitID, ListOptions, readable } from "../../../interfaces/utils";
import {
  HTTP_SERVICE,
  isErrorResponse,
  ErrorResponse,
} from "../../../services/http.service";
import {
  getLoggedUser,
  selectedConsultationAtom,
  selectedPatientId,
} from "../../../services/state.service";

type ICreateConsultationForm = IConsultationTemplate & {
  date: string;
  time: string;
  prescriptionIssued: boolean;
  resistantHypertension: boolean;
  note: string;
};

const getInitialValues = (
  consultation: IConsultationEngagement | null
): ICreateConsultationForm => ({
  type: consultation?.content?.type ?? undefined,
  appointment: consultation?.content?.appointment ?? undefined,
  date:
    `${localFormat(consultation?.content?.occurredAtDatetime as string)}` ??
    `${localFormat(new Date().toISOString())}`,
  time:
    `${extractTime(consultation?.content?.occurredAtDatetime as string)}` ??
    `${extractTime(new Date().toISOString())}`,
  patientAttendance: consultation?.content?.patientAttendance ?? undefined,
  patientIdentityConfirmed:
    consultation?.content?.patientIdentityConfirmed ?? false,
  isPatientRepresentativePresent:
    consultation?.content?.isPatientRepresentativePresent ?? false,
  isFailedEncounter: consultation?.content?.isFailedEncounter ?? false,
  isVideoCall: consultation?.content?.isVideoCall ?? false,
  prescriptionIssued: consultation?.content?.tags?.prescriptionIssued ?? false,
  resistantHypertension:
    consultation?.content?.tags?.resistantHypertension ?? false,
  note: consultation?.note ?? "",
});

interface ConsultationFormProps {
  closeForm: () => void;
}

const ConsultationForm = ({ closeForm }: ConsultationFormProps) => {
  const user = useRecoilValue(getLoggedUser);
  const patientId = useRecoilValue(selectedPatientId);
  const selectedConsultation = useRecoilValue(selectedConsultationAtom);

  const schema = z.object({
    type: z.string(),
    appointment: z.any(),
    date: z.coerce.date(),
    time: z.string(),
    patientAttendance: z.string(),
    note: z.string(),
  });

  const handleUpdateResponse = () => {
    closeForm();
    // setPatient(updatedPatient);
  };

  const formik = useFormik<ICreateConsultationForm>({
    initialValues: getInitialValues(selectedConsultation),
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values: ICreateConsultationForm) => {
      const {
        prescriptionIssued,
        resistantHypertension,
        date,
        time,
        note,
        ...other
      } = values;

      const consultation: OmitID<IConsultationEngagement> = {
        type: EEngagementTypes.consultation,
        user: user?._id as string,
        patient: patientId as string,
        content: {
          ...other,
          occurredAtDatetime: combineDateTime(date, time) as string,
          tags: {
            prescriptionIssued,
            resistantHypertension,
          },
        },
        note,
        datetime: DateTime.now().toString(),
      };

      if (selectedConsultation) {
        HTTP_SERVICE.updateEngagement(
          selectedConsultation._id as string,
          consultation as Partial<IEngagement>
        ).then((result: IEngagement | ErrorResponse): void => {
          if (!isErrorResponse(result)) {
            handleUpdateResponse();
          }
        });
      } else {
        HTTP_SERVICE.createEngagement(
          consultation as Partial<IEngagement>
        ).then((result: IEngagement | ErrorResponse): void => {
          if (!isErrorResponse(result)) {
            handleUpdateResponse();
          }
        });
      }
    },
  });

  return (
    <>
      <Box
        sx={{
          height: "50px",
          width: "100%",
        }}
      >
        <Typography variant="h6">{selectedConsultation ? `Edit Log Consultation` : `Log Consultation`} </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Consultation Type"
              variant="outlined"
              size="small"
              name="type"
              select
              value={formik.values.type ? formik.values.type : '' }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
              fullWidth
            >
              <MenuItem value="">
                No Selected
              </MenuItem>
              {[...consultationTypeOptions()].map(
                (i: ListOptions<ConsultationType>) => (
                  <MenuItem key={i.key} value={i.key}>
                    {readable(i.value)}
                  </MenuItem>
                )
              )}
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Appointment"
              variant="outlined"
              size="small"
              name="appointment"
              value={formik.values.appointment ? formik.values.appointment : ''}
              select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.appointment && Boolean(formik.errors.appointment)
              }
              helperText={
                formik.touched.appointment && formik.errors.appointment
              }
              fullWidth
            >
              <MenuItem key="" value="">
                No Selected
              </MenuItem>
              <MenuItem
                key="unplanned_appointment"
                value="unplanned_appointment"
              >
                Unplanned Appointment
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Date"
              variant="outlined"
              size="small"
              type="date"
              name="date"
              value={formik.values.date }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: `${localFormat(new Date().toISOString())}`,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Time"
              variant="outlined"
              size="small"
              type="time"
              name="time"
              value={formik.values.time }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.time && Boolean(formik.errors.time)}
              helperText={formik.touched.time && formik.errors.time}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Patient Attendance"
              variant="outlined"
              size="small"
              name="patientAttendance"
              value={formik.values.patientAttendance ? formik.values.patientAttendance : '' }
              select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.patientAttendance &&
                Boolean(formik.errors.patientAttendance)
              }
              helperText={
                formik.touched.patientAttendance &&
                formik.errors.patientAttendance
              }
              fullWidth
            >
              <MenuItem key="" value="">
                Not Selected
              </MenuItem>
              <MenuItem key="present" value="present">
                Present
              </MenuItem>
              <MenuItem key="not_present" value="not_present">
                Not Present
              </MenuItem>
              <MenuItem key="patient_not_required" value="patient_not_required">
                Patient Not Required
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  name="patientIdentityConfirmed"
                  checked={formik.values.patientIdentityConfirmed}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
              label="Patient Identity Confirmed"
            />
          </Grid>

          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isPatientRepresentativePresent"
                  checked={formik.values.isPatientRepresentativePresent}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
              label="Representative Present"
            />
          </Grid>

          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isFailedEncounter"
                  checked={formik.values.isFailedEncounter}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
              label="Failed Encounter"
            />
          </Grid>

          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isVideoCall"
                  checked={formik.values.isVideoCall}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
              label="Video Call"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Notes
            </Typography>
            <TextField
              label=""
              multiline
              rows={4}
              name="note"
              defaultValue={formik.values.note}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.note && Boolean(formik.errors.note)}
              helperText={formik.touched.note && formik.errors.note}
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  name="prescriptionIssued"
                  checked={formik.values.prescriptionIssued}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
              label="Prescription Issued"
            />
          </Grid>

          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  name="resistantHypertension"
                  checked={formik.values.resistantHypertension}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
              label="Resistant Hypertension"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <Button type="submit" variant="contained" size="small">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ConsultationForm;
