import { DateTime, Settings } from "luxon";

Settings.defaultZone = "Europe/London";
Settings.defaultLocale = "en-gb";

const localFormat = (datetime: string) =>
  DateTime.fromISO(datetime, { zone: "Europe/London" })
    .toFormat("yyyy-MM-dd")
    .toLocaleString();

const extractTime = (datetime: string) =>
  DateTime.fromISO(datetime, { zone: "Europe/London" })
    .toFormat("HH:mm")
    .toLocaleString();

const humanReadable = (datetime: string) =>
  DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_MED); // October 22, 9:38 PM

export const combineDateTime = (date: string, time: string) =>
  DateTime.fromISO(`${date}T${time}:00.00`, {
    zone: "Europe/London",
  }).toISO();

export const fromDB = (date: string) =>
  DateTime.fromISO(date, {
    zone: "Europe/London",
  })
    .toFormat("yyyy-MM-dd")
    .toLocaleString();

export const fromForm = (date: string): string =>
  DateTime.fromISO(date, {
    zone: "Europe/London",
  }).toISO() as string;

export const dateOnly = (date: string) =>
  DateTime.fromISO(date, {
    zone: "Europe/London",
  }).toFormat("yyyy-MM-dd");

export { DateTime, Settings, localFormat, extractTime, humanReadable };
