import { Field, FieldProps } from "formik";
import { TextField } from "@mui/material";

interface InputFieldProps {
  name: string;
  label: string;
  defaultValue?: string | undefined;
  disabledField?: boolean;
}

const InputField = (props: InputFieldProps) => {
  const { label, name, defaultValue, disabledField } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps<string>) => {
        const { handleChange } = fieldProps.form;
        return (
          <TextField
            label={label}
            variant="outlined"
            size="small"
            name={name}
            value={defaultValue}
            onChange={handleChange}
            fullWidth
            disabled={disabledField}
          />
        );
      }}
    </Field>
  );
};

export default InputField;
