import { IName, IAddress } from "./common.interface";
import { IPractice } from "./practice.interface";
import { ITask } from "./task.interface";
import { enumToKeyValuePair } from "./utils";

export interface IHealthCondition {
  name: string;
}

export interface IAlert {
  message: string;
  level: "urgent" | "normal";
}

export interface ITarget {
  name: string;
}

export type MedicalHistory = { [key: string]: string[] | string };

export interface IMedicalHistory extends MedicalHistory {
  respiratory: string[] | string;
  neurology: string[] | string;
  cardiovascular: string[] | string;
  endocrine: string[] | string;
  other: string[] | string;
}

export interface IPatientPhoneNumbers {
  landline?: number;
  mobile1?: number;
  mobile2?: number;
}

export enum EPatientInactiveStatusType {
  "abusive" = "abusive",
  "opted_out" = "opted_out",
  "changed_practice" = "changed_practice",
  "practice_off_boarded" = "practice_off_boarded",
  "deceased" = "deceased",
}

export type PatientInactiveStatusType = keyof typeof EPatientInactiveStatusType;

export const inactiveStatusesType = enumToKeyValuePair(
  EPatientInactiveStatusType
);

export enum EPatientActiveStatusType {
  "registered" = "registered",
  "restarted" = "restarted",
  "active" = "active",
  "paused" = "paused",
}

export type PatientActiveStatusType = keyof typeof EPatientActiveStatusType;

export const activeStatusesType = enumToKeyValuePair(EPatientActiveStatusType);

export type PatientStatusType =
  | PatientActiveStatusType
  | PatientInactiveStatusType;

export const patientStatuses = [...inactiveStatusesType, ...activeStatusesType];

export interface IPatientStatus {
  type: PatientStatusType;
  datetime: string;
  user: string;
  note?: string;
}

export interface IPatient {
  _id: string;
  nhsNumber: string;
  name: IName;
  imageKey: string;
  gender: string;
  dateOfBirth: string;
  emailAddr: string;
  postalAddress: IAddress;
  phoneNumbers: IPatientPhoneNumbers;
  practice: string;
  consented: boolean;
  healthConditions: IHealthCondition[] | undefined;
  alerts: IAlert[];
  preferredLanguage: string;
  availabilityHours: string;
  medicalHistory: IMedicalHistory;
  additionalInfo: string;
  targets: ITarget[] | undefined;
  status: IPatientStatus;
}

export interface IPatientPopulatedFields {
  tasks: ITask[];
  practice: IPractice;
}

export type IPatientPopulated = Omit<IPatient, "practice" | "tasks"> &
  IPatientPopulatedFields;

export interface ISignedUrl {
  putUrl: string;
  getUrl: string;
}
