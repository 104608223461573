import { useRecoilValue } from "recoil";
import { Button, Grid, Typography, Divider } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { selectedPatientAtom } from "../../../../services/state.service";
import { IPatientPopulated } from "../../../../interfaces/patient.interface";
import { DisplayObject } from "../../../../shared/components";

export const PastMedicalHistory = () => {
  const selectedPatient =
    useRecoilValue<IPatientPopulated>(selectedPatientAtom);
  return (
    <Grid item container alignItems="flex-end" xs={12} rowSpacing={4}>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h6">Past Medical History</Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Button
              size="small"
              sx={{ textTransform: "none" }}
              endIcon={<OpenInNewIcon />}
            >
              Update Past Medical History
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" component="div">
          <DisplayObject data={selectedPatient.medicalHistory} />
        </Typography>
      </Grid>
    </Grid>
  );
};
