import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Grid, IconButton } from "@mui/material";
import { ArrowBackSharp } from "@mui/icons-material";
import Slidebar from "./Slidebar";
import { FormModal } from "./PatientForms";
import {
  userListAtom,
  selectedPatientAtom,
  engagementListAtom,
  patientForms,
} from "../../services/state.service";
import {
  HTTP_SERVICE,
  isErrorResponse,
  ErrorResponse,
} from "../../services/http.service";
import { IPatientPopulated } from "../../interfaces/patient.interface";
import FormButton from "./Dashboard/FormButton";
import Dashboard from "./Dashboard";
import Alerts from "./Dashboard/Alerts";
import { IEngagement } from "../../interfaces/engangement.interface";
import { IUser } from "../../interfaces/user.interface";

const PatientDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [patient, setPatient] = useRecoilState(selectedPatientAtom);
  const [engagements, setEngagements] = useRecoilState(engagementListAtom);
  const [, setUsers] = useRecoilState(userListAtom);

  useEffect(() => {
    HTTP_SERVICE.getPatient(id as string).then(
      (result: IPatientPopulated | ErrorResponse): void => {
        if (!isErrorResponse(result)) {
          setPatient(result);
        }
      }
    );
    HTTP_SERVICE.getPatientEngagements(id as string).then(
      (engagementResult: IEngagement[] | ErrorResponse): void => {
        if (!isErrorResponse(engagementResult)) {
          setEngagements(engagementResult);
        }
      }
    );
    HTTP_SERVICE.getUsers().then(
      (userResult: IUser[] | ErrorResponse): void => {
        if (!isErrorResponse(userResult)) {
          setUsers(userResult);
        }
      }
    );
  }, [setUsers, setPatient, setEngagements]);

  return (
    <>
      <FormModal />
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <Alerts alerts={patient ? patient.alerts : undefined} />
          <IconButton size="large" onClick={() => navigate("/")}>
            <ArrowBackSharp />
          </IconButton>
          <FormButton
            title="Consultation"
            form={patientForms.ConsultationForm}
          />
          <FormButton title="Assessment" form={patientForms.AssessmentForm} />
          <FormButton title="Add Alert" form={patientForms.AddAlertForm} />
          <Slidebar />
        </Grid>
        <Grid item md={12} xs={12}>
          <Dashboard patient={patient} engagements={engagements} />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientDetailPage;
