import { useSetRecoilState } from "recoil";
import Card from "@mui/material/Card";
import { Box, Button, Grid } from "@mui/material";
import { ArrowRight } from "@mui/icons-material";
import {
  activeFormAtom,
  patientForms,
} from "../../../../services/state.service";
import { readable } from "../../../../interfaces/utils";
import { MedicalHistory } from "../../../../interfaces/patient.interface";
import { CardListItem } from "../../../../shared/components";
import MuiTheme from "../../../../shared/theme/MuiTheme";

type MedicalHistoryCardProps = {
  medicalHistory: MedicalHistory;
};

const MedicalHistoryCard = ({ medicalHistory }: MedicalHistoryCardProps) => {
  const setActiveForm = useSetRecoilState(activeFormAtom);
  const handleUpdate = () => setActiveForm(patientForms.MedicalHistoryForm);

  return (
    <Card sx={{ p: 2, mt: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handleUpdate}>Update Medical History</Button>
      </Box>
      <Box>
        <Grid container>
          <Grid
            item
            md={12}
            xs={12}
            sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
          >
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              label="Respiratory"
              value={ (medicalHistory?.respiratory  as string[] )?.map((item: string) => `${readable(item)}, ` ) as unknown as string ?? "-"  }
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
          >
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              color={MuiTheme.palette.colors.error.main}
              label="Neurology"
              value={ (medicalHistory?.neurology  as string[] )?.map((item: string) => `${readable(item)}, ` ) as unknown as string ?? "-"  }
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
          >
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              color={MuiTheme.palette.colors.yellow.dark}
              label="Cardiovascular"
              value={ (medicalHistory?.cardiovascular  as string[] )?.map((item: string) => `${readable(item)}, ` ) as unknown as string ?? "-"  }
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
          >
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              color={MuiTheme.palette.colors.success.dark}
              label="Endocrine"
              value={ (medicalHistory?.endocrine  as string[] )?.map((item: string) => `${readable(item)}, ` ) as unknown as string ?? "-"  }
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
          >
            <CardListItem
              icon={<ArrowRight fontSize="small" />}
              color={MuiTheme.palette.colors.warning.main}
              label="Other"
              value={readable(medicalHistory?.other as string) ?? "-"}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default MedicalHistoryCard;
