import { useRecoilValue } from "recoil";
import { Divider, Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { FieldsSubset } from "../../../../services/form-config.service";
import { selectedTemplateFieldsAtom } from "../../../../services/state.service";
import { CheckBoxField, SelectField } from "../../../../shared/components";
import { IClinicalAssessmentTemplate } from "../../../../interfaces/engangement.interface";
import { RedFlagQuestionnaire } from "../../../../interfaces/questionnaire.interface";

const redFlagOptions = [
    { key: "yes", value: true, label: "Yes" },
    { key: "notAsked", value: undefined, label: "Not Asked" },
    { key: "no", value: false, label: "No" },
];

type IClinicalAssessmentTemplateLite = Omit<
    IClinicalAssessmentTemplate,
    "redFlagQuestionnaire"
> & RedFlagQuestionnaire;

export const RedFlagQuestionnaires = () => {
    const templateFields = useRecoilValue<FieldsSubset>(
        selectedTemplateFieldsAtom
    );

    const { values } = useFormikContext<IClinicalAssessmentTemplateLite>();
    
    return (
        <Grid item container alignItems="flex-end" xs={12} rowSpacing={4} spacing={1}>

            {templateFields?.redFlagQuestionnaire && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}

            {templateFields?.redFlagQuestionnaire && (
                <Grid item xs={12}>
                    <Typography variant="h6">Red Flags</Typography>
                    <Typography variant="body1">
                        Clinicians should consider Diabetic Ketoacidosis (DKA)
                        while checking red flags for diabetes.
                    </Typography>
                </Grid>
            )}

            {templateFields?.severeHeadache && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Severe Headache
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="severeHeadache"
                                selectedValue={ values.severeHeadache ? values.severeHeadache : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.chestPain && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Chest Pain
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="chestPain"
                                selectedValue={ values.chestPain ? values.chestPain : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.palpitations && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Palpitations
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="palpitations"
                                selectedValue={ values.palpitations ? values.palpitations : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.shortnessOfBreath && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Shortness of Breath
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="shortnessOfBreath"
                                selectedValue={ values.shortnessOfBreath ? values.shortnessOfBreath : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.dizziness && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Dizziness
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="dizziness"
                                selectedValue={ values.dizziness ? values.dizziness : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.lethargyDrowsinessOrConfusion && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Lethargy, drowsiness or confusion
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="lethargyDrowsinessOrConfusion"
                                selectedValue={ values.lethargyDrowsinessOrConfusion ? values.lethargyDrowsinessOrConfusion : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.bloodInUrine && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Blood in Urine
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="bloodInUrine"
                                selectedValue={ values.bloodInUrine ? values.bloodInUrine : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.blurredVision && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Blurred Vision
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="blurredVision"
                                selectedValue={ values.blurredVision ? values.blurredVision : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.nauseaAndVomiting && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Nausea and Vomiting
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="nauseaAndVomiting"
                                selectedValue={ values.nauseaAndVomiting ? values.nauseaAndVomiting : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.abdominalPain && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Abdominal Pain
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="abdominalPain"
                                selectedValue={ values.abdominalPain ? values.abdominalPain : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.nosebleeds && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Nosebleeds
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="nosebleeds"
                                selectedValue={ values.nosebleeds ? values.nosebleeds : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.fastDeepOrDifficultBreathing && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Fast, Deep and difficulty breathing
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="fastDeepOrDifficultBreathing"
                                selectedValue={ values.fastDeepOrDifficultBreathing ? values.fastDeepOrDifficultBreathing : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.veryThirsty && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Very Thirsty
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="veryThirsty"
                                selectedValue={ values.veryThirsty ? values.veryThirsty : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.urinatingLotMoreThanUsual && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Urinating a lot more than usual
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="urinatingLotMoreThanUsual"
                                selectedValue={ values.urinatingLotMoreThanUsual ? values.urinatingLotMoreThanUsual : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.drySkinAndMouth && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Dry Skin and Mouth
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="drySkinAndMouth"
                                selectedValue={ values.drySkinAndMouth ? values.drySkinAndMouth : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.flushedFace && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Flushed Face
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="flushedFace"
                                selectedValue={ values.flushedFace ? values.flushedFace : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.muscleStiffnessOrAches && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Muscle Stiffness or Aches
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="muscleStiffnessOrAches"
                                selectedValue={ values.muscleStiffnessOrAches ? values.muscleStiffnessOrAches : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.evidenceOfKetones && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Evidence of Ketones
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="evidenceOfKetones"
                                selectedValue={ values.evidenceOfKetones ? values.evidenceOfKetones : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.capillaryBloodGlucoseLess2p8 && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Capillary Blood Glucose {`<`}2.8mmol
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="capillaryBloodGlucoseLess2p8"
                                selectedValue={ values.capillaryBloodGlucoseLess2p8 ? values.capillaryBloodGlucoseLess2p8 : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.assistanceToManageHypos && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2">
                                Requiring Assistance from Someone Else to Manage Hypos
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="assistanceToManageHypos"
                                selectedValue={ values.assistanceToManageHypos ? values.assistanceToManageHypos : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.persistentGlucoseMore15 && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Persistent Glucose {`>`}15
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="persistentGlucoseMore15"
                                selectedValue={ values.persistentGlucoseMore15 ? values.persistentGlucoseMore15 : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.acuteOnsetLimbSymptoms && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2">
                                Acute Oneset Limb Redness/pain/changes in
                                Colour/Sensation
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="acuteOnsetLimbSymptoms"
                                selectedValue={ values.acuteOnsetLimbSymptoms ? values.acuteOnsetLimbSymptoms : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.fruitySmellingBreath && (
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" sx={{ pt: 1 }}>
                                Fruity-Smelling Breath
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectField
                                label=""
                                name="fruitySmellingBreath"
                                selectedValue={ values.fruitySmellingBreath ? values.fruitySmellingBreath : false }
                                options={redFlagOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {templateFields?.redFlagQuestionnaire && (
                <Grid item xs={12}>
                    <CheckBoxField
                        label=""
                        name="safetyNettingForSelectedRedFlags"
                        position="horizontal"
                        selectedValue={ values.safetyNettingForSelectedRedFlags? 'advise_to_CallNHS' : '' }
                        options={[
                            {
                                key: "advise_to_CallNHS",
                                value:
                                    "advise_to_CallNHS",
                                label:
                                    "Advised to call A&E or NHS 111 if they develop any of these red flags",
                            },
                        ]}
                    />
                </Grid>
            )}

            {templateFields?.redFlagQuestionnaire && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}

        </Grid>
    );

}