import { isEqual, uniqueId } from "lodash";
import { Stack, Grid, Typography, Divider } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IAudit, IDifference, IUpdate } from "../../../interfaces/common.interface";
import { fromDB, humanReadable } from "../../../services/datetime.service";
import { TimelineContentItemAudit } from "./TimelineContentItemAudit";
import MuiTheme from "../../../shared/theme/MuiTheme";
import { LabelMapper } from "../../../shared/constants";

type AuditProps = {
  bgColour: string;
  audit: IAudit[];
};

const hasChanged = (v1: string[], v2: string[]) => (
  !isEqual(v1, v2)
)

const renderRedFlagsItmes = (value: IUpdate | IDifference) => {
  if (!value) { return false }
  if (value.newValue === null && value.oldValue === false) {
    return false;
  }
  return true;
}

const showRedFlagHeading = (redFlags: IDifference) => {
  if ((Object.keys(redFlags).length) !== 0) {
    const keys = Object.keys(redFlags);
    const res = keys.map((i) =>
      renderRedFlagsItmes(redFlags[i])
    )
    if (res.length === 1 && res[0] === false) {
      return res[0]
    }
  }else { return false}
  return true;
}

const findStringInBothArrays = (array1: string[], array2: string[], searchString: string) => {
  // means values is present in both(old & new) array so value is not changed
  if (array1.includes(searchString) && array2.includes(searchString)) {
    return false;
  }
  if (array1.includes(searchString) || array2.includes(searchString)) {
    return true;
  }
  return false;
}

export const ClinicalAssessmentAudit = ({ bgColour, audit }: AuditProps) => (
  <Stack>
    {audit &&
      audit.map(({ user, updates, datetime }: IAudit) => {
        const content = updates?.content as IDifference;
        return updates?.content ? (
          <Accordion key={uniqueId()}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: MuiTheme.palette.colors.grey.main }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: MuiTheme.palette.colors.grey.main }}>
                {`Updated by ${user?.name?.first} ${user?.name
                  ?.last} on ${humanReadable(datetime)}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing="4px">
                <Grid container>
                  {content?.appointment && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Appointment"
                          oldValue={
                            LabelMapper[content?.appointment.oldValue as string]
                          }
                          newValue={
                            LabelMapper[content?.appointment.newValue as string]
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.occurredAtDatetime && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack
                        spacing="6px"
                        direction="row"
                        alignItems="center"
                        gap={2}
                      >
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Date"
                          oldValue={
                            content?.occurredAtDatetime.oldValue
                              ? `${fromDB(
                                content?.occurredAtDatetime.oldValue as string
                              )}`
                              : `${"-"}`
                          }
                          newValue={
                            content?.occurredAtDatetime.newValue
                              ? `${fromDB(
                                content?.occurredAtDatetime.newValue as string
                              )}`
                              : `${"-"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.patientAttendance && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Patient Attendance"
                          oldValue={
                            LabelMapper[
                            content?.patientAttendance.oldValue as string
                            ]
                          }
                          newValue={
                            LabelMapper[
                            content?.patientAttendance.newValue as string
                            ]
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.patientIdentityConfirmed && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Identity Confirmed"
                          oldValue={
                            content?.patientIdentityConfirmed.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.patientIdentityConfirmed.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.isPatientRepresentativePresent && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Representative Present"
                          oldValue={
                            content?.isPatientRepresentativePresent.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.isPatientRepresentativePresent.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.isFailedEncounter && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Failed Encounter"
                          oldValue={
                            content?.isFailedEncounter.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.isFailedEncounter.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.isVideoCall && (
                    <Grid item xs={12} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Video Call"
                          oldValue={
                            content?.isVideoCall.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.isVideoCall.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {hasChanged(content?.urgentReviewDiabetes.oldValue, content?.urgentReviewDiabetes.newValue) && (
                    <>
                      <Grid item xs={12} sx={{ py: 2 }}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography><strong> Urgent Review (Diabetes) </strong></Typography>
                      </Grid>
                    </>
                  )}


                  {(findStringInBothArrays(content?.urgentReviewDiabetes.oldValue, content?.urgentReviewDiabetes.newValue, 'pre_history_dka')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='pre_history_dka'
                          oldValue={
                            content?.urgentReviewDiabetes.oldValue.find((i: string) => i === 'pre_history_dka')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.urgentReviewDiabetes.newValue.find((i: string) => i === 'pre_history_dka')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.urgentReviewDiabetes.oldValue, content?.urgentReviewDiabetes.newValue, 'medications_altered')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='medications_altered'
                          oldValue={
                            content?.urgentReviewDiabetes.oldValue.find((i: string) => i === 'medications_altered')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.urgentReviewDiabetes.newValue.find((i: string) => i === 'medications_altered')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {hasChanged(content?.urgentReviewHypertension.oldValue, content?.urgentReviewHypertension.newValue) && (
                    <>
                      <Grid item xs={12} sx={{ py: 2 }}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography><strong> Urgent Review (Hypertension) </strong></Typography>
                      </Grid>
                    </>
                  )}

                  {(findStringInBothArrays(content?.urgentReviewHypertension.oldValue, content?.urgentReviewHypertension.newValue, 'bp_checked')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='bp_checked'
                          oldValue={
                            content?.urgentReviewHypertension.oldValue.find((i: string) => i === 'bp_checked')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.urgentReviewHypertension.newValue.find((i: string) => i === 'bp_checked')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.urgentReviewHypertension.oldValue, content?.urgentReviewHypertension.newValue, 'recent_exercise')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='recent_exercise'
                          oldValue={
                            content?.urgentReviewHypertension.oldValue.find((i: string) => i === 'recent_exercise')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.urgentReviewHypertension.newValue.find((i: string) => i === 'recent_exercise')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.urgentReviewHypertension.oldValue, content?.urgentReviewHypertension.newValue, 'excessive_caffeine_intake')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='excessive_caffeine_intake'
                          oldValue={
                            content?.urgentReviewHypertension.oldValue.find((i: string) => i === 'excessive_caffeine_intake')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.urgentReviewHypertension.newValue.find((i: string) => i === 'excessive_caffeine_intake')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  { content?.redFlagQuestionnaire && showRedFlagHeading(content?.redFlagQuestionnaire as IDifference) && (
                    <>
                      <Grid item xs={12} sx={{ py: 2 }}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography><strong> Red Flags </strong></Typography>
                      </Grid>
                    </>
                  )}


                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.severeHeadache) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Severe Headache"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.severeHeadache.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.severeHeadache.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.chestPain) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Chest Pain"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.chestPain.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.chestPain.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}


                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.palpitations) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Palpitations"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.palpitations.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.palpitations.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.shortnessOfBreath) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Shortness of Breath"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.shortnessOfBreath.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.shortnessOfBreath.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.dizziness) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Dizziness"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.dizziness.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.dizziness.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.lethargyDrowsinessOrConfusion) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Lethargy, drowsiness or confusion"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.lethargyDrowsinessOrConfusion.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.lethargyDrowsinessOrConfusion.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.bloodInUrine) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Blood in Urine"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.bloodInUrine.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.bloodInUrine.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.blurredVision) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Blurred Vision"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.blurredVision.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.blurredVision.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.nauseaAndVomiting) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Nausea and Vomiting"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.nauseaAndVomiting.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.nauseaAndVomiting.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.abdominalPain) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Abdominal Pain"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.abdominalPain.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.abdominalPain.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.nosebleeds) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Nosebleeds"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.nosebleeds.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.nosebleeds.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.fastDeepOrDifficultBreathing) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Fast, Deep and difficulty breathing"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.fastDeepOrDifficultBreathing.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.fastDeepOrDifficultBreathing.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.veryThirsty) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Very Thirsty"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.veryThirsty.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.veryThirsty.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.urinatingLotMoreThanUsual) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Urinating a lot more than usual"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.urinatingLotMoreThanUsual.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.urinatingLotMoreThanUsual.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.drySkinAndMouth) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Dry Skin and Mouth"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.drySkinAndMouth.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.drySkinAndMouth.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.flushedFace) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Flushed Face"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.flushedFace.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.flushedFace.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.muscleStiffnessOrAches) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Muscle Stiffness or Aches"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.muscleStiffnessOrAches.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.muscleStiffnessOrAches.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.evidenceOfKetones) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Evidence of Ketones"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.evidenceOfKetones.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.evidenceOfKetones.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.capillaryBloodGlucoseLess2p8) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Capillary Blood Glucose <2.8mmol"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.capillaryBloodGlucoseLess2p8.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.capillaryBloodGlucoseLess2p8.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.assistanceToManageHypos) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Requiring Assistance from Someone Else to Manage Hypos"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.assistanceToManageHypos.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.assistanceToManageHypos.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.persistentGlucoseMore15) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Persistent Glucose >15"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.persistentGlucoseMore15.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.persistentGlucoseMore15.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.acuteOnsetLimbSymptoms) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Acute Oneset Limb Redness/pain/changes in Colour/Sensation"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.acuteOnsetLimbSymptoms.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.acuteOnsetLimbSymptoms.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.fruitySmellingBreath) && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Fruity-Smelling Breath"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.fruitySmellingBreath.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.fruitySmellingBreath.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {renderRedFlagsItmes((content?.redFlagQuestionnaire as IDifference)?.safetyNettingForSelectedRedFlags) && (
                    <Grid item xs={12} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Advised to call A&E or NHS 111 if they develop any of these red flags"
                          oldValue={
                            (content?.redFlagQuestionnaire as IDifference)?.safetyNettingForSelectedRedFlags.oldValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            (content?.redFlagQuestionnaire as IDifference)?.safetyNettingForSelectedRedFlags.newValue
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.hypertensionCaseFinding && (
                    <>
                      <Grid item xs={12} sx={{ py: 2 }}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography><strong> Case Finding (Hypertension) </strong></Typography>
                      </Grid>

                      <Grid item xs={4} sx={{ pb: 1 }}>
                        <Stack direction="row" alignItems="center" gap={2}>
                          <TimelineContentItemAudit
                            color={bgColour}
                            label="Outcome"
                            oldValue={
                              content?.hypertensionCaseFinding.oldValue
                            }
                            newValue={
                              content?.hypertensionCaseFinding.newValue
                            }
                          />
                        </Stack>
                      </Grid>
                    </>
                  )}

                  {(content?.bloodPressureHistory || content?.bloodPressurePlan || content?.bloodPressureMedicationChangeDetail) && (
                    <>
                      <Grid item xs={12} sx={{ py: 2 }}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography><strong> Blood Pressure </strong></Typography>
                      </Grid>
                    </>
                  )}


                  {content?.bloodPressureHistory && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="History"
                          oldValue={
                            content?.bloodPressureHistory?.oldValue
                          }
                          newValue={
                            content?.bloodPressureHistory?.newValue

                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.bloodPressurePlan && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Clinical Management Plan"
                          oldValue={
                            content?.bloodPressurePlan.oldValue
                          }
                          newValue={
                            content?.bloodPressurePlan.newValue
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.bloodPressureMedicationChanges && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Medication Changes"
                          oldValue={
                            content?.bloodPressureMedicationChanges.oldValue
                          }
                          newValue={
                            content?.bloodPressureMedicationChanges.newValue
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.bloodPressureMedicationChangeDetail && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Medication Change Detail"
                          oldValue={
                            content?.bloodPressureMedicationChangeDetail.oldValue
                          }
                          newValue={
                            content?.bloodPressureMedicationChangeDetail.newValue
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(content?.hypertensionHistory || content?.hypertensionPlan || content?.hypertensionMedicationChangeDetail) && (
                    <>
                      <Grid item xs={12} sx={{ py: 2 }}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography><strong>Hypertension </strong></Typography>
                      </Grid>
                    </>
                  )}

                  {content?.hypertensionHistory && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="History"
                          oldValue={
                            content?.hypertensionHistory?.oldValue

                          }
                          newValue={
                            content?.bloodPressureHistory?.newValue

                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.hypertensionPlan && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Clinical Management Plan"
                          oldValue={
                            content?.hypertensionPlan.oldValue
                          }
                          newValue={
                            content?.hypertensionPlan.newValue
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.hypertensionMedicationChanges && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Medication Changes"
                          oldValue={
                            content?.hypertensionMedicationChanges.oldValue
                          }
                          newValue={
                            content?.hypertensionMedicationChanges.newValue
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.hypertensionMedicationChangeDetail && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Medication Change Detail"
                          oldValue={
                            content?.hypertensionMedicationChangeDetail.oldValue
                          }
                          newValue={
                            content?.hypertensionMedicationChangeDetail.newValue
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(content?.diabetesHistory || content?.diabetesPlan || content?.diabetesMedicationChangeDetail) && (
                    <>
                      <Grid item xs={12} sx={{ py: 2 }}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography><strong>Diabetes </strong></Typography>
                      </Grid>
                    </>
                  )}

                  {content?.diabetesHistory && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="History"
                          oldValue={
                            content?.diabetesHistory?.oldValue
                          }
                          newValue={
                            content?.diabetesHistory?.newValue
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.diabetesPlan && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Clinical Management Plan"
                          oldValue={
                            content?.diabetesPlan.oldValue
                          }
                          newValue={
                            content?.diabetesPlan.newValue
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.diabetesMedicationChanges && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Medication Changes"
                          oldValue={
                            content?.diabetesMedicationChanges.oldValue
                          }
                          newValue={
                            content?.diabetesMedicationChanges.newValue
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {content?.diabetesMedicationChangeDetail && (
                    <Grid item xs={4} sx={{ pb: 1 }}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label="Medication Change Detail"
                          oldValue={
                            content?.diabetesMedicationChangeDetail.oldValue
                          }
                          newValue={
                            content?.diabetesMedicationChangeDetail.newValue
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {hasChanged(content?.medicationReview.oldValue, content?.medicationReview.newValue) && (
                    <>
                      <Grid item xs={12} sx={{ py: 2 }}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography><strong> Medication Review </strong></Typography>
                      </Grid>
                    </>
                  )}


                  {(findStringInBothArrays(content?.medicationReview.oldValue, content?.medicationReview.newValue, 'medication_compliance_checked')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='medication_compliance_checked'
                          oldValue={
                            content?.medicationReview.oldValue.find((i: string) => i === 'medication_compliance_checked')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.medicationReview.newValue.find((i: string) => i === 'medication_compliance_checked')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.medicationReview.oldValue, content?.medicationReview.newValue, 'allergies_confirmed')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='allergies_confirmed'
                          oldValue={
                            content?.medicationReview.oldValue.find((i: string) => i === 'allergies_confirmed')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.medicationReview.newValue.find((i: string) => i === 'allergies_confirmed')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.medicationReview.oldValue, content?.medicationReview.newValue, 'side_effects_discussed')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='side_effects_discussed'
                          oldValue={
                            content?.medicationReview.oldValue.find((i: string) => i === 'side_effects_discussed')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.medicationReview.newValue.find((i: string) => i === 'side_effects_discussed')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.medicationReview.oldValue, content?.medicationReview.newValue, 'prescription_issued')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='prescription_issued'
                          oldValue={
                            content?.medicationReview.oldValue.find((i: string) => i === 'prescription_issued')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.medicationReview.newValue.find((i: string) => i === 'prescription_issued')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {hasChanged(content?.investigationsOrdered.oldValue, content?.investigationsOrdered.newValue) && (
                    <>
                      <Grid item xs={12} sx={{ py: 2 }}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography><strong> Actions </strong></Typography>
                        <Typography><strong> Investigations Ordered </strong></Typography>
                      </Grid>
                    </>
                  )}


                  {(findStringInBothArrays(content?.investigationsOrdered.oldValue, content?.investigationsOrdered.newValue, 'ambulatory_blood_pressure')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='ambulatory_blood_pressure'
                          oldValue={
                            content?.investigationsOrdered.oldValue.find((i: string) => i === 'ambulatory_blood_pressure')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.investigationsOrdered.newValue.find((i: string) => i === 'ambulatory_blood_pressure')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.investigationsOrdered.oldValue, content?.investigationsOrdered.newValue, 'blood_test')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='blood_test'
                          oldValue={
                            content?.investigationsOrdered.oldValue.find((i: string) => i === 'blood_test')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.investigationsOrdered.newValue.find((i: string) => i === 'blood_test')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.investigationsOrdered.oldValue, content?.investigationsOrdered.newValue, 'urine_test')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='urine_test'
                          oldValue={
                            content?.investigationsOrdered.oldValue.find((i: string) => i === 'urine_test')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.investigationsOrdered.newValue.find((i: string) => i === 'urine_test')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.investigationsOrdered.oldValue, content?.investigationsOrdered.newValue, 'food_check')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='food_check'
                          oldValue={
                            content?.investigationsOrdered.oldValue.find((i: string) => i === 'food_check')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.investigationsOrdered.newValue.find((i: string) => i === 'food_check')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.investigationsOrdered.oldValue, content?.investigationsOrdered.newValue, 'physical_examination')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='physical_examination'
                          oldValue={
                            content?.investigationsOrdered.oldValue.find((i: string) => i === 'physical_examination')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.investigationsOrdered.newValue.find((i: string) => i === 'physical_examination')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.investigationsOrdered.oldValue, content?.investigationsOrdered.newValue, 'side_effects_discussed')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='side_effects_discussed'
                          oldValue={
                            content?.investigationsOrdered.oldValue.find((i: string) => i === 'side_effects_discussed')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.investigationsOrdered.newValue.find((i: string) => i === 'side_effects_discussed')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.investigationsOrdered.oldValue, content?.investigationsOrdered.newValue, 'other')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='other'
                          oldValue={
                            content?.investigationsOrdered.oldValue.find((i: string) => i === 'other')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.investigationsOrdered.newValue.find((i: string) => i === 'other')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}


                  {hasChanged(content?.adviceGiven.oldValue, content?.adviceGiven.newValue) && (
                    <>
                      <Grid item xs={12} sx={{ py: 2 }}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography><strong> Advice Given </strong></Typography>
                      </Grid>
                    </>
                  )}

                  {(findStringInBothArrays(content?.adviceGiven.oldValue, content?.adviceGiven.newValue, 'advice_on_vaccinations')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='advice_on_vaccinations'
                          oldValue={
                            content?.adviceGiven.oldValue.find((i: string) => i === 'advice_on_vaccinations')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.adviceGiven.newValue.find((i: string) => i === 'advice_on_vaccinations')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}


                  {(findStringInBothArrays(content?.adviceGiven.oldValue, content?.adviceGiven.newValue, 'advice_on_diabetic_eye_screening')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='advice_on_diabetic_eye_screening'
                          oldValue={
                            content?.adviceGiven.oldValue.find((i: string) => i === 'advice_on_diabetic_eye_screening')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.adviceGiven.newValue.find((i: string) => i === 'advice_on_diabetic_eye_screening')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {hasChanged(content?.onwardReferralCompleted.oldValue, content?.onwardReferralCompleted.newValue) && (
                    <>
                      <Grid item xs={12} sx={{ py: 2 }}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography><strong> Onward Referral Completed </strong></Typography>
                      </Grid>
                    </>
                  )}

                  {(findStringInBothArrays(content?.onwardReferralCompleted.oldValue, content?.onwardReferralCompleted.newValue, 'gp')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='gp'
                          oldValue={
                            content?.onwardReferralCompleted.oldValue.find((i: string) => i === 'gp')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.onwardReferralCompleted.newValue.find((i: string) => i === 'gp')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.onwardReferralCompleted.oldValue, content?.onwardReferralCompleted.newValue, 'hospital')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='hospital'
                          oldValue={
                            content?.onwardReferralCompleted.oldValue.find((i: string) => i === 'hospital')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.onwardReferralCompleted.newValue.find((i: string) => i === 'hospital')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(findStringInBothArrays(content?.onwardReferralCompleted.oldValue, content?.onwardReferralCompleted.newValue, 'ae')) && (
                    <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TimelineContentItemAudit
                          color={bgColour}
                          label='ae'
                          oldValue={
                            content?.onwardReferralCompleted.oldValue.find((i: string) => i === 'ae')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                          newValue={
                            content?.onwardReferralCompleted.newValue.find((i: string) => i === 'ae')
                              ? `${"Yes"}`
                              : `${"No"}`
                          }
                        />
                      </Stack>
                    </Grid>
                  )}

                </Grid>
              </Stack>
            </AccordionDetails>
          </Accordion>
        ) : null;
      })}
  </Stack>
);
