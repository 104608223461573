import { useRecoilValue } from "recoil";
import { Button, Grid, Typography, Divider } from "@mui/material";
import { useFormikContext } from "formik";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { selectedTemplateFieldsAtom } from "../../../../services/state.service";
import { FieldsSubset } from "../../../../services/form-config.service";
import {
  weightAdviceOptions,
  dietAdviceOptions,
  smokingAdviceOptions,
  exerciseAdviceOptions,
  IClinicalReadingTemplate,
  IClinicalAssessmentTemplate,
} from "../../../../interfaces/engangement.interface";
import { SelectField } from "../../../../shared/components";
import { LifestyleReading } from "../../Shared/LifestyleReading";

type LifestyleQuestionnaireProps = {
  lastLifeStyleSubmission: IClinicalReadingTemplate;
};

export const LifestyleQuestionnaire = ({
  lastLifeStyleSubmission,
}: LifestyleQuestionnaireProps) => {
  const templateFields = useRecoilValue<FieldsSubset>(
    selectedTemplateFieldsAtom
  );

  const { values } = useFormikContext<IClinicalAssessmentTemplate>();

  return (
    <Grid item container alignItems="flex-end" xs={12} rowSpacing={4}>
      {templateFields?.lifestyleQuestionnaire && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}

      {templateFields?.lifestyleQuestionnaire && (
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h6">Lifestyle Questionnaire</Typography>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <Button
                size="small"
                sx={{ textTransform: "none" }}
                endIcon={<OpenInNewIcon />}
              >
                Update Lifestyle Questionnaire
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {templateFields?.lifestyleQuestionnaire && (
        <Grid container>
          <LifestyleReading bgColour="#2196F3" content={lastLifeStyleSubmission}/>
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container spacing={1}>
            
          {templateFields?.lifestyleWeightAdvice && (
            <Grid item xs={3}>
              <SelectField
                label="Weight"
                name="lifestyleWeightAdvice"
                selectedValue={  values.lifestyleWeightAdvice?  values.lifestyleWeightAdvice : 'leave' }
                options={weightAdviceOptions()}
              />
            </Grid>
          )}

          {templateFields?.lifestyleSmokingAdvice && (
            <Grid item xs={3}>
              <SelectField
                label="Smoking"
                name="lifestyleSmokingAdvice"
                selectedValue={  values.lifestyleSmokingAdvice?  values.lifestyleSmokingAdvice : 'none' }
                options={smokingAdviceOptions()}
              />
            </Grid>
          )}

          {templateFields?.lifestyleDietAdvice && (
            <Grid item xs={3}>
              <SelectField
                label="Diet"
                name="lifestyleDietAdvice"
                selectedValue={  values.lifestyleDietAdvice?  values.lifestyleDietAdvice : 'good' }
                options={dietAdviceOptions()}
              />
            </Grid>
          )}

          {templateFields?.lifestyleExerciseAdvice && (
            <Grid item xs={3}>
              <SelectField
                label="Exercise"
                name="lifestyleExerciseAdvice"
                selectedValue={  values.lifestyleExerciseAdvice?  values.lifestyleExerciseAdvice : 'do_regular_exercise' }
                options={exerciseAdviceOptions()}
              />
            </Grid>
          )}

        </Grid>
      </Grid>

    </Grid>
  );
};
