

import { useRecoilValue } from "recoil";
import { Divider, Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { FieldsSubset } from "../../../../services/form-config.service";
import { selectedTemplateFieldsAtom } from "../../../../services/state.service";
import { CheckBoxField } from "../../../../shared/components";
import { IClinicalAssessmentTemplate, medicationReviewOptions } from "../../../../interfaces/engangement.interface";

export const MedicationReview = () => {
    const templateFields = useRecoilValue<FieldsSubset>(
        selectedTemplateFieldsAtom
    );

    const { values } = useFormikContext<IClinicalAssessmentTemplate>();

    return (
        <Grid item container alignItems="flex-end" xs={12} rowSpacing={4} spacing={1}>

            {templateFields?.medicationReview && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}

            {templateFields?.medicationReview && (
                <Grid item xs={12}>
                    <Typography variant="h6">Medication Review</Typography>
                </Grid>
            )}

            {templateFields?.medicationReview && (
                <Grid item xs={12}>
                    <CheckBoxField
                        label=""
                        name="medicationReview"
                        position="horizontal"
                        selectedValue={values.medicationReview.map((i: unknown) => (i))}
                        options={medicationReviewOptions()}
                    />
                </Grid>
            )}

        </Grid>
    );
}
