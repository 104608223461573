import { useFormik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedPatientAtom } from "../../../services/state.service";
import {
  IPatientPopulated,
  IMedicalHistory,
} from "../../../interfaces/patient.interface";
import {
  HTTP_SERVICE,
  ErrorResponse,
  isErrorResponse,
} from "../../../services/http.service";

interface MedicalHistoryFormProps {
  closeForm: () => void;
}

const checkValueExists = (property: string[] | string, val: string) => {
  if (!property) return false;

  if (Array.isArray(property)) {
    return property.includes(val);
  }
  return property === val;
};

export const MedicalHistoryForm = ({ closeForm }: MedicalHistoryFormProps) => {
  const [patient, setPatient] = useRecoilState(selectedPatientAtom);

  const { medicalHistory } = patient;

  const initialValuess: IMedicalHistory = {
    respiratory: medicalHistory?.respiratory ?? "",
    neurology: medicalHistory?.neurology ?? "",
    cardiovascular: medicalHistory?.cardiovascular ?? "",
    endocrine: medicalHistory?.endocrine ?? "",
    other: medicalHistory?.other ?? "",
  };

  const handleUpdateResponse = (updatedPatient: IPatientPopulated) => {
    if (updatedPatient) {
      closeForm();
      setPatient(updatedPatient);
    }
  };

  const formik = useFormik({
    initialValues: initialValuess,
    onSubmit: (values: IMedicalHistory) => {
      HTTP_SERVICE.updatePatient(patient._id, { medicalHistory: values }).then(
        (result: IPatientPopulated | ErrorResponse): void => {
          if (!isErrorResponse(result)) {
            handleUpdateResponse(result);
          }
        }
      );
    },
  });

  return (
    <div>
      <Box
        sx={{
          mt: 4,
          height: "50px",
          width: "100%",
        }}
      >
        <Typography variant="h6">Update Past Medical History</Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography variant="h6">Respiratory</Typography>
            </Grid>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={checkValueExists(
                      medicalHistory?.respiratory,
                      "asthma"
                    )}
                    name="respiratory"
                    value="asthma"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Asthma"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={checkValueExists(
                      medicalHistory?.respiratory,
                      "COPD"
                    )}
                    name="respiratory"
                    value="COPD"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
                label="COPD"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography variant="h6">Neurology</Typography>
            </Grid>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={checkValueExists(
                      medicalHistory?.neurology,
                      "stroke"
                    )}
                    name="neurology"
                    value="stroke"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Stroke"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={checkValueExists(
                      medicalHistory?.neurology,
                      "dementia"
                    )}
                    name="neurology"
                    value="dementia"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Dementia"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography variant="h6">Cardiovascular</Typography>
            </Grid>
            <FormGroup>
              <Box display="flex" justifyContent="flex-end">
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={checkValueExists(
                          medicalHistory?.cardiovascular,
                          "essential_hypertension"
                        )}
                        name="cardiovascular"
                        value="essential_hypertension"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    }
                    label="Essential Hypertension"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={checkValueExists(
                          medicalHistory?.cardiovascular,
                          "resistant_hypertension"
                        )}
                        name="cardiovascular"
                        value="resistant_hypertension"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    }
                    label="Resistant Hypertension"
                  />
                </Grid>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={checkValueExists(
                          medicalHistory?.cardiovascular,
                          "heart_failure"
                        )}
                        name="cardiovascular"
                        value="heart_failure"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    }
                    label="Heart Failure"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={checkValueExists(
                          medicalHistory?.cardiovascular,
                          "atrial_fibrillation"
                        )}
                        name="cardiovascular"
                        value="atrial_fibrillation"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    }
                    label="Atrial Fibrillation"
                  />
                </Grid>
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography variant="h6">Endocrine</Typography>
            </Grid>
            <FormGroup>
              <Box display="flex" justifyContent="flex-end">
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={checkValueExists(
                          medicalHistory?.endocrine,
                          "diabetes_type_1"
                        )}
                        name="endocrine"
                        value="diabetes_type_1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    }
                    label="Diabetes Type I"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={checkValueExists(
                          medicalHistory?.endocrine,
                          "diabetes_type_2"
                        )}
                        name="endocrine"
                        value="diabetes_type_2"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    }
                    label="Diabetes Type II"
                  />
                </Grid>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={checkValueExists(
                          medicalHistory?.endocrine,
                          "hyperthyroidism"
                        )}
                        name="endocrine"
                        value="hyperthyroidism"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    }
                    label="Hyperthyroidism"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={checkValueExists(
                          medicalHistory?.endocrine,
                          "hypothyroidism"
                        )}
                        name="endocrine"
                        value="hypothyroidism"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    }
                    label="Hypothyroidism"
                  />
                </Grid>
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Other
            </Typography>
            <TextField
              defaultValue={medicalHistory?.other}
              label="Other"
              multiline
              rows={4}
              name="other"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mt: 2 }} />
          </Grid>

          <Grid item xs={6}>
            <Button type="submit" variant="contained" size="small">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default MedicalHistoryForm;
