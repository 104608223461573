import { Stack, Grid } from "@mui/material";
import { uniqueId } from "lodash";
import { IReading } from "../../../interfaces/questionnaire.interface";
import { readable } from "../../../interfaces/utils";
import { TimelineContentItem } from "./TimelineContentItem";

type ReadingsProps = {
  readings: IReading[];
  bgColour?: string | undefined;
};

export const Reading = ({ bgColour, readings }: ReadingsProps) => (
  <Stack spacing="4px">
    <Grid container>
      {readings.map((reading: IReading) => (
        <Grid item xs={4} key={uniqueId()} sx={{ pb: 1 }}>
        <Stack direction="row" alignItems="center" gap={2} > 
          <TimelineContentItem color={bgColour} label={readable(reading.parameter)} value={readable(reading.value)}   />
          </Stack>
        </Grid>
      ))}
    </Grid>
  </Stack>
);
