import { enumToKeyValuePair, ListOptions, StringExtension } from "./utils";

/**
 * Life Style Questi
 *
 */
export enum EPhysicalActivityStatus {
  very_active = "very_active",
  active = "active",
  slightly_active = "slightly_active",
  not_very_active = "not_very_active",
  do_not_know = "do_not_know",
}

export type PhysicalActivityStatus =
  | StringExtension<keyof typeof EPhysicalActivityStatus>
  | undefined;

export const physicalActivityStatusOptions =
  (): ListOptions<EPhysicalActivityStatus>[] =>
    enumToKeyValuePair(EPhysicalActivityStatus);

export enum EDietStatus {
  good = "good",
  average = "average",
  poor = "poor",
  do_not_know = "do_not_know",
}

export type DietStatus = StringExtension<keyof typeof EDietStatus> | undefined;

export const dietStatusOptions = (): ListOptions<EDietStatus>[] =>
  enumToKeyValuePair(EDietStatus);

export enum EVapingStatus {
  yes = "yes",
  socially = "socially",
  quit = "quit",
  never = "never",
  do_not_know = "do_not_know",
}

export type VapingStatus =
  | StringExtension<keyof typeof EVapingStatus>
  | undefined;

export const vapingStatusOptions = (): ListOptions<EVapingStatus>[] =>
  enumToKeyValuePair(EVapingStatus);

export enum ESmokingStatus {
  less_than_1 = "less_than_1",
  ten_to_ninteen = "ten_to_ninteen",
  more_than_20 = "more_than_20",
  ex_smoker = "ex_smoker",
  currently_smoking = "currently_smoking",
  do_not_know = "do_not_know",
}

export type SmokingStatus = keyof typeof ESmokingStatus | undefined;

export const smokingStatusOptions = (): ListOptions<ESmokingStatus>[] =>
  enumToKeyValuePair(ESmokingStatus);

export enum EAlcoholStatus {
  ex_drinker = "ex_drinker",
  drinking = "drinking",
  do_not_know = "do_not_know",
}

export type AlcoholStatus =
  | StringExtension<keyof typeof EAlcoholStatus>
  | undefined;

export const alocholStatusOptions = (): ListOptions<EAlcoholStatus>[] =>
  enumToKeyValuePair(EAlcoholStatus);

export enum ELifeStyleGoal {
  be_more_active = "be_more_active",
  improve_their_diet = "improve_their_diet",
  manage_their_condition_better = "manage_their_condition_better",
  different_goal = "different_goal",
  do_not_know = "do_not_know",
}

export type LifeStyleGoal =
  | StringExtension<keyof typeof ELifeStyleGoal>
  | undefined;

export const goalOptions = (): ListOptions<ELifeStyleGoal>[] =>
  enumToKeyValuePair(ELifeStyleGoal);

export enum EConfidenceStatus {
  very_confident = "very_confident",
  confident = "confident",
  uncertain = "uncertain",
  unconfident = "unconfident",
  very_unconfident = "very_unconfident",
  do_not_know = "do_not_know",
}

export type ConfidenceStatus =
  | StringExtension<keyof typeof EConfidenceStatus>
  | undefined;

export const confidenceStatusOptions = (): ListOptions<EConfidenceStatus>[] =>
  enumToKeyValuePair(EConfidenceStatus);

export type LifestyleQuestionnaire = {
  bmi: string | undefined;
  weight: string | undefined;
  height: string | undefined;
  confidence: string | undefined;
  goal: LifeStyleGoal;
  diet: DietStatus;
  physicalActivity: PhysicalActivityStatus;
  vaping: VapingStatus;
  smoking: SmokingStatus;
  smokingYearQuit: string | undefined;
  alcoholUnitsPerWeek: AlcoholStatus;
};

export enum ELifestyleParameter {
  bmi = "bmi",
  height = "height",
  weight = "weight",
  confidence = "confidence",
  goal = "goal",
  diet = "diet",
  physical_activity = "physical_activity",
  vaping = "vaping",
  smoking = "smoking",
  smoking_year_quit = "smoking_year_quit",
  alcohol_units_per_week = "alcohol_units_per_week",
}

export type LifestyleParameter = keyof typeof ELifestyleParameter;

export const lifestyleParameterOptions =
  (): ListOptions<LifestyleParameter>[] =>
    enumToKeyValuePair(ELifestyleParameter);

/**
 *
 * Blood Pressure Reading Form
 *
 */
export enum EBloodPressureParameter {
  blood_pressure_systole = "blood_pressure_systole",
  blood_pressure_diastole = "blood_pressure_diastole",
  pulse_rate = "pulse_rate",
}

export type BloodPressureParameter = keyof typeof EBloodPressureParameter;

export const bloodPressureParameterOptions =
  (): ListOptions<BloodPressureParameter>[] =>
    enumToKeyValuePair(EBloodPressureParameter);

/**
 *
 * Diabetes Questionnaire Form
 *
 */
export type DiabetesQuestionnaire = {
  doesHomeGlucose: YesOrNo | undefined;
  glucoseBelow4: YesOrNo | undefined;
  glucoseBelow2p8: YesOrNo | undefined;
  experiencesHypos: YesOrNo | undefined;
  symptomsDuringHypos: YesOrNo | undefined;
  timesHelpDuringHypos: string | undefined;
  dateLastHypo: string | undefined;
  bloodSugarToTreat: string | undefined;
  numTimesSugarLess4: string | undefined;
  eatDrinkToTreat: string | undefined;
  checkSugarBeforeDriving: YesOrNo | undefined;
  fastingAtTimeOfHypo: YesOrNo | undefined;
};

export enum EDiabetesParameter {
  does_home_glucose = "does_home_glucose",
  glucose_below_4 = "glucose_below_4",
  glucose_below_2p8 = "glucose_below_2p8",
  experiences_hypos = "experiences_hypos",
  symptoms_during_hypos = "experiences_hypos",
  times_help_during_hypos = "times_help_during_hypos",
  date_last_hypo = "date_last_hypo",
  blood_sugar_to_treat = "blood_sugar_to_treat",
  num_times_sugar_less_4 = "num_times_sugar_less_4",
  eat_drink_to_treat = "eat_drink_to_treat",
  check_sugar_before_driving = "check_sugar_before_driving",
  fasting_at_time_of_hypo = "fasting_at_time_of_hypo",
}

export type DiabetesParameter = keyof typeof EDiabetesParameter;

export const diabetesParameterOptions = (): ListOptions<DiabetesParameter>[] =>
  enumToKeyValuePair(EDiabetesParameter);

export enum EYesOrNo {
  yes = "yes",
  no = "no",
  not_defined = "not-defined",
}

type YesOrNo = keyof typeof EYesOrNo;

export const yesOrNoOptions = (): ListOptions<YesOrNo>[] =>
  enumToKeyValuePair(EYesOrNo);

export enum EOtherParameters {
  curious_character_finding = "curious_character_finding",
  anxious_character_finding = "anxious_character_finding",
  dietary_sodium_intake_finding = "dietary_sodium_intake_finding",
  identifying_barriers_to_goal_achievement_procedure = "identifying_barriers_to_goal_achievement_procedure",
}

export type OtherParameter = keyof typeof EOtherParameters;

export const otherParameterOptions = (): ListOptions<OtherParameter>[] =>
  enumToKeyValuePair(EOtherParameters);

export enum RedFlagQuestionnaireOptions {
  yes = "true",
  no = "false",
  not_asked = "undefined",
}

export type RedFlagQuestionnaire = {
  abdominalPain?: undefined | boolean;
  acuteOnsetLimbSymptoms?: undefined | boolean;
  assistanceToManageHypos?: undefined | boolean;
  bloodInUrine?: undefined | boolean;
  blurredVision?: undefined | boolean;
  capillaryBloodGlucoseLess2p8?: undefined | boolean;
  chestPain?: undefined | boolean;
  dizziness?: undefined | boolean;
  drySkinAndMouth?: undefined | boolean;
  evidenceOfKetones?: undefined | boolean;
  fastDeepOrDifficultBreathing?: undefined | boolean;
  flushedFace?: undefined | boolean;
  fruitySmellingBreath?: undefined | boolean;
  lethargyDrowsinessOrConfusion?: undefined | boolean;
  muscleStiffnessOrAches?: undefined | boolean;
  nauseaAndVomiting?: undefined | boolean;
  nosebleeds?: undefined | boolean;
  palpitations?: undefined | boolean;
  persistentGlucoseMore15?: undefined | boolean;
  severeHeadache?: undefined | boolean;
  shortnessOfBreath?: undefined | boolean;
  urinatingLotMoreThanUsual?: undefined | boolean;
  veryThirsty?: undefined | boolean;
  safetyNettingForSelectedRedFlags?: undefined | boolean;
};

export enum EFormType {
  lifestyle_questionnaire_form = "lifestyle_questionnaire_form",
  diabetes_questionnaire_form = "diabetes_questionnaire_form",
  red_flag_questionnaire_form = "red_flag_questionnaire_form",
  blood_pressure_reading_form = "blood_pressure_reading_form",
}

export const formTypeOptions = (): ListOptions<FormType>[] =>
  enumToKeyValuePair(EFormType);

export type FormType = keyof typeof EFormType;

export type Parameter =
  | BloodPressureParameter
  | LifestyleParameter
  | DiabetesParameter
  | OtherParameter;

export interface IReading {
  // _id: string;
  parameter: Parameter;
  value: string;
  units?: string;
  note?: string;
  datetime: string;
}
