
import { useRecoilValue } from "recoil";
import { Divider, Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { FieldsSubset } from "../../../../services/form-config.service";
import { selectedTemplateFieldsAtom } from "../../../../services/state.service";
import { CheckBoxField } from "../../../../shared/components";
import { IClinicalAssessmentTemplate, investigationsOrderedActionOptions } from "../../../../interfaces/engangement.interface";

export const Actions = () => {
    const templateFields = useRecoilValue<FieldsSubset>(
        selectedTemplateFieldsAtom
    );

    const { values } = useFormikContext<IClinicalAssessmentTemplate>();

    return (
        <Grid item container alignItems="flex-end" xs={12} rowSpacing={4} spacing={1}>

            {templateFields?.investigationsOrdered && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}

            {templateFields?.investigationsOrdered && (
                <Grid item xs={12}>
                    <Typography variant="h6">Actions</Typography>
                </Grid>
            )}

            {templateFields?.investigationsOrdered && (
                <Grid item xs={12}>
                    <CheckBoxField
                        label="Investigations Ordered"
                        name="investigationsOrdered"
                        position="horizontal"
                        selectedValue={values.investigationsOrdered.map((i: unknown) => (i))}
                        options={investigationsOrderedActionOptions()}
                    />
                </Grid>
            )}

        </Grid>
    );
}

