import { useRecoilValue } from "recoil";
import { Divider, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { FieldsSubset } from "../../../../services/form-config.service";
import { selectedTemplateFieldsAtom } from "../../../../services/state.service";
import { CheckBoxField } from "../../../../shared/components";
import { IClinicalAssessmentTemplate, adviceGivenOptions } from "../../../../interfaces/engangement.interface";

export const AdviceGiven = () => {
    const templateFields = useRecoilValue<FieldsSubset>(
        selectedTemplateFieldsAtom
    );

    const { values } = useFormikContext<IClinicalAssessmentTemplate>();

    return (
        <Grid item container alignItems="flex-end" xs={12} rowSpacing={4} spacing={1}>

            {templateFields?.adviceGiven && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}

            {templateFields?.adviceGiven && (
                <Grid item xs={12}>
                        <CheckBoxField
                            label="Advice Given"
                            name="adviceGiven"
                            position="horizontal"
                            selectedValue={values.adviceGiven.map((i: unknown) => (i))}
                            options={adviceGivenOptions()}
                        />
                </Grid>
            )}

        </Grid>
    );

}
