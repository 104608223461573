import { Typography, Grid } from "@mui/material";

type FieldValue = string | string[];

interface DisplayObjectProps {
  data: { [key: string]: FieldValue };
}

const formatValue = (value: FieldValue) => {
  if (Array.isArray(value)) {
    return value.toString();
  }

  return value;
};

const DisplayObject = ({ data }: DisplayObjectProps) => (
  <>
    {data && (
      <Grid container spacing={2}>
        {Object.entries(data).map(
          ([key, value], index) =>
            formatValue(value) && (
              <Grid item xs={12} key={index}>
                <Grid item xs={12}>
                  <Typography>
                    <strong>{key}:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} key={index + 1}>
                  <Typography>{formatValue(value)}</Typography>
                </Grid>
              </Grid>
            )
        )}
      </Grid>
    )}
  </>
);

export default DisplayObject;
