import { Box, Button, Typography } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import {
  ErrorResponse,
  isErrorResponse,
  HTTP_SERVICE,
} from "../../services/http.service";
import { persistToken } from "../../services/auth.service";
import { AuthSuccessResponse } from "../../interfaces/auth.interface";

type SignProps = {
  redirectURL: string | undefined;
};

export const SignIn = ({ redirectURL }: SignProps) => {

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      HTTP_SERVICE.authenticate({ token: codeResponse.access_token })
        .then((result: AuthSuccessResponse | ErrorResponse) => {
          if (!isErrorResponse(result)) {
            persistToken(result);
          }
          if (redirectURL) window.location.href = redirectURL;
        })
        .catch();
    },
    // onError: (codeResponse) => console.log(codeResponse),
  });

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "80px",
          margin: "auto",
          border: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <img
          src="/logo-black.png"
          width="240px"
          style={{
            marginBottom: 50,
          }}
          alt="Nutrisphere logo"
        />
        <Typography variant="h6">Care Dashboard</Typography>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => login()}
        >
          Sign In
        </Button>
      </Box>
    </Box>
  );
};
