import { Field, FieldProps } from "formik";
import { MenuItem, TextField } from "@mui/material";
import { uniqueId } from "lodash";
import { ListOptions } from "../../../interfaces/utils";

interface SelectFieldProps {
  name: string;
  label: string | undefined;
  selectedValue: string | boolean | undefined;
  options: ListOptions<unknown>[];
}

const SelectField = (props: SelectFieldProps) => {
  const { label, name, selectedValue, options } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps<string>) => {
        const { handleChange, handleBlur } = fieldProps.form;
        const { error, touched } = fieldProps.meta;
        return (
          <TextField
            select
            label={label}
            variant="outlined"
            size="small"
            name={name}
            value={selectedValue}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched && Boolean(error)}
            helperText={touched && error}
            fullWidth
          >
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              options.map((option: ListOptions<any>) => (
                <MenuItem key={uniqueId()} value={option.value}>
                  {option.label}
                </MenuItem>
              ))
            }
          </TextField>
        );
      }}
    </Field>
  );
};

export default SelectField;
