import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Field, FieldProps } from "formik";
import { uniqueId } from "lodash";
import { ListOptions } from "../../../interfaces/utils";

// type SelectOptionType = {
//     key: string;
//     value: string;
// }

interface RadioButtonFieldProps {
  name: string;
  label: string;
  defaultValue: string | undefined;
  position: "horizontal" | "vertical";
  options: ListOptions<unknown>[];
}

const RadioButtonField = (props: RadioButtonFieldProps) => {
  const { label, name, defaultValue, position, options } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps<string>) => {
        const { handleChange } = fieldProps.form;
        return (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">{label}</Typography>
            </Grid>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={defaultValue}
                name={name}
                onChange={handleChange}
                sx={
                  position === "horizontal"
                    ? {
                        position: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  options.map((option: ListOptions<any>) => (
                    <FormControlLabel
                      key={uniqueId()}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))
                }
              </RadioGroup>
            </FormControl>
          </>
        );
      }}
    </Field>
  );
};

export default RadioButtonField;
