import { useRecoilValue } from "recoil";
import { useFormik } from "formik";
import * as z from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  getLoggedUser,
  selectedPatientId,
} from "../../../services/state.service";
import {
  ESubmissionTypes,
  IEngagement,
  IClinicalReadingTemplate,
  IClinicalReading,
  EEngagementTypes,
} from "../../../interfaces/engangement.interface";
import {
  IReading,
  ELifestyleParameter,
  LifestyleParameter,
  PhysicalActivityStatus,
  physicalActivityStatusOptions,
  DietStatus,
  dietStatusOptions,
  EFormType,
  SmokingStatus,
  smokingStatusOptions,
  vapingStatusOptions,
  VapingStatus,
  confidenceStatusOptions,
  ConfidenceStatus,
  LifestyleQuestionnaire,
} from "../../../interfaces/questionnaire.interface";
import { ListOptions, OmitID, Replace } from "../../../interfaces/utils";
import {
  HTTP_SERVICE,
  ErrorResponse,
  isErrorResponse,
} from "../../../services/http.service";
import { DateTime } from "../../../services/datetime.service";

type LifeStyleReadingForm = LifestyleQuestionnaire;

type FormSubmission = "sms" | "email" | "phone";

const formSubmissionTypeToClinicianSubmissionTypes: {
  [key in FormSubmission]: ESubmissionTypes;
} = {
  sms: ESubmissionTypes.clinical_submission_by_sms,
  email: ESubmissionTypes.clinical_submission_by_email,
  phone: ESubmissionTypes.clinical_submission_by_phone,
};

type ILifeStyleForm = Replace<
  Replace<IClinicalReadingTemplate, "readings", LifeStyleReadingForm>,
  "submission",
  FormSubmission
> & {
  note: string;
};

const lifeStyleReading = (): LifestyleQuestionnaire => ({
  weight: "",
  height: "",
  bmi: "",
  confidence: "very_confident",
  goal: "do_not_know",
  diet: "do_not_know",
  physicalActivity: "do_not_know",
  vaping: "do_not_know",
  smoking: "do_not_know",
  smokingYearQuit: "",
  alcoholUnitsPerWeek: "do_not_know",
});

const convertToDBFormat = (
  param: LifestyleParameter,
  value: string,
  datetime?: string | undefined
): IReading[] => [
  {
    parameter: param,
    value: String(value),
    datetime: datetime ?? "",
  },
];

const intialValues: ILifeStyleForm = {
  formType: EFormType.lifestyle_questionnaire_form,
  submission: "sms",
  readings: lifeStyleReading(),
  note: "",
};

interface LifestyleReadingFormProps {
  closeForm: () => void;
}

const validationSchema = z.object({
  submission: z.string(),
  readings: z.object({
    weight: z.number().gte(1).lte(9999,{ message: "Weight must contain at most 4 digit(s)" }) ,
    height: z.number().gte(1).lte(999,{ message: "Height must contain at most 3 digit(s)" }),
    bmi: z.number().gte(1).lte(999,{ message: "Height must contain at most 3 digit(s)" }),
    smokingYearQuit: z.number().gte(1).lte(99, { message: "Must contain at most 2 digit(s)"}),
    alcoholUnitsPerWeek: z.number().gte(1).lte(999, { message: "Must contain at most 3 digit(s)"}),
  }),
  note: z.string().min(10),
});

export const LifestyleReadingForm = ({
  closeForm,
}: LifestyleReadingFormProps) => {
  const user = useRecoilValue(getLoggedUser);
  const patientId = useRecoilValue(selectedPatientId);

  const formik = useFormik({
    initialValues: intialValues,
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: (values: ILifeStyleForm) => {
      const { readings, note, submission } = values;
      const newClinialEngagement: OmitID<IClinicalReading> = {
        type: EEngagementTypes.clinical_reading,
        user: user?._id as string,
        patient: patientId as string,
        content: {
          formType: EFormType.lifestyle_questionnaire_form,
          submission: formSubmissionTypeToClinicianSubmissionTypes[submission],
          readings: [
            convertToDBFormat(
              ELifestyleParameter.weight,
              readings.weight as string
            ),
            convertToDBFormat(
              ELifestyleParameter.height,
              readings.height as string
            ),
            convertToDBFormat(ELifestyleParameter.bmi, readings.bmi as string),
            convertToDBFormat(
              ELifestyleParameter.confidence,
              readings.confidence as string
            ),
            convertToDBFormat(
              ELifestyleParameter.goal,
              readings.goal as string
            ),
            convertToDBFormat(
              ELifestyleParameter.diet,
              readings.diet as string
            ),
            convertToDBFormat(
              ELifestyleParameter.physical_activity,
              readings.physicalActivity as string
            ),
            convertToDBFormat(
              ELifestyleParameter.vaping,
              readings.vaping as string
            ),
            convertToDBFormat(
              ELifestyleParameter.smoking,
              readings.smoking as string
            ),
            convertToDBFormat(
              ELifestyleParameter.smoking_year_quit,
              readings.smokingYearQuit as string
            ),
            convertToDBFormat(
              ELifestyleParameter.alcohol_units_per_week,
              readings.alcoholUnitsPerWeek as string
            ),
          ],
        },
        note,
        datetime: DateTime.now().toString(),
      };

      HTTP_SERVICE.createEngagement(
        newClinialEngagement as Partial<IEngagement>
      ).then((result: IEngagement | ErrorResponse): void => {
        if (!isErrorResponse(result)) {
          closeForm();
        }
      });
    },
  });

  return (
    <div>
      <Box
        sx={{
          height: "50px",
          width: "100%",
        }}
      >
        <Typography variant="h6">Add Lifestyle Readings</Typography>
      </Box>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Select Submission Option"
              variant="outlined"
              size="small"
              name="submission"
              select
              defaultValue={formik.initialValues.submission}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.submission && Boolean(formik.errors.submission)
              }
              helperText={formik.touched.submission && formik.errors.submission}
              fullWidth
            >
              <MenuItem value="">No Selected</MenuItem>
              <MenuItem key="sms-submission-type" value="sms">
                SMS
              </MenuItem>
              <MenuItem key="email-submission-type" value="email">
                Email
              </MenuItem>
              <MenuItem key="phone-submission-type" value="phone">
                Phone
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <Typography> </Typography>
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="How much do they currently weight?(kg)"
              variant="outlined"
              size="small"
              type="number"
              name="readings.weight"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.readings?.weight &&
                Boolean(formik.errors.readings?.weight)
              }
              helperText={
                formik.touched.readings?.weight &&
                formik.errors.readings?.weight
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="What is their height?(cm)"
              variant="outlined"
              size="small"
              type="number"
              name="readings.height"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.readings?.height &&
                Boolean(formik.errors.readings?.height)
              }
              helperText={
                formik.touched.readings?.height &&
                formik.errors.readings?.height
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="BMI-this will be automatically calculated"
              variant="outlined"
              size="small"
              type="number"
              name="readings.bmi"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.readings?.bmi &&
                Boolean(formik.errors.readings?.bmi)
              }
              helperText={
                formik.touched.readings?.bmi && formik.errors.readings?.bmi
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <Grid item xs={12}>
                {/* <Typography variant="h6">What is their confidence level?</Typography> */}
                <FormLabel>What is their confidence level?</FormLabel>
              </Grid>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="readings.confidence"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.initialValues.readings?.confidence}
              >
                {[...confidenceStatusOptions()].map(
                  (i: ListOptions<ConfidenceStatus>) => (
                    <FormControlLabel
                      key={i.key}
                      value={i.value}
                      control={<Radio />}
                      label={i.label}
                    />
                  )
                )}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <Grid item xs={12}>
                <FormLabel>What is their goal?</FormLabel>
              </Grid>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="readings.goal"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.initialValues.readings?.goal}
              >
                {[...physicalActivityStatusOptions()].map(
                  (i: ListOptions<PhysicalActivityStatus>) => (
                    <FormControlLabel
                      key={i.key}
                      value={i.value}
                      control={<Radio />}
                      label={i.label}
                    />
                  )
                )}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <Grid item xs={12}>
                <FormLabel>Which best describes their diet?</FormLabel>
              </Grid>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="readings.diet"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.initialValues.readings?.diet}
              >
                {[...dietStatusOptions()].map((i: ListOptions<DietStatus>) => (
                  <FormControlLabel
                    key={i.key}
                    value={i.value}
                    control={<Radio />}
                    label={i.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <Grid item xs={12}>
                <FormLabel>What is their activity level?</FormLabel>
              </Grid>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="readings.physicalActivity"
                onChange={formik.handleChange}
                defaultValue={formik.initialValues.readings?.physicalActivity}
              >
                {[...physicalActivityStatusOptions()].map(
                  (i: ListOptions<PhysicalActivityStatus>) => (
                    <FormControlLabel
                      key={i.key}
                      value={i.value}
                      control={<Radio />}
                      label={i.label}
                    />
                  )
                )}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <Grid item xs={12}>
                <FormLabel>Do they vape?</FormLabel>
              </Grid>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="readings.vaping"
                onChange={formik.handleChange}
                defaultValue={formik.initialValues.readings?.vaping}
              >
                {[...vapingStatusOptions()].map(
                  (i: ListOptions<VapingStatus>) => (
                    <FormControlLabel
                      key={i.key}
                      value={i.value}
                      control={<Radio />}
                      label={i.label}
                    />
                  )
                )}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <Grid item xs={12}>
                <FormLabel>Do they smoke?</FormLabel>
              </Grid>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="readings.smoking"
                onChange={formik.handleChange}
                defaultValue={formik.initialValues.readings?.smoking}
              >
                {[...smokingStatusOptions()].map(
                  (i: ListOptions<SmokingStatus>) => (
                    <FormControlLabel
                      key={i.key}
                      value={i.value}
                      control={<Radio />}
                      label={i.label}
                    />
                  )
                )}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Year Quit"
              variant="outlined"
              size="small"
              type="number"
              name="readings.smokingYearQuit"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.readings?.smokingYearQuit &&
                Boolean(formik.errors.readings?.smokingYearQuit)
              }
              helperText={
                formik.touched.readings?.smokingYearQuit &&
                formik.errors.readings?.smokingYearQuit
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <FormLabel>
              How many units of alcohole do they drink in normal week?
            </FormLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Units"
              variant="outlined"
              size="small"
              type="number"
              name="readings.alcoholUnitsPerWeek"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.readings?.alcoholUnitsPerWeek &&
                Boolean(formik.errors.readings?.alcoholUnitsPerWeek)
              }
              helperText={
                formik.touched.readings?.alcoholUnitsPerWeek &&
                formik.errors.readings?.alcoholUnitsPerWeek
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Notes"
              multiline
              rows={4}
              name="note"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.note && Boolean(formik.errors.note)}
              helperText={formik.touched.note && formik.errors.note}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <Button type="submit" variant="contained" size="small">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default LifestyleReadingForm;
