import { IAudit } from "./common.interface";
import {
  RedFlagQuestionnaire,
  FormType,
  IReading,
  LifestyleQuestionnaire,
  DiabetesQuestionnaire,
  EFormType,
} from "./questionnaire.interface";
import { ITask } from "./task.interface";
import { enumToKeyValuePair, ListOptions } from "./utils";

export enum EEngagementTypes {
  clinical_reading = "clinical_reading",
  clinical_review = "clinical_review",
  consultation = "consultation",
  other = "other",
}

export type EngagementType = keyof typeof EEngagementTypes;

export const engagementTypeOptions = (): ListOptions<EngagementType>[] =>
  enumToKeyValuePair(EEngagementTypes);

export type IEngagementContent<T> = T;

export interface IEngagementBase<T> {
  _id: string;
  type: EngagementType;
  user: string;
  patient: string;
  content: IEngagementContent<T>;
  note?: string;
  audit?: IAudit[];
  datetime?: string;
}

export enum ESubmissionTypes {
  patient_questionnaire_completed = "patient_questionnaire_completed",
  clinical_submission_by_email = "clinical_submission_by_email",
  clinical_submission_by_other = "clinical_submission_by_other",
  clinical_submission_by_phone = "clinical_submission_by_phone",
  clinical_submission_by_sms = "clinical_submission_by_sms",
  patient_blood_pressure_form_submission = "patient_blood_pressure_form_submission",
  patient_lifestyle_form_submission = "patient_lifestyle_form_submission",
  patient_consent_form_submission = "patient_consent_form_submission",
  patient_diabetes_form_submission = "patient_diabetes_form_submission",
}

export type SubmissionType = keyof typeof ESubmissionTypes;

export const submissionTypeOptions = (): ListOptions<SubmissionType>[] =>
  enumToKeyValuePair(ESubmissionTypes);

export interface IClinicalReadingTemplate {
  formType?: FormType;
  submission: SubmissionType;
  readings: IReading[][];
}

export type IClinicalReading = Omit<
  IEngagementBase<IClinicalReadingTemplate>,
  "type"
> & {
  type: EEngagementTypes.clinical_reading;
};

/**
 *
 * Define Types For Clinical Review Engagement
 *
 * "Hypertension routine review", "Hypertension urgent review", "Hypertension case finding", "Diabetes routine review", "Diabetes urgent review"
 *
 *
 */

enum EAssessmentTemplate {
  hypertension_routine_review = "hypertension_routine_review",
  hypertension_urgent_review = "hypertension_urgent_review",
  hypertension_case_finding = "hypertension_case_finding",
  diabetes_routine_review = "diabetes_routine_review",
  diabetes_urgent_review = "diabetes_urgent_review",
}

export type AssessmentTemplate = keyof typeof EAssessmentTemplate;

export const assessmentTemplateOptions =
  (): ListOptions<EAssessmentTemplate>[] =>
    enumToKeyValuePair(EAssessmentTemplate);

enum EAppointment {
  unplanned_appointment = "unplanned_appointment",
  planned_appointment = "planned_appointment",
}

export type Appointment = keyof typeof EAppointment | undefined; // TODO: Define

export const appointmentOptions = (): ListOptions<EAppointment>[] =>
  enumToKeyValuePair(EAppointment);

enum EPatientAttendance {
  present = "present",
  not_present = "not_present",
  patient_not_required = "patient_not_required",
  not_defined = "not_defined",
}

export type PatientAttendance = keyof typeof EPatientAttendance | boolean;

export const patientAttendanceOptions = (): ListOptions<PatientAttendance>[] =>
  enumToKeyValuePair(EPatientAttendance);

enum EPatientIdentityStatus {
  identity_verified = "identity_verified",
}

export type PatientIdentityStatus =
  | keyof typeof EPatientIdentityStatus
  | undefined;

enum EPatientRepresentativeStatus {
  representative_present = "representative_present",
}

export type PatientRepresentativeStatus =
  | keyof typeof EPatientRepresentativeStatus
  | undefined;

enum EPatientFailedEncounter {
  faile_encounter = "faile_encounter",
}

export type PatientFailedEncounter =
  | keyof typeof EPatientFailedEncounter
  | undefined;

enum EPatientVideoCall {
  video_call = "video_call",
}

export type PatientVideoCall = keyof typeof EPatientVideoCall | undefined;

enum EUrgentReviewDiabetes {
  medications_altered = "medications_altered",
  pre_history_dka = "pre_history_dka",
}

export type UrgentReviewDiabetes =
  | keyof typeof EUrgentReviewDiabetes
  | undefined;

export const urgentReviewDiabetesOptions =
  (): ListOptions<EUrgentReviewDiabetes>[] =>
    enumToKeyValuePair(EUrgentReviewDiabetes);

enum EUrgentReviewHypertension {
  bp_checked = "bp_checked",
  recent_exercise = "recent_exercise",
  excessive_caffeine_intake = "excessive_caffeine_intake",
}

export type UrgentReviewHypertension =
  | keyof typeof EUrgentReviewHypertension
  | undefined;

export const urgentReviewHypertensionOptions =
  (): ListOptions<UrgentReviewHypertension>[] =>
    enumToKeyValuePair(EUrgentReviewHypertension);

export enum EWeightAdvice {
  leave = "leave",
  continue = "continue",
}

export type WeightAdvice = keyof typeof EWeightAdvice | undefined;

export const weightAdviceOptions = (): ListOptions<WeightAdvice>[] =>
  enumToKeyValuePair(EWeightAdvice);

export enum ESmokingAdvice {
  none = "none",
  ex_smoker = "ex_smoker",
  currently_smoking = "currently_smoking",
}

export type SmokingAdvice = keyof typeof ESmokingAdvice | undefined;

export const smokingAdviceOptions = (): ListOptions<SmokingAdvice>[] =>
  enumToKeyValuePair(ESmokingAdvice);

export enum EDietAdvice {
  poor = "poor",
  good = "good",
  reduce = "reduce",
  increase = "increase",
  avoid_junk = "avoid_junk",
}

export type DietAdvice = keyof typeof EDietAdvice | undefined;

export const dietAdviceOptions = (): ListOptions<DietAdvice>[] =>
  enumToKeyValuePair(EDietAdvice);

export enum EExerciseAdvice {
  do_regular_exercise = "do_regular_exercise",
  reduce_exercise = "reduce_exercise",
  do_1_mile_walk_daily = "do_1_mile_walk_daily",
}

export type ExerciseAdvice = keyof typeof EExerciseAdvice | undefined;

export const exerciseAdviceOptions = (): ListOptions<ExerciseAdvice>[] =>
  enumToKeyValuePair(EExerciseAdvice);

export enum EDiseases {
  asthma = "asthma",
  copd = "copd",
  stroke = "stroke",
  dementia = "dementia",
  essential_hypertension = "essential_hypertension",
  resistant_hypertension = "resistant_hypertension",
  heart_failure = "heart_failure",
  atrial_filbrillation = "atrial_filbrillation",
  diabetes_type_1 = "diabetes_type_1",
  diabetes_type_2 = "diabetes_type_2",
  hyperthyroidism = "hyperthyroidism",
  hypothyroidism = "Hypothyroidism",
}

export type Diseases = keyof typeof EDiseases | undefined;

export enum EHypertensionCaseFinding {
  ongoing_investigation = "ongoing_investigation",
  not_hypertensive = "not_hypertensive",
  newly_diagnosed = "newly_diagnosed",
  existing_diagnosis_recorded = "existing_diagnosis_recorded",
}

export type HypertensionCaseFinding =
  | keyof typeof EHypertensionCaseFinding
  | undefined;

export const hypertensionCaseFindingeOptions =
  (): ListOptions<HypertensionCaseFinding>[] =>
    enumToKeyValuePair(EHypertensionCaseFinding);

export enum EMedicationChange {
  not_recorded = "not_recorded",
}

export type MedicationChange = keyof typeof EMedicationChange | undefined; // TODOL define

export const medicationChangeOptions = (): ListOptions<MedicationChange>[] =>
  enumToKeyValuePair(EMedicationChange);

export enum EMedicationReview {
  medication_compliance_checked = "medication_compliance_checked",
  allergies_confirmed = "allergies_confirmed",
  side_effects_discussed = "side_effects_discussed",
  prescription_issued = "prescription_issued",
}

export type MedicationReview = keyof typeof EMedicationReview | undefined;

export const medicationReviewOptions = (): ListOptions<MedicationReview>[] =>
  enumToKeyValuePair(EMedicationReview);

export enum EInvestigationsOrderedAction {
  ambulatory_blood_pressure = "ambulatory_blood_pressure",
  blood_test = "blood_test",
  urine_test = "urine_test",
  food_check = "food_check",
  physical_examination = "physical_examination",
  side_effects_discussed = "side_effects_discussed",
  other = "other",
}

export type InvestigationsOrderedAction =
  | keyof typeof EInvestigationsOrderedAction
  | undefined;

export const investigationsOrderedActionOptions =
  (): ListOptions<InvestigationsOrderedAction>[] =>
    enumToKeyValuePair(EInvestigationsOrderedAction);

export enum EAdviceGiven {
  advice_on_vaccinations = "advice_on_vaccinations",
  advice_on_diabetic_eye_screening = "advice_on_diabetic_eye_screening",
}

export type AdviceGiven = keyof typeof EAdviceGiven | undefined;

export const adviceGivenOptions = (): ListOptions<AdviceGiven>[] =>
  enumToKeyValuePair(EAdviceGiven);

export enum EOnwardReferralCompleted {
  gp = "gp",
  hospital = "hospital",
  ae = "ae",
}

export type OnwardReferralCompleted =
  | keyof typeof EOnwardReferralCompleted
  | undefined;

export const onwardReferralCompletedOptions =
  (): ListOptions<OnwardReferralCompleted>[] =>
    enumToKeyValuePair(EOnwardReferralCompleted);

interface CommonContent {
  tags?: {
    prescriptionIssued?: boolean;
    resistantHypertension?: boolean;
  };
  isVideoCall?: boolean;
  isFailedEncounter?: boolean;
  patientIdentityConfirmed?: boolean;
  patientAttendance?: PatientAttendance;
  isPatientRepresentativePresent?: boolean;
  appointment?: Appointment;
  occurredAtDatetime?: string;
  occurredAtTime?: string;
}

export enum EFollowUpTypes {
  submit_form = "submit_form",
  check_investiagtions_results = "check_investiagtions_results",
  book_follow_up = "book_follow_up",
}

export type FollowUpTypes = keyof typeof EFollowUpTypes | undefined;

export type FollowUp = {
  type: FollowUpTypes;
  formType?: EFormType;
  sendSMS: boolean;
  date: string;
  note: string;
  task?: ITask;
};

export type BloodPressureStats = {
  maxSystolic: string | number | null;
  maxDiastolic: string | number | null;
  avgSystolic: string | number | null;
  avgDiastolic: string | number | null;
  from: string;
  to: string;
};

export interface IClinicalAssessmentTemplate extends CommonContent {
  assessmentTemplate: AssessmentTemplate[];

  recipient?: string;
  template_type?: string;
  sender_id?: string;
  host_link?: string;
  alert?: {
    _id: string;
    value: string;
  };

  // Intro call questions
  introCallConfirmedPatientPhoneNumber?: undefined | boolean;
  introCallNotes?: undefined | string;

  // General clinical questions
  bloodPressureStatistics?: BloodPressureStats;
  pastMedicalHistory?: { text: string }[];

  // Lifestyle
  lifestyleQuestionnaire?: LifestyleQuestionnaire;
  lifestyleWeightAdvice?: WeightAdvice;
  lifestyleSmokingAdvice?: SmokingAdvice;
  lifestyleDietAdvice?: DietAdvice;
  lifestyleExerciseAdvice?: ExerciseAdvice;

  // Hypertension specific questions
  hypertensionHistory?: undefined | string;
  hypertensionPlan?: undefined | string;
  hypertensionMedicationChanges?: undefined | MedicationChange;
  hypertensionMedicationChangeDetail?: string;
  hypertensionResistant?: undefined | boolean;

  // Red flags
  redFlagQuestionnaire?: RedFlagQuestionnaire;

  // Hypertension case finding questions
  hypertensionCaseFinding?: undefined | HypertensionCaseFinding;

  // Hypertension questions
  bloodPressureHistory?: undefined | string;
  bloodPressurePlan?: undefined | string;
  bloodPressureMedicationChanges?: undefined | MedicationChange;
  bloodPressureMedicationChangeDetail?: undefined | string;

  // Urgent hypertension questions
  urgentReviewHypertension: UrgentReviewHypertension[];

  // Medication changes questions:
  medicationReview: MedicationReview[];

  // Diabetes questionnaire
  diabetesQuestionnaire?: DiabetesQuestionnaire;

  // Diabetes questions
  diabetesHistory?: undefined | string;
  diabetesPlan?: undefined | string;
  diabetesMedicationChanges?: MedicationChange;
  diabetesMedicationChangeDetail?: undefined | string;

  // Urgent diabetes questions
  urgentReviewDiabetes: UrgentReviewDiabetes[];

  // Actions:
  onwardReferralCompleted: OnwardReferralCompleted[];

  // Investigations:
  investigationsOrdered: InvestigationsOrderedAction[];

  // Advice given
  adviceGiven: AdviceGiven[];

  followUp?: FollowUp[];
}

export type IClinicalReview = Omit<
  IEngagementBase<IClinicalAssessmentTemplate>,
  "type"
> & {
  type: EEngagementTypes.clinical_review;
};

/**
 *
 * Define Types For Consultation Engagement
 *
 *
 */

export enum EConsultationType {
  admin_note = "admin_note",
  intro_call = "intro_call",
  opt_out_call = "opt_out_call",
  other_clinical_note = "other_clinical_note",
  re_engagement_call = "re_engagement_call",
}

export type ConsultationType = keyof typeof EConsultationType;

export const consultationTypeOptions = (): ListOptions<ConsultationType>[] =>
  enumToKeyValuePair(EConsultationType);

export interface IConsultationTemplate extends CommonContent {
  type: ConsultationType | undefined;
}

export type IConsultationEngagement = Omit<
  IEngagementBase<IConsultationTemplate>,
  "type"
> & {
  type: EEngagementTypes.consultation;
};

/**
 *
 * Define Types For General Engagement
 *
 *
 */

export enum EOtherEngagementTypes {
  appointment = "appointment",
  clinician_consent_service = "clinician_consent_service",
  email_technical_support_request_incoming = "email_technical_support_request_incoming",
  email_technical_support_request_outgoing = "email_technical_support_request_outgoing",
  phone_technical_support_request_incoming = "phone_technical_support_request_incoming",
  phone_technical_support_request_outgoing = "phone_technical_support_request_outgoing",
  sms_technical_support_request_incoming = "sms_technical_support_request_incoming",
  intro_message_ehr = "intro_message_ehr",
  sms_outgoing = "sms_outgoing",
  email_outgoing = "email_outgoing",
  sms_successful = "sms_successful",
  patient_alert_created = "patient_alert_created",
  patient_details_updated = "patient_details_updated",
}

export type OtherEngagementType = keyof typeof EOtherEngagementTypes;

export interface ISmsEngagementTemplate {
  type: EOtherEngagementTypes.sms_successful;
  recipient: string;
  templateType: string;
  templateVersion: number;
  senderID: string;
}

export type ISmsEngagement = Omit<
  IEngagementBase<ISmsEngagementTemplate>,
  "type"
> & {
  type: EEngagementTypes.other;
};

export interface IOtherEngagementTemplate {
  type: OtherEngagementType;
  [key: string]: unknown;
}

export type IOtherEngagement = Omit<
  IEngagementBase<IOtherEngagementTemplate>,
  "type"
> & {
  type: EEngagementTypes.other;
} | ISmsEngagement;

export type IEngagement =
  | IClinicalReading
  | IConsultationEngagement
  | IClinicalReview
  | IOtherEngagement
