import { useState } from "react";
import { Box, Button, Drawer, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Tasks from "./Tasks";

const Slidebar = () => {
  type Anchor = "right";

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const theme = useTheme();

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        p: 3,
        width: 750,
        background: theme.palette.common.white,
      }}
      role="presentation"
    >
      <IconButton
        onClick={toggleDrawer(anchor, false)}
        style={{ color: "black" }}
      >
        <NavigateNextIcon />
      </IconButton>
      <Tasks title="Tasks" />
    </Box>
  );

  return (
    <>
      {(["right"] as const).map((anchor) => (
        <span key={anchor}>
          <Button variant="contained" onClick={toggleDrawer(anchor, true)}>
            Tasks
          </Button>
          <Drawer hideBackdrop anchor={anchor} open={state[anchor]}>
            {list(anchor)}
          </Drawer>
        </span>
      ))}
    </>
  );
};

export default Slidebar;
