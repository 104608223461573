import { useReducer, useState } from "react";
import { useFormik } from "formik";
import * as z from "zod";
import MuiPhoneNumber from 'mui-phone-number';
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useRecoilState, useRecoilValue } from "recoil";
import isEqual from "lodash.isequal";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  getLoggedUser,
  selectedPatientAtom,
} from "../../../services/state.service";
import {
  activeStatusesType,
  EPatientActiveStatusType,
  inactiveStatusesType,
  IPatient,
  IPatientPhoneNumbers,
  IPatientPopulated,
  IPatientStatus,
  PatientStatusType,
} from "../../../interfaces/patient.interface";
import { IAddress, IName } from "../../../interfaces/common.interface";
import { localFormat } from "../../../services/datetime.service";
import {
  HTTP_SERVICE,
  ErrorResponse,
  isErrorResponse,
} from "../../../services/http.service";
import { ListOptions } from "../../../interfaces/utils";
// import { closeForm } from "../closeForm";

interface PatientDetailFromFields {
  name: Partial<IName>;
  dateOfBirth: string;
  gender: string;
  emailAddr: string;
  availabilityHours: string;
  postalAddress: Partial<IAddress>;
  preferredLanguage: string;
  phoneNumbers: Partial<IPatientPhoneNumbers>;
  status: PatientStatusType;
}

const getStatusObject = (
  status: PatientStatusType,
  user: string
): IPatientStatus => ({
  type: status,
  datetime: new Date().toISOString(),
  user,
});

interface PatientDetailFromProps {
  closeForm: () => void;
}

const PatientDetailFrom = ({ closeForm }: PatientDetailFromProps) => {
  const user = useRecoilValue(getLoggedUser);
  const [patient, setPatient] = useRecoilState(selectedPatientAtom);
  const [patientStatus, setPatientStatus] = useState<
    PatientStatusType | undefined
  >(patient?.status.type ?? EPatientActiveStatusType.registered);

  const patientInfo: IPatientPopulated = {
    ...patient,
  };

  const [editPatient, updatePatient] = useReducer(
    (
      {
        name,
        dateOfBirth,
        gender,
        emailAddr,
        availabilityHours,
        postalAddress,
        preferredLanguage,
        phoneNumbers,
      }: IPatientPopulated,
      {
        name: updateName,
        postalAddress: updateAddress,
        phoneNumbers: updatePhoneNumbers,
        status: updateStatus,
        ...updates
      }: Partial<PatientDetailFromFields>
    ) =>
      ({
        dateOfBirth,
        gender,
        emailAddr,
        availabilityHours,
        preferredLanguage,
        name: {
          ...name,
          ...updateName,
        },
        postalAddress: {
          ...postalAddress,
          ...updateAddress,
        },
        phoneNumbers: {
          ...phoneNumbers,
          ...updatePhoneNumbers,
        },
        status: {
          ...getStatusObject(
            updateStatus as PatientStatusType,
            user?._id as string
          ),
        },
        ...updates,
      }) as IPatientPopulated,
    patientInfo
  );

  const handleUpdateResponse = (updatedPatient: IPatientPopulated) => {
    closeForm();
    setPatient(updatedPatient);
  };

  const schema = z.object({
    name: z.object({
      nick: z.string().min(4).max(50),
    }),
    phoneNumbers: z.object({
      mobile1: z.string().min(15, { message: "Mobile Number 1 must contain valid phone number" }).regex(/^\+44/, { message: "Invalid country code" }),
      mobile2: z.string().regex(/^\+44/, { message: "Invalid country code" }).optional(),
      landline: z.string().regex(/^\+44/, { message: "Invalid country code" }).optional(),

    }),
    emailAddr: z.string().email(),
    language: z.string(),
    gender: z.string(),
    postalAddress: z.object({
      line1: z.string().min(4).max(1000),
      line2: z.string().min(4).max(1000).optional(),
      city: z.string().min(4).max(300),
      county: z.string().min(4).max(100),
      country: z.string().min(4).max(100),
      postalCode: z.string().min(4).max(50),
    }),
    status: z.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: {
        nick: editPatient.name.nick,
      },
      phoneNumbers: {
        mobile1: editPatient?.phoneNumbers?.mobile1,
        mobile2: editPatient?.phoneNumbers?.mobile2,
        landline: editPatient?.phoneNumbers?.landline,
      },
      emailAddr: editPatient.emailAddr,
      language: editPatient.preferredLanguage,
      gender: editPatient.gender,
      postalAddress: {
        line1: editPatient.postalAddress.line1,
        line2: editPatient.postalAddress.line2,
        city: editPatient.postalAddress.city,
        county: editPatient.postalAddress.county,
        country: editPatient.postalAddress.country,
        postalCode: editPatient.postalAddress.postalCode,
      },
      status: patientStatus,
    },
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: () => {
      let dataUpdates: Partial<IPatient> = {};
      [
        "dateOfBirth",
        "gender",
        "emailAddr",
        "availabilityHours",
        "preferredLanguage",
        "phoneNumbers",
        "name",
        "postalAddress",
        "status",
      ].forEach((key) => {
        if (
          isEqual(
            editPatient[key as keyof IPatientPopulated],
            patientInfo[key as keyof PatientDetailFromFields]
          )
        )
          return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updatedValue = editPatient[key as keyof IPatientPopulated] as any;
        dataUpdates[key as keyof PatientDetailFromFields] = updatedValue;
      });

      if (editPatient.status.type !== patientStatus) {
        dataUpdates = {
          ...dataUpdates,
          status: {
            ...getStatusObject(
              patientStatus as PatientStatusType,
              user?._id as string
            ),
          },
        } as Partial<IPatient>;
      }
      HTTP_SERVICE.updatePatient(
        patientInfo._id,
        dataUpdates as Partial<IPatient>
      ).then((result: IPatientPopulated | ErrorResponse): void => {
        if (!isErrorResponse(result)) {
          handleUpdateResponse(result);
        }
      });
    },
  });

  return (
    <>
      <Box
        sx={{
          height: "50px",
          width: "100%",
        }}
      >
        <Typography variant="h6">
          Edit Patient ({`${editPatient.name.first} ${editPatient.name.last}`})
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Preferred Name"
              variant="outlined"
              size="small"
              type="text"
              name="name.nick"
              value={`${editPatient.name.nick}`}
              onChange={(e) => {
                updatePatient({ name: { nick: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.name?.nick && Boolean(formik.errors.name?.nick)
              }
              helperText={formik.touched.name?.nick && formik.errors.name?.nick}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Preferred language"
              variant="outlined"
              size="small"
              name="language"
              value={`${editPatient.preferredLanguage}`}
              select
              onChange={(e) => {
                updatePatient({ preferredLanguage: e.target.value });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.language && Boolean(formik.errors.language)}
              helperText={formik.touched.language && formik.errors.language}
              fullWidth
            >
              <MenuItem key="" value="">
                No Selected
              </MenuItem>
              <MenuItem key="english" value="English">
                English
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Date of Birth"
              variant="outlined"
              size="small"
              type="date"
              name="dob"
              value={localFormat(editPatient.dateOfBirth)}
              onChange={(e) => {
                updatePatient({ dateOfBirth: e.target.value });
              }}
              onBlur={formik.handleBlur}
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Gender"
              variant="outlined"
              size="small"
              name="gender"
              value={`${editPatient.gender}`}
              select
              onChange={(e) => {
                updatePatient({ gender: e.target.value });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.gender && Boolean(formik.errors.gender)}
              helperText={formik.touched.gender && formik.errors.gender}
              fullWidth
            >
              <MenuItem key="empty" value="">
                Not Selected
              </MenuItem>
              <MenuItem key="male" value="male">
                Male
              </MenuItem>
              <MenuItem key="female" value="female">
                Female
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Status"
              variant="outlined"
              size="small"
              name="status"
              defaultValue={formik.initialValues.status}
              select
              onChange={(e) => {
                setPatientStatus(e.target.value as PatientStatusType);
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
              fullWidth
            >
              <MenuItem key="active-statuses" disabled>
                Active Status Group
              </MenuItem>
              {activeStatusesType.map(
                (status: ListOptions<PatientStatusType>) => (
                  <MenuItem key={status.key} value={status.value}>
                    {status.label}
                  </MenuItem>
                )
              )}
              <MenuItem key="inactive-statuses" disabled>
                In Active Status Group
              </MenuItem>
              {inactiveStatusesType.map(
                (status: ListOptions<PatientStatusType>) => (
                  <MenuItem key={status.key} value={status.value}>
                    {status.label}
                  </MenuItem>
                )
              )}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Contact</Typography>
          </Grid>

          <Grid item xs={6} >
            <MuiPhoneNumber
                  variant="outlined"
                  size="small"
                  name="phoneNumbers.mobile1"
                  label="Mobile Number 1"
                  defaultCountry="gb"
                  countryCodeEditable={false as boolean}
                    // it will only show uk in dropdown list 
                  onlyCountries={['gb']} 
                  inputProps={{ maxLength: 15 }}
                  value={editPatient?.phoneNumbers?.mobile1 as number}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue("phoneNumbers.mobile1", e)
                    updatePatient({
                      phoneNumbers: {
                        mobile1: e as unknown as number,
                      },
                    });
                  }} 
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phoneNumbers?.mobile1 && Boolean(formik.errors.phoneNumbers?.mobile1)
                  }
                  helperText={formik.touched.phoneNumbers?.mobile1 && formik.errors.phoneNumbers?.mobile1}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  fullWidth
              />   
           </Grid>    

          <Grid item xs={6}>
            <MuiPhoneNumber
                  variant="outlined"
                  size="small"
                  name="phoneNumbers.mobile2"
                  label="Mobile Number 2"
                  defaultCountry="gb"
                  countryCodeEditable={false as boolean}
                    // it will only show uk in dropdown list 
                  onlyCountries={['gb']} 
                  inputProps={{ maxLength: 15 }}
                  value={editPatient?.phoneNumbers?.mobile2 as number}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue("phoneNumbers.mobile2", e)
                    updatePatient({
                      phoneNumbers: {
                        mobile2: e as unknown as number,
                      },
                    });
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phoneNumbers?.mobile2 && Boolean(formik.errors.phoneNumbers?.mobile2)
                  }
                  helperText={formik.touched.phoneNumbers?.mobile2 && formik.errors.phoneNumbers?.mobile2}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  fullWidth
              />   
          </Grid>

          <Grid item xs={6}>
            <MuiPhoneNumber
                  variant="outlined"
                  size="small"
                  name="phoneNumbers.landline"
                  label="Landline Number"
                  defaultCountry="gb"
                  countryCodeEditable={false as boolean}
                    // it will only show uk in dropdown list 
                  onlyCountries={['gb']} 
                  inputProps={{ maxLength: 15 }}
                  value={editPatient?.phoneNumbers?.landline as number}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue("phoneNumbers.landline", e)
                    updatePatient({
                      phoneNumbers: {
                        landline: e as unknown as number,
                      },
                    });
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phoneNumbers?.landline && Boolean(formik.errors.phoneNumbers?.landline)
                  }
                  helperText={formik.touched.phoneNumbers?.landline && formik.errors.phoneNumbers?.landline}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  fullWidth
              />   
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Email Address"
              variant="outlined"
              size="small"
              type="email"
              name="emailAddr"
              value={`${editPatient.emailAddr}`}
              onChange={(e) => {
                updatePatient({ emailAddr: e.target.value });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.emailAddr && Boolean(formik.errors.emailAddr)
              }
              helperText={formik.touched.emailAddr && formik.errors.emailAddr}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Home Address</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Address line 1"
              variant="outlined"
              size="small"
              type="text"
              name="postalAddress.line1"
              value={editPatient.postalAddress.line1}
              onChange={(e) => {
                updatePatient({ postalAddress: { line1: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalAddress?.line1 &&
                Boolean(formik.errors.postalAddress?.line1)
              }
              helperText={
                formik.touched.postalAddress?.line1 &&
                formik.errors.postalAddress?.line1
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Address line 2"
              variant="outlined"
              size="small"
              type="text"
              name="postalAddress.line2"
              value={editPatient.postalAddress.line2}
              onChange={(e) => {
                updatePatient({ postalAddress: { line2: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalAddress?.line2 &&
                Boolean(formik.errors.postalAddress?.line2)
              }
              helperText={
                formik.touched.postalAddress?.line2 &&
                formik.errors.postalAddress?.line2
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Town/City"
              variant="outlined"
              size="small"
              type="text"
              name="postalAddress.city"
              value={editPatient.postalAddress.city}
              onChange={(e) => {
                updatePatient({ postalAddress: { city: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalAddress?.city &&
                Boolean(formik.errors.postalAddress?.city)
              }
              helperText={
                formik.touched.postalAddress?.city &&
                formik.errors.postalAddress?.city
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="County"
              variant="outlined"
              size="small"
              type="text"
              name="postalAddress.county"
              defaultValue={editPatient.postalAddress.county}
              onChange={(e) => {
                updatePatient({ postalAddress: { county: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalAddress?.county &&
                Boolean(formik.errors.postalAddress?.county)
              }
              helperText={
                formik.touched.postalAddress?.county &&
                formik.errors.postalAddress?.county
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Country"
              variant="outlined"
              size="small"
              type="text"
              name="postalAddress.country"
              value={editPatient.postalAddress.country}
              onChange={(e) => {
                updatePatient({ postalAddress: { country: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalAddress?.country &&
                Boolean(formik.errors.postalAddress?.country)
              }
              helperText={
                formik.touched.postalAddress?.country &&
                formik.errors.postalAddress?.country
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Postcode"
              variant="outlined"
              size="small"
              type="text"
              name="postalAddress.postalCode"
              value={editPatient.postalAddress.postalCode}
              onChange={(e) => {
                updatePatient({
                  postalAddress: { postalCode: e.target.value },
                });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalAddress?.postalCode &&
                Boolean(formik.errors.postalAddress?.postalCode)
              }
              helperText={
                formik.touched.postalAddress?.postalCode &&
                formik.errors.postalAddress?.postalCode
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <Button type="submit" variant="contained" size="small">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default PatientDetailFrom;
