import {
  IClinicalReadingTemplate,
} from "../../../interfaces/engangement.interface";
import {
  IReading,
  Parameter,
  EDiabetesParameter,
} from "../../../interfaces/questionnaire.interface";
import { enumKeys } from "../../../interfaces/utils";
import { Reading } from "./Reading";

type DiabetesReadingProps = {
  content: IClinicalReadingTemplate;
  bgColour: string;
};

const diabetesParameters = enumKeys(EDiabetesParameter) as Parameter[];

const sortByParameter = (a: IReading, b: IReading) =>
  (diabetesParameters.includes(a.parameter)
    ? diabetesParameters.indexOf(a.parameter)
    : 100) -
  (diabetesParameters.includes(b.parameter)
    ? diabetesParameters.indexOf(b.parameter)
    : 100);

export const DiabetesReading = ({ bgColour, content }: DiabetesReadingProps) => {
  const readings = content?.readings?.flat()?.sort(sortByParameter) ?? [];

  return <Reading bgColour={bgColour} readings={readings} />;
};
