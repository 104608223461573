import { useRecoilValue } from "recoil";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Divider
} from "@mui/material";
import { Field, FieldProps, useFormikContext } from "formik";
import { selectedTemplateFieldsAtom } from "../../../../services/state.service";
import { FieldsSubset } from "../../../../services/form-config.service";
import {
  IClinicalAssessmentTemplate,
  appointmentOptions,
  patientAttendanceOptions,
} from "../../../../interfaces/engangement.interface";
import { localFormat } from "../../../../services/datetime.service";
import { SelectField } from "../../../../shared/components";

export const CommonFields = () => {
  const templateFields = useRecoilValue<FieldsSubset>(
    selectedTemplateFieldsAtom
  );

  const { values } = useFormikContext<IClinicalAssessmentTemplate>();

  return (
    <Grid
      item
      container
      alignItems="flex-end"
      xs={12}
      rowSpacing={4}
      spacing={1}
    >
      <Grid item xs={12}>
        <Divider />
      </Grid>

      {templateFields?.appointment && (
        <Grid item xs={4}>
          <SelectField
            label="Appointment"
            name="appointment"
            selectedValue={values?.appointment ? values?.appointment : 'unplanned_appointment'}
            options={appointmentOptions()}
          />
        </Grid>
      )}

      {templateFields?.occurredAtDatetime && (
        <Field name="occurredAtDatetime">
          {(fieldProps: FieldProps<string[]>) => {
            const { handleChange, handleBlur } = fieldProps.form;
            const { error, touched } = fieldProps.meta;
            return (
              <Grid item xs={4}>
                <TextField
                  label="Date"
                  variant="outlined"
                  size="small"
                  type="date"
                  name="occurredAtDatetime"
                  defaultValue={ values.occurredAtDatetime ?? values.occurredAtDatetime  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched && Boolean(error)}
                  helperText={touched && error}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: `${localFormat(new Date().toISOString())}`,
                  }}
                  fullWidth
                />
              </Grid>
            );
          }}
        </Field>
      )}

      {templateFields?.occurredAtTime && (
        <Field name="occurredAtDatetime">
          {(fieldProps: FieldProps<string[]>) => {
            const { handleChange, handleBlur } = fieldProps.form;
            const { error, touched } = fieldProps.meta;
            return (
              <Grid item xs={4}>
                <TextField
                  label="Time"
                  variant="outlined"
                  size="small"
                  type="time"
                  name="occurredAtTime"
                  defaultValue={ values.occurredAtTime ?? values.occurredAtTime  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched && Boolean(error)}
                  helperText={touched && error}
                  InputLabelProps={{ shrink: true }}
                  // inputProps={{
                  //   min: `${localFormat(new Date().toISOString())}`,
                  // }}
                  fullWidth
                />
              </Grid>
            );
          }}
        </Field>
      )}

      {templateFields?.patientAttendance && (
        <Grid item xs={4}>
          <SelectField
            label="Patient Attendance"
            name="patientAttendance"
            selectedValue={values?.patientAttendance ? values?.patientAttendance : 'not_defined'}
            options={patientAttendanceOptions()}
          />
        </Grid>
      )}

      {templateFields?.patientIdentityConfirmed && (
        <Grid item xs={4}>
          <Field name="patientIdentityConfirmed">
            {(fieldProps: FieldProps<string[]>) => {
              const { handleChange, handleBlur } = fieldProps.form;
              // const { error, touched } = fieldProps.meta;
              return (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ values.patientIdentityConfirmed?? values.patientIdentityConfirmed }
                        name="patientIdentityConfirmed"
                        value="patientIdentityConfirmed" 
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label="Identity Confirmed"
                  />
                </FormGroup>
              );
            }}
          </Field>
        </Grid>
      )}

      {templateFields?.isPatientRepresentativePresent && (
        <Grid item xs={4}>
          <Field name="isPatientRepresentativePresent">
            {(fieldProps: FieldProps<string[]>) => {
              const { handleChange, handleBlur } = fieldProps.form;
              // const { error, touched } = fieldProps.meta;
              return (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={ values.isPatientRepresentativePresent?? values.isPatientRepresentativePresent }
                        name="isPatientRepresentativePresent"
                        value="isPatientRepresentativePresent"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label="Representative Present"
                  />
                </FormGroup>
              );
            }}
          </Field>
        </Grid>
      )}

      {templateFields?.isFailedEncounter && (
        <Grid item xs={4}>
          <Field name="isFailedEncounter">
            {(fieldProps: FieldProps<string[]>) => {
              const { handleChange, handleBlur } = fieldProps.form;
              // const { error, touched } = fieldProps.meta;
              return (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={ values.isFailedEncounter?? values.isFailedEncounter }
                        name="isFailedEncounter"
                        value="isFailedEncounter"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label="Failed Encounter"
                  />
                </FormGroup>
              );
            }}
          </Field>
        </Grid>
      )}

      {templateFields?.isVideoCall && (
        <Grid item xs={4}>
          <Field name="isVideoCall">
            {(fieldProps: FieldProps<string[]>) => {
              const { handleChange, handleBlur } = fieldProps.form;
              // const { error, touched } = fieldProps.meta;
              return (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={ values.isVideoCall?? values.isVideoCall }
                        name="isVideoCall"
                        value="isVideoCall"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label="Video Call"
                  />
                </FormGroup>
              );
            }}
          </Field>
        </Grid>
      )}
    </Grid>
  );
};
