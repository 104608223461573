import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { uniqueId } from "lodash";
import { useSetRecoilState } from "recoil";
import { FollowUp, IClinicalAssessmentTemplate, IClinicalReview } from "../../../interfaces/engangement.interface";
import { LabelMapper } from "../../../shared/constants";
import { fromDB } from "../../../services/datetime.service";
import { TimelineContentItem } from "./TimelineContentItem";
import { activeFormAtom, patientForms, selectedAssessmentAtom } from "../../../services/state.service";
import { ClinicalAssessmentAudit } from "./ClinicalAssessmentAudit";
import { IAudit } from "../../../interfaces/common.interface";

type ClinicalReviewReadingProps = {
  assessment: IClinicalReview;
  content: IClinicalAssessmentTemplate;
  bgColour: string;
};

export const ClinicalAssessment = ({
  assessment,
  bgColour,
  content,
}: ClinicalReviewReadingProps) => {

  const [revisionVisibility, setRevisionVisibility] = useState<boolean>();
  const setActiveForm = useSetRecoilState(activeFormAtom);
  const setEditAssessment = useSetRecoilState(selectedAssessmentAtom);

  const toggleRevisionVisibility = () => {
    setRevisionVisibility(!revisionVisibility);
  };

  const handleEdit = (edit: IClinicalReview) => {
    setEditAssessment(edit);
    setActiveForm(patientForms.AssessmentForm);
  };

  return (
    <Stack spacing="4px">
      <Grid container>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Appointment' value={LabelMapper[content.appointment as string]} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Date' value={content.occurredAtDatetime ? `${fromDB(content.occurredAtDatetime) }` : `${'-'}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Patient Attendance' value={LabelMapper[content.patientAttendance as string]} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Identity Confirmed' value={content.patientIdentityConfirmed ? `${"Yes"}` : `${"No"}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Representative Present' value={content.isPatientRepresentativePresent ? `${"Yes"}` : `${"No"}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Failed Encounter' value={content.isFailedEncounter ? `${"Yes"}` : `${"No"}`} />
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Video Call' value={content.isVideoCall ? `${"Yes"}` : `${"No"}`} />
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Clinical Readings </strong>
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Average' value={`${content.bloodPressureStatistics?.avgSystolic} /${content.bloodPressureStatistics?.avgDiastolic} mmHg`} />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Max Sys' value={`${content.bloodPressureStatistics?.maxSystolic}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Max Dia' value={`${content.bloodPressureStatistics?.maxDiastolic}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Date From' value={fromDB(content.bloodPressureStatistics?.from as string)} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Date To' value={fromDB(content.bloodPressureStatistics?.to as string)} />
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Lifestyle Questionnaire </strong>
          </Typography>
        </Grid>

        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='BMI' value={content.lifestyleQuestionnaire?.bmi ? `${content.lifestyleQuestionnaire?.bmi}` : '-'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Height' value={content.lifestyleQuestionnaire?.height ? `${content.lifestyleQuestionnaire?.height}` : '-'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Weight' value={content.lifestyleQuestionnaire?.weight ? `${content.lifestyleQuestionnaire?.weight}` : '-'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Confidence Level' value={content.lifestyleQuestionnaire?.confidence ? `${LabelMapper[content.lifestyleQuestionnaire?.confidence as string]}` : '-'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Goal' value={content.lifestyleQuestionnaire?.goal ? `${LabelMapper[content.lifestyleQuestionnaire?.goal as string]}` : '-'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Diet' value={content.lifestyleQuestionnaire?.diet ? `${LabelMapper[content.lifestyleQuestionnaire?.diet as string]}` : '-'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Level of physical activity observable entity' value={content.lifestyleQuestionnaire?.physicalActivity ? `${LabelMapper[content.lifestyleQuestionnaire?.physicalActivity as string]}` : '-'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Vaping Status' value={content.lifestyleQuestionnaire?.vaping ? `${LabelMapper[content.lifestyleQuestionnaire?.vaping as string]}` : '-'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Smoking Status' value={content.lifestyleQuestionnaire?.smoking ? `${LabelMapper[content.lifestyleQuestionnaire?.smoking as string]}` : '-'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Smooker Year Quit' value={content.lifestyleQuestionnaire?.smokingYearQuit ? `${content.lifestyleQuestionnaire?.smokingYearQuit}` : '-'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Alcohol units consumed per week' value={content.lifestyleQuestionnaire?.alcoholUnitsPerWeek ? `${content.lifestyleQuestionnaire?.alcoholUnitsPerWeek}` : '-'} />
          </Stack>
        </Grid>

        <Grid item xs={12} sx={{ pb: 1, pt: 1, }}>
          <Typography>
            <strong> Lifestyle Advice </strong>
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Weight' value={content.lifestyleWeightAdvice ? `${LabelMapper[content.lifestyleWeightAdvice as string]}` : '-'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Smoking' value={content.lifestyleSmokingAdvice ? `${LabelMapper[content.lifestyleSmokingAdvice as string]}` : '-'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Diet' value={content.lifestyleDietAdvice ? `${LabelMapper[content.lifestyleDietAdvice as string]}` : '-'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Exercise' value={content.lifestyleExerciseAdvice ? `${LabelMapper[content.lifestyleExerciseAdvice as string]}` : '-'} />
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Urgent Review (Diabetes) </strong>
          </Typography>
        </Grid>

        {content.urgentReviewDiabetes.map((item: unknown) => (
          <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
            <Stack direction="row" alignItems="center" gap={2}>
              <TimelineContentItem color={bgColour} label={`${LabelMapper[item as string]}`} value={item ? `${'Yes'}` : `${'-'}`} />
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Urgent Review (Hypertension) </strong>
          </Typography>
        </Grid>

        {content.urgentReviewHypertension.map((item: unknown) => (
          <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
            <Stack direction="row" alignItems="center" gap={2}>
              <TimelineContentItem color={bgColour} label={`${LabelMapper[item as string]}`} value={item ? `${'Yes'}` : `${'-'}`} />
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {/* not added */}
      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Red Flags </strong>
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Severe Headache' value={content.redFlagQuestionnaire?.severeHeadache ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Chest Pain' value={content.redFlagQuestionnaire?.chestPain ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Palpitations' value={content.redFlagQuestionnaire?.palpitations ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Shortness of Breath' value={content.redFlagQuestionnaire?.shortnessOfBreath ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Dizziness' value={content.redFlagQuestionnaire?.dizziness ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Lethargy, drowsiness or confusion' value={content.redFlagQuestionnaire?.lethargyDrowsinessOrConfusion ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Blood in Urine' value={content.redFlagQuestionnaire?.bloodInUrine ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Blurred Vision' value={content.redFlagQuestionnaire?.blurredVision ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Nausea and Vomiting' value={content.redFlagQuestionnaire?.nauseaAndVomiting ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Abdominal Pain' value={content.redFlagQuestionnaire?.abdominalPain ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Nosebleeds' value={content.redFlagQuestionnaire?.nosebleeds ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Fast, Deep and difficulty breathing' value={content.redFlagQuestionnaire?.fastDeepOrDifficultBreathing ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Very Thirsty' value={content.redFlagQuestionnaire?.veryThirsty ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Urinating a lot more than usual' value={content.redFlagQuestionnaire?.urinatingLotMoreThanUsual ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Dry Skin and Mouth' value={content.redFlagQuestionnaire?.drySkinAndMouth ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Flushed Face' value={content.redFlagQuestionnaire?.flushedFace ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Muscle Stiffness or Aches' value={content.redFlagQuestionnaire?.muscleStiffnessOrAches ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Evidence of Ketones' value={content.redFlagQuestionnaire?.evidenceOfKetones ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Capillary Blood Glucose <2.8mmol' value={content.redFlagQuestionnaire?.capillaryBloodGlucoseLess2p8 ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Requiring Assistance from Someone Else to Manage Hypos' value={content.redFlagQuestionnaire?.assistanceToManageHypos ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Persistent Glucose >15' value={content.redFlagQuestionnaire?.persistentGlucoseMore15 ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Acute Oneset Limb Redness/pain/changes in Colour/Sensation' value={content.redFlagQuestionnaire?.acuteOnsetLimbSymptoms ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Fruity-Smelling Breath' value={content.redFlagQuestionnaire?.fruitySmellingBreath ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Advised to call A&E or NHS 111 if they develop any of these red
              flags' value={content.redFlagQuestionnaire?.safetyNettingForSelectedRedFlags ? `${'True'}` : 'False'} />
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Case Finding (Hypertension) </strong>
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Outcome' value={content.hypertensionCaseFinding ? `${LabelMapper[content.hypertensionCaseFinding as string]}` : '-'} />
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Blood Pressure </strong>
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='History' value={content.bloodPressureHistory ? content.bloodPressureHistory : `${"No"}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Clinical Management Plan' value={content.bloodPressurePlan ? content.bloodPressurePlan : `${"No"}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Medication Changes' value={content.bloodPressureMedicationChanges ? content.bloodPressureMedicationChanges : `${"No"}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Medication Change Detail' value={content.bloodPressureMedicationChangeDetail ? content.bloodPressureMedicationChangeDetail : `${"No"}`} />
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Hypertension </strong>
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='History' value={content.hypertensionHistory ? content.hypertensionHistory : `${"No"}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Clinical Management Plan' value={content.hypertensionPlan ? content.hypertensionPlan : `${"No"}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Medication Changes' value={content.hypertensionMedicationChanges ? content.hypertensionMedicationChanges : `${"No"}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Medication Change Detail' value={content.hypertensionMedicationChangeDetail ? content.hypertensionMedicationChangeDetail : `${"No"}`} />
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Diabetes </strong>
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='History' value={content.diabetesHistory ? content.diabetesHistory : `${"No"}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Clinical Management Plan' value={content.diabetesPlan ? content.diabetesPlan : `${"No"}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Medication Changes' value={content.diabetesMedicationChanges ? content.diabetesMedicationChanges : `${"No"}`} />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ pb: 1 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <TimelineContentItem color={bgColour} label='Medication Change Detail' value={content.diabetesMedicationChangeDetail ? content.diabetesMedicationChangeDetail : `${"No"}`} />
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Medication Review </strong>
          </Typography>
        </Grid>

        {content.medicationReview.map((item: unknown) => (
          <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
            <Stack direction="row" alignItems="center" gap={2}>
              <TimelineContentItem color={bgColour} label={`${LabelMapper[item as string]}`} value={item ? `${'Yes'}` : `${'-'}`} />
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Actions </strong>
          </Typography>
          <Typography>
            <strong> Investigations Ordered </strong>
          </Typography>
        </Grid>

        {content.investigationsOrdered.map((item: unknown) => (
          <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
            <Stack direction="row" alignItems="center" gap={2}>
              <TimelineContentItem color={bgColour} label={`${LabelMapper[item as string]}`} value={item ? `${'Yes'}` : `${'-'}`} />
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Advice Given </strong>
          </Typography>
        </Grid>

        {content.adviceGiven.map((item: unknown) => (
          <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
            <Stack direction="row" alignItems="center" gap={2}>
              <TimelineContentItem color={bgColour} label={`${LabelMapper[item as string]}`} value={item ? `${'Yes'}` : `${'-'}`} />
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Onward Referral Completed </strong>
          </Typography>
        </Grid>

        {content.onwardReferralCompleted.map((item: unknown) => (
          <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
            <Stack direction="row" alignItems="center" gap={2}>
              <TimelineContentItem color={bgColour} label={`${LabelMapper[item as string]}`} value={item ? `${'Yes'}` : `${'-'}`} />
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Typography>
            <strong> Follow Up </strong>
          </Typography>
        </Grid>

        {content.followUp?.map((item: FollowUp) => (
          <Grid container key={uniqueId()}>
            <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
              <Stack direction="row" alignItems="center" gap={2}>
                <TimelineContentItem color={bgColour} label={`${LabelMapper[ item.type === 'submit_form'? `follow_up_${item.formType}` : `follow_up_${item.type}` ]}`} value={item ? `${'Yes'}` : `${'-'}`} />
              </Stack>
            </Grid>
            <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
              <Stack direction="row" alignItems="center" gap={2}>
                <TimelineContentItem color={bgColour} label='Date' value={item.date ? item.date : `${'-'}`} />
              </Stack>
            </Grid>
            <Grid item xs={4} sx={{ pb: 1 }} key={uniqueId()}>
              <Stack direction="row" alignItems="center" gap={2}>
                <TimelineContentItem color={bgColour} label='Notes' value={item.note ? item.note : `${'-'}`} />
              </Stack>
            </Grid>
          </Grid>
        ))}
      </Grid>

      {revisionVisibility && (
        <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>
          <ClinicalAssessmentAudit
            audit={assessment?.audit as IAudit[]}
            bgColour={bgColour}
          />
        </Grid>
      )}

      <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>
        <Box sx={{ display: "flex" }}>
          <Typography paddingRight={2}>
            <Button
              onClick={() => {
                handleEdit(assessment)
              }}
            >
              Edit Assessment
            </Button>
          </Typography>
          <Typography>
            <Button onClick={toggleRevisionVisibility}>
              {!revisionVisibility
                ? `Show Revisions (${assessment.audit?.length})`
                : `Hide Revisions (${assessment.audit?.length})`}
            </Button>
          </Typography>
        </Box>
      </Grid>
    </Stack>

  )
}
