import { Stack, Box, Divider, Typography } from "@mui/material";
import MuiTheme from "../../../shared/theme/MuiTheme";
import { readable } from "../../../interfaces/utils";

type TimelineContentItemAuditProps = {
  color: string | undefined;
  label: string;
  oldValue: string;
  newValue: string;
};

export const TimelineContentItemAudit = ({
  color = MuiTheme.palette.primary.main,
  label,
  oldValue,
  newValue,
}: TimelineContentItemAuditProps) => (
  <>
    <Box>
      <Divider
        orientation="vertical"
        style={{
          minHeight: "5px",
          width: "5px",
          background: `${color}`,
          borderRadius: "1px",
          marginBottom: "2px",
        }}
      />
      <Divider
        orientation="vertical"
        style={{
          minHeight: "30px",
          width: "5px",
          background: `${color}`,
          borderRadius: "2px",
          marginBottom: "2px",
        }}
      />
      <Divider
        orientation="vertical"
        style={{
          minHeight: "5px",
          width: "5px",
          background: `${color}`,
          borderRadius: "1px",
        }}
      />
    </Box>
    <Stack spacing="6px">
      <Typography>
        <strong>{readable(label)}</strong>
      </Typography>
      <Box sx={{ display: "flex" }}>
        <Typography
          sx={{ textDecoration: "line-through" }}
          fontSize={16}
          paddingRight={1}
        >
          {readable(oldValue)}
        </Typography>
        <Typography fontSize={16}> {readable(newValue)}</Typography>{" "}
      </Box>
    </Stack>
  </>
);
