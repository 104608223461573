import { useRecoilValue } from "recoil";
import { Button, Grid, Typography, Divider } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { selectedTemplateFieldsAtom } from "../../../../services/state.service";
import { FieldsSubset } from "../../../../services/form-config.service";
import { IClinicalReadingTemplate } from "../../../../interfaces/engangement.interface";
import { DiabetesReading } from "../../Shared/DiabetesReading";

type DiabetesQuestionnaireProps = {
  lastDiabetesSubmission: IClinicalReadingTemplate;
};

export const DiabetesQuestionnaire = ({
  lastDiabetesSubmission,
}: DiabetesQuestionnaireProps) => {
  const templateFields = useRecoilValue<FieldsSubset>(
    selectedTemplateFieldsAtom
  );
  return (
    <Grid item container alignItems="flex-end" xs={12} rowSpacing={4}>
      {templateFields?.diabetesQuestionnaire && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}

      {templateFields?.diabetesQuestionnaire && (
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h6">Diabetes Questionnaire</Typography>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <Button
                size="small"
                sx={{ textTransform: "none" }}
                endIcon={<OpenInNewIcon />}
              >
                Update Diabetes Questionnaire
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {templateFields?.diabetesQuestionnaire && (
        <Grid container>
          <DiabetesReading bgColour="#2196F3" content={lastDiabetesSubmission} />
        </Grid>
      )}
    </Grid>
  );
};
