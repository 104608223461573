import { useRecoilValue } from "recoil";
import * as z from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  ErrorMessage,
  getIn,
  ArrayHelpers,
} from "formik";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  MenuItem,
  Divider,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// import { uniqueId } from "lodash";
import {
  getLoggedUser,
  selectedPatientId,
} from "../../../services/state.service";
import {
  ESubmissionTypes,
  IEngagement,
  IClinicalReadingTemplate,
  IClinicalReading,
  EEngagementTypes,
} from "../../../interfaces/engangement.interface";
import {
  IReading,
  EBloodPressureParameter,
  BloodPressureParameter,
  EFormType,
} from "../../../interfaces/questionnaire.interface";
import { OmitID, Replace } from "../../../interfaces/utils";
import {
  HTTP_SERVICE,
  ErrorResponse,
  isErrorResponse,
} from "../../../services/http.service";
import { combineDateTime, DateTime } from "../../../services/datetime.service";

type BloodPressureReading = {
  systolic: string;
  diastolic: string;
  pulse: string;
  date: string;
  time: string;
};

type FormSubmission = "sms" | "email" | "phone";

const formSubmissionTypeToClinicianSubmissionTypes: {
  [key in FormSubmission]: ESubmissionTypes;
} = {
  sms: ESubmissionTypes.clinical_submission_by_sms,
  email: ESubmissionTypes.clinical_submission_by_email,
  phone: ESubmissionTypes.clinical_submission_by_phone,
};

type IBloodPressureReadingForm = Replace<
  Replace<IClinicalReadingTemplate, "readings", BloodPressureReading[]>,
  "submission",
  FormSubmission
> & {
  note: string;
  research: string;
};

const bloodPressureReading = () => ({
  systolic: "",
  diastolic: "",
  pulse: "",
  date: "",
  time: "",
});

const convertToDBFormat = (
  param: BloodPressureParameter,
  value: string,
  datetime: string
): OmitID<IReading> => ({
  parameter: param,
  value: String(value),
  datetime,
});

const intialValues: IBloodPressureReadingForm = {
  formType: EFormType.blood_pressure_reading_form,
  submission: "sms",
  readings: [bloodPressureReading()],
  note: "",
  research: "",
};

interface PatientDetailFromProps {
  closeForm: () => void;
}

const validationSchema = z.object({ 
  submission: z.string(),
  readings: z.array(
    z.object({
      systolic: z.number().gte(1).lte(999,{ message: "Systolic must be 3 digits" }),
      diastolic: z.number().gte(1).lte(999,{ message: "Diastolic must be 3 digits" }),
      pulse: z.number().gte(1).lte(999,{ message: "Pulse must be 3 digits" }),
      date: z.string(),
      time: z.string(),
    })
  ).optional(),
  note: z.string().min(10),
  research: z.boolean().optional(),
});

export const BloodPressureReadingFrom = ({
  closeForm,
}: PatientDetailFromProps) => {
  const user = useRecoilValue(getLoggedUser);
  const patientId = useRecoilValue(selectedPatientId);

  return (
    <>
      <Box
        sx={{
          height: "50px",
          width: "100%",
        }}
      >
        <Typography variant="h6">Add Blood Pressure Readings</Typography>
      </Box>
      <Formik
        initialValues={intialValues}
        validationSchema={toFormikValidationSchema(validationSchema)}
        onSubmit={(values: IBloodPressureReadingForm) => {
          const { readings, note, submission } = values;
          const newClinialEngagement: OmitID<IClinicalReading> = {
            type: EEngagementTypes.clinical_reading,
            user: user?._id as string,
            patient: patientId as string,
            content: {
              formType: EFormType.blood_pressure_reading_form,
              submission:
                formSubmissionTypeToClinicianSubmissionTypes[submission],
              readings: readings.map(
                ({ systolic, diastolic, pulse, date, time }) => [
                  convertToDBFormat(
                    EBloodPressureParameter.blood_pressure_systole,
                    systolic,
                    combineDateTime(date, time) as string
                  ),
                  convertToDBFormat(
                    EBloodPressureParameter.blood_pressure_diastole,
                    diastolic,
                    combineDateTime(date, time) as string
                  ),
                  ...(pulse
                    ? [
                        convertToDBFormat(
                          EBloodPressureParameter.pulse_rate,
                          pulse,
                          combineDateTime(date, time) as string
                        ),
                      ]
                    : []),
                ]
              ),
            },
            note,
            datetime: DateTime.now().toString(),
          };

          HTTP_SERVICE.createEngagement(
            newClinialEngagement as Partial<IEngagement>
          ).then((result: IEngagement | ErrorResponse): void => {
            if (!isErrorResponse(result)) {
              closeForm();
            }
          });
        }}
      >
        {({ values, errors, touched }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  as={TextField}
                  label="Submission"
                  variant="outlined"
                  size="small"
                  name="submission"
                  select
                  error={touched.submission && Boolean(errors.submission)}
                  helperText={touched.submission && errors.submission}
                  fullWidth
                >
                  <MenuItem value="">No Selected</MenuItem>
                  <MenuItem key="sms-submission-type" value="sms">
                    SMS
                  </MenuItem>
                  <MenuItem key="email-submission-type" value="email">
                    Email
                  </MenuItem>
                  <MenuItem key="phone-submission-type" value="phone">
                    Phone
                  </MenuItem>
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Typography> </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ mt: 1 }} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Blood Pressure Readings </Typography>
                <Typography variant="body2">
                  At least one reading is required for submission{" "}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FieldArray
                  name="readings"
                  render={(arrayHelpers: ArrayHelpers) => (
                    <Box>
                      {values.readings && values.readings.length > 0 ? (
                        values.readings.map((reading, index) => (
                          <Grid container spacing={2} key={`fieldArray-${index+1}`} >
                            <Grid item xs={3} sx={{ mb: 1 }}>
                              <Field
                                as={TextField}
                                label="Systolic 1 (mmHg)*"
                                variant="outlined"
                                size="small"
                                type="number"
                                name={`readings[${index}].systolic`}
                                error={
                                  getIn(
                                    errors,
                                    `readings[${index}].systolic`
                                  ) &&
                                  getIn(touched, `readings[${index}].systolic`)
                                }
                                helperText={
                                  <ErrorMessage
                                    name={`readings[${index}].systolic`}
                                  />
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={3} sx={{ mb: 1 }}>
                              <Field
                                as={TextField}
                                label="Diastolic 1 (mmHg)*"
                                variant="outlined"
                                size="small"
                                type="number"
                                name={`readings.${index}.diastolic`}
                                error={
                                  getIn(
                                    errors,
                                    `readings.${index}.diastolic`
                                  ) &&
                                  getIn(touched, `readings.${index}.diastolic`)
                                }
                                helperText={
                                  <ErrorMessage
                                    name={`readings.${index}.diastolic`}
                                  />
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={3} sx={{ mb: 1 }}>
                              <Field
                                as={TextField}
                                label="Pulse 1 (bpm)"
                                variant="outlined"
                                size="small"
                                type="number"
                                name={`readings.${index}.pulse`}
                                error={
                                  getIn(errors, `readings.${index}.pulse`) &&
                                  getIn(touched, `readings.${index}.pulse`)
                                }
                                helperText={
                                  <ErrorMessage
                                    name={`readings.${index}.pulse`}
                                  />
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={5} sx={{ mb: 3 }}>
                              <Field
                                as={TextField}
                                label="Date*"
                                variant="outlined"
                                size="small"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                name={`readings.${index}.date`}
                                error={
                                  getIn(errors, `readings.${index}.date`) &&
                                  getIn(touched, `readings.${index}.date`)
                                }
                                helperText={
                                  <ErrorMessage
                                    name={`readings.${index}.date`}
                                  />
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={5} sx={{ mb: 3 }}>
                              <Field
                                as={TextField}
                                label="Time*"
                                variant="outlined"
                                size="small"
                                type="time"
                                InputLabelProps={{ shrink: true }}
                                name={`readings.${index}.time`}
                                error={
                                  getIn(errors, `readings.${index}.time`) &&
                                  getIn(touched, `readings.${index}.time`)
                                }
                                helperText={
                                  <ErrorMessage
                                    name={`readings.${index}.time`}
                                  />
                                }
                                fullWidth
                              />
                            </Grid>

                            {values.readings.length > 1 ? (
                              <Grid item xs={2}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "stretch",
                                  }}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </Grid>
                            ) : (
                              <Typography> </Typography>
                            )}
                          </Grid>
                        ))
                      ) : (
                        <Typography> </Typography>
                      )}
                      <div>
                        <Button
                          type="button"
                          variant="contained"
                          size="small"
                          onClick={() =>
                            arrayHelpers.push({
                              systolic: "",
                              diastolic: "",
                              pulse: "",
                              date: "",
                              time: "",
                            })
                          }
                        >
                          Add Reading
                        </Button>
                      </div>
                    </Box>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ mt: 1 }} />
              </Grid>

              <Grid item xs={12} sx={{ mt: 1 }}>
                <Field
                  as={TextField}
                  label="Additional Notes"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="note"
                  multiline
                  rows={4}
                  error={touched.note && Boolean(errors.note)}
                  helperText={touched.note && errors.note}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  type="checkbox"
                  name="research"
                  as={FormControlLabel}
                  control={<Checkbox />}
                  label="Did the patient consent to participate in user research?"
                />
              </Grid>

              <Grid item xs={6}>
                <Button type="submit" variant="contained" size="small">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
