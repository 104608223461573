import { useRecoilValue } from "recoil";
import { Divider, Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { FieldsSubset } from "../../../../services/form-config.service";
import { selectedTemplateFieldsAtom } from "../../../../services/state.service";
import { CheckBoxField } from "../../../../shared/components";
import { IClinicalAssessmentTemplate, urgentReviewDiabetesOptions } from "../../../../interfaces/engangement.interface";

export const UrgentDiabetes = () => {
    const templateFields = useRecoilValue<FieldsSubset>(
        selectedTemplateFieldsAtom
    );
    const { values } = useFormikContext<IClinicalAssessmentTemplate>();
    return (
        <Grid item container alignItems="flex-end" xs={12} rowSpacing={4}>

            {templateFields?.urgentReviewDiabetes && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}

            {templateFields?.urgentReviewDiabetes && (
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Urgent Review (Diabetes)
                    </Typography>
                </Grid>
            )}

            {templateFields?.urgentReviewDiabetes && (
                <Grid item xs={12}>
                    <CheckBoxField
                        label=""
                        name="urgentReviewDiabetes"
                        position="horizontal"
                        selectedValue={values.urgentReviewDiabetes.map((i: unknown) => (i))}
                        options={urgentReviewDiabetesOptions()}
                    />
                </Grid>
            )}
        </Grid>
    );

}
