import { Typography, CircularProgress, Box } from "@mui/material";

const Spiner = () => (
  <Box style={{ height: "100vh" }}>
    <Box sx={{ textAlign: "center", marginTop: "40vh" }}>
      <Typography>Loading...</Typography>
      <br />
      <CircularProgress />
    </Box>
  </Box>
);

export default Spiner;
