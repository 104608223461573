import { ReactElement } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import MuiTheme from "../../theme/MuiTheme";


type CardListItemProps = {
  icon?: ReactElement  
  color?: string | undefined;
  label: string;
  value: string;
};

const CardListItem = ({
  icon = <SquareRoundedIcon fontSize="small"/>,
  color = MuiTheme.palette.primary.main,
  label,
  value,
}: CardListItemProps) => (
  <Card
    sx={{
      marginBottom: "3px",
      borderBottom: "0px",
      borderLeft: `1px solid ${color}`,
    }}
  >
    <Box sx={{ display: "flex", justifyContent: "space-between", pb: 1 }}>
      <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 1 }}>
        <Box fontSize="small" sx={{ ml: 1, color: `${color}` }}>
            {icon}
        </Box>
        <Typography fontSize={16} sx={{ color: `${color}` }}>
          {label}
        </Typography>
        <Typography fontSize={16}>{value}</Typography>
      </Stack>
    </Box>
  </Card>
);

export default CardListItem;
