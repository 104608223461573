import { useRecoilValue } from "recoil";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { Field, FieldProps, useFormikContext } from "formik";
import { FieldsSubset } from "../../../../services/form-config.service";
import { selectedTemplateFieldsAtom } from "../../../../services/state.service";
import { InputField, SelectField } from "../../../../shared/components";
import { IClinicalAssessmentTemplate, medicationChangeOptions } from "../../../../interfaces/engangement.interface";

export const DiabetesCaseFinding = () => {
    const templateFields = useRecoilValue<FieldsSubset>(
        selectedTemplateFieldsAtom
    );

    const { values } = useFormikContext<IClinicalAssessmentTemplate>();

    return (
        <Grid item container alignItems="flex-end" xs={12} rowSpacing={4} spacing={1}>

            {templateFields?.diabetesQuestionnaire && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}

            {templateFields?.diabetesQuestionnaire && (
                <Grid item xs={12}>
                    <Typography variant="h6">Diabetes</Typography>
                </Grid>
            )}

            {templateFields?.diabetesHistory && (
                <Field name="diabetesHistory" >
                    {(fieldProps: FieldProps<string[]>) => {
                        const { handleChange, handleBlur } = fieldProps.form;
                        const { error, touched } = fieldProps.meta;
                        return (
                            <Grid item xs={12}>
                                <TextField
                                    label="History"
                                    multiline
                                    rows={3}
                                    name="diabetesHistory"
                                    value={values.diabetesHistory}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched && Boolean(error)}
                                    helperText={touched && error}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }}
                </Field>
            )}

            {templateFields?.diabetesPlan && (
                <Field name="diabetesPlan" >
                    {(fieldProps: FieldProps<string[]>) => {
                        const { handleChange, handleBlur } = fieldProps.form;
                        const { error, touched } = fieldProps.meta;
                        return (
                            <Grid item xs={12}>
                                <TextField
                                    label="Clinical Management Plan"
                                    multiline
                                    rows={3}
                                    name="diabetesPlan"
                                    value={values.diabetesPlan}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched && Boolean(error)}
                                    helperText={touched && error}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }}
                </Field>
            )}

            {templateFields?.diabetesMedicationChanges && (
                <Grid item xs={12}>
                    <SelectField
                        label="Medication Changes"
                        name="diabetesMedicationChanges"
                        selectedValue={values?.diabetesMedicationChanges ? values?.diabetesMedicationChanges : 'not_recorded'}
                        options={medicationChangeOptions()}
                    />
                </Grid>
            )}

            {templateFields?.diabetesMedicationChangeDetail && (
                <Grid item xs={12}>
                    <InputField
                        label="Medication Change Details"
                        name="diabetesMedicationChangeDetail"
                        defaultValue={values?.diabetesMedicationChangeDetail}
                    />
                </Grid>
            )}

        </Grid>
    );
}