import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import VapingRoomsIcon from "@mui/icons-material/VapingRooms";
import WineBarIcon from "@mui/icons-material/WineBar";
import SpeedIcon from "@mui/icons-material/Speed";
import { Box, Button, Grid } from "@mui/material";
import {
  activeFormAtom,
  patientForms,
} from "../../../../services/state.service";
import {
  IClinicalReadingTemplate,
  IEngagement,
} from "../../../../interfaces/engangement.interface";
import { LifestyleQuestionnaire } from "../../../../interfaces/questionnaire.interface";
import {
  getLastLifeStyleSubmission,
  readingsToQuestionnaire,
} from "../../utils";
import { readable } from "../../../../interfaces/utils";
import { CardListItem } from "../../../../shared/components";
import MuiTheme from "../../../../shared/theme/MuiTheme";

type LifestyleCardProps = {
  engagements: IEngagement[];
};

const LifestyleCard = ({ engagements }: LifestyleCardProps) => {
  const [lifeStyleReadings, setLifeStyleReadings] =
    useState<LifestyleQuestionnaire>();

  const getLastLifeStyleReadings = () => {
    setLifeStyleReadings(
      readingsToQuestionnaire<IClinicalReadingTemplate, LifestyleQuestionnaire>(
        getLastLifeStyleSubmission(engagements)
      )
    );
  };

  useEffect(() => {
    if (engagements.length) getLastLifeStyleReadings();
  }, [engagements]);

  const setActiveForm = useSetRecoilState(activeFormAtom);
  const handleAdd = () => setActiveForm(patientForms.LifestyleReadingForm);

  return (
    <Card sx={{ p: 2, mt: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handleAdd}>Add Lifestyle Reading</Button>
      </Box>
      <Grid container spacing={1}>
        <Grid item lg={6} md={12} sm={12}>
          <Box>
            <Card sx={{ p: 2 }} style={{ backgroundColor: "#faedcd" }}>
              <Box sx={{ display: "flex" }}>
                <Box style={{ marginTop: "-1px" }}>
                  <SpeedIcon color="primary" fontSize="large" />
                </Box>
                <Typography variant="h5" color="#d4a373" sx={{ mr: 8 }}>
                  BMI
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", pl: 0 }}>
                <Typography variant="h6" sx={{ mt: 0, mb: 0 }}>
                  {readable(lifeStyleReadings?.bmi as string) ?? "-"}
                </Typography>
              </Box>
            </Card>
          </Box>
        </Grid>
        <Grid item lg={6} md={12} sm={12}>
          <Grid container>
            <Grid item md={12} xs={12} sx={{ marginBottom: 1 }}>
              <Card sx={{ px: 1 }} style={{ backgroundColor: "#faedcd" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h5" color="#d4a373" sx={{ my: 1 }}>
                    Height
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 1 }}>
                    {readable(lifeStyleReadings?.height as string) ?? "-"} cm
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item md={12} xs={12}>
              <Card sx={{ px: 1 }} style={{ backgroundColor: "#faedcd" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h5" color="#d4a373" sx={{ my: 1 }}>
                    Weight
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 1 }}>
                    {readable(lifeStyleReadings?.weight as string) ?? "-"} kg
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        <Grid container>
          <Grid item md={12} xs={12}>
            <CardListItem
              color={MuiTheme.palette.primary.main}
              icon={<SentimentDissatisfiedIcon fontSize="small" />}
              label="Confidence"
              value={readable(lifeStyleReadings?.confidence as string) ?? "-"}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              color={MuiTheme.palette.primary.main}
              icon={<EmojiEventsIcon fontSize="small" />}
              label="Goal"
              value={readable(lifeStyleReadings?.goal as string) ?? "-"}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              color={MuiTheme.palette.primary.main}
              icon={<RestaurantMenuIcon fontSize="small" />}
              label="Diet"
              value={readable(lifeStyleReadings?.diet as string) ?? "-"}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              color={MuiTheme.palette.primary.main}
              icon={<DirectionsWalkIcon fontSize="small" />}
              label="Activity"
              value={
                readable(lifeStyleReadings?.physicalActivity as string) ?? "-"
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              color={MuiTheme.palette.primary.main}
              icon={<VapingRoomsIcon fontSize="small" />}
              label="Vaping"
              value={readable(lifeStyleReadings?.vaping as string) ?? "-"}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              color={MuiTheme.palette.primary.main}
              icon={<VapingRoomsIcon fontSize="small" />}
              label="Smoking"
              value={readable(lifeStyleReadings?.smoking as string) ?? "-"}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CardListItem
              color={MuiTheme.palette.primary.main}
              icon={<WineBarIcon fontSize="small" />}
              label="Alcohol units"
              value={
                readable(lifeStyleReadings?.alcoholUnitsPerWeek as string) ??
                "-"
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default LifestyleCard;
