import { z } from "zod";
import { useRecoilValue } from "recoil";
import { DateTime } from "luxon";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Formik, Form, getIn, ErrorMessage } from "formik";
import {} from "react-router-dom";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { OmitID, Replace } from "../../../interfaces/utils";
import {
  EEngagementTypes,
  ESubmissionTypes,
  IClinicalReading,
  IClinicalReadingTemplate,
  IEngagement,
} from "../../../interfaces/engangement.interface";
import {
  IReading,
  DiabetesParameter,
  EDiabetesParameter,
  EFormType,
  DiabetesQuestionnaire,
} from "../../../interfaces/questionnaire.interface";

import {
  getLoggedUser,
  selectedPatientId,
} from "../../../services/state.service";
import {
  ErrorResponse,
  HTTP_SERVICE,
  isErrorResponse,
} from "../../../services/http.service";

type DiabetesReading = DiabetesQuestionnaire;

const diabetesReading = (): DiabetesReading => ({
  doesHomeGlucose: "yes",
  glucoseBelow4: "yes",
  glucoseBelow2p8: "yes",
  experiencesHypos: "yes",
  symptomsDuringHypos: "yes",
  timesHelpDuringHypos: "",
  dateLastHypo: "",
  bloodSugarToTreat: "",
  numTimesSugarLess4: "",
  eatDrinkToTreat: "",
  checkSugarBeforeDriving: "yes",
  fastingAtTimeOfHypo: "yes",
});

type FormSubmission = "sms" | "email" | "phone";

const formSubmissionTypeToClinicianSubmissionTypes: {
  [key in FormSubmission]: ESubmissionTypes;
} = {
  sms: ESubmissionTypes.clinical_submission_by_sms,
  email: ESubmissionTypes.clinical_submission_by_email,
  phone: ESubmissionTypes.clinical_submission_by_phone,
};

type IDiabetesReadingForm = Replace<
  Replace<IClinicalReadingTemplate, "readings", DiabetesReading>,
  "submission",
  FormSubmission
>;

const convertToDBFormat = (
  param: DiabetesParameter,
  value: string,
  datetime?: string | undefined
): OmitID<IReading[]> => [
  {
    parameter: param,
    value: String(value),
    datetime: datetime ?? "",
  },
];

const initialValues: IDiabetesReadingForm = {
  formType: EFormType.diabetes_questionnaire_form,
  submission: "sms",
  readings: diabetesReading(),
};

interface DiabetesReadingFormProps {
  closeForm: () => void;
}

const schema = z.object({
  submission: z.string(),
  readings: z.object({
    doesHomeGlucose: z.string(),
    glucoseBelow4: z.string(),
    experiencesHypos: z.string(),
    symptomsDuringHypos: z.string(),
    dateLastHypo: z.string(),
    numTimesSugarLess4: z.number().gte(1).lte(99,{ message: "Must contain at most 2 digit(s)" }),
    checkSugarBeforeDriving: z.string(),
    glucoseBelow2p8: z.string(),
    timesHelpDuringHypos: z.number().gte(1).lte(999,{ message: "Must contain at most 3 digit(s)" }),
    bloodSugarToTreat: z.number().gte(1).lte(999,{ message: "Must contain at most 3 digit(s)" }),
    eatDrinkToTreat: z.string().max(1500),
    fastingAtTimeOfHypo: z.string(),
  }),
});

export const DiabetesReadingForm = ({
  closeForm,
}: DiabetesReadingFormProps) => {
  const user = useRecoilValue(getLoggedUser);
  const patientId = useRecoilValue(selectedPatientId);
  return (
    <>
      <Box
        sx={{
          height: "50px",
          width: "100%",
        }}
      >
        <Typography variant="h6">Add Diabetes Readings</Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={toFormikValidationSchema(schema)}
        onSubmit={(values: IDiabetesReadingForm) => {
          const { readings, submission } = values;
          const newClinicalEngagement: OmitID<IClinicalReading> = {
            type: EEngagementTypes.clinical_reading,
            user: user?._id as string,
            // user: "650aafcf41c00ef96a9cec96",
            patient: patientId as string,
            content: {
              formType: EFormType.diabetes_questionnaire_form,
              submission:
                formSubmissionTypeToClinicianSubmissionTypes[submission],
              readings: [
                convertToDBFormat(
                  EDiabetesParameter.blood_sugar_to_treat,
                  readings.bloodSugarToTreat as string
                ),
                convertToDBFormat(
                  EDiabetesParameter.check_sugar_before_driving,
                  readings.checkSugarBeforeDriving as string
                ),
                convertToDBFormat(
                  EDiabetesParameter.date_last_hypo,
                  readings.dateLastHypo as string
                ),
                convertToDBFormat(
                  EDiabetesParameter.does_home_glucose,
                  readings.doesHomeGlucose as string
                ),
                convertToDBFormat(
                  EDiabetesParameter.eat_drink_to_treat,
                  readings.eatDrinkToTreat as string
                ),
                convertToDBFormat(
                  EDiabetesParameter.experiences_hypos,
                  readings.experiencesHypos as string
                ),
                convertToDBFormat(
                  EDiabetesParameter.fasting_at_time_of_hypo,
                  readings.fastingAtTimeOfHypo as string
                ),
                convertToDBFormat(
                  EDiabetesParameter.glucose_below_2p8,
                  readings.glucoseBelow2p8 as string
                ),
                convertToDBFormat(
                  EDiabetesParameter.glucose_below_4,
                  readings.glucoseBelow4 as string
                ),
                convertToDBFormat(
                  EDiabetesParameter.num_times_sugar_less_4,
                  readings.numTimesSugarLess4 as string
                ),
                convertToDBFormat(
                  EDiabetesParameter.symptoms_during_hypos,
                  readings.symptomsDuringHypos as string
                ),
                convertToDBFormat(
                  EDiabetesParameter.times_help_during_hypos,
                  readings.timesHelpDuringHypos as string
                ),
              ],
            },
            note: "",
            datetime: DateTime.now().toString(),
          };

          HTTP_SERVICE.createEngagement(
            newClinicalEngagement as Partial<IEngagement>
          ).then((result: IEngagement | ErrorResponse): void => {
            if (!isErrorResponse(result)) {
              closeForm();
            }
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  as={TextField}
                  label="Submission"
                  variant="outlined"
                  size="small"
                  name="submission"
                  select
                  error={touched.submission && Boolean(errors.submission)}
                  helperText={touched.submission && errors.submission}
                  fullWidth
                >
                  <MenuItem value="">No Selected</MenuItem>
                  <MenuItem key="sms-submission-type" value="sms">
                    SMS
                  </MenuItem>
                  <MenuItem key="email-submission-type" value="email">
                    Email
                  </MenuItem>
                  <MenuItem key="phone-submission-type" value="phone">
                    Phone
                  </MenuItem>
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Typography> </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <Grid item xs={12}>
                    {/* <Typography variant="h6">What is their confidence level?</Typography> */}
                    <FormLabel>Patient does home glucose monitoring?</FormLabel>
                  </Grid>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="readings.doesHomeGlucose"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.readings.doesHomeGlucose}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl>
                  <Grid item xs={12}>
                    {/* <Typography variant="h6">What is their confidence level?</Typography> */}
                    <FormLabel>Any reading below 4mmol/l?</FormLabel>
                  </Grid>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="readings.glucoseBelow4"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.readings.glucoseBelow4}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl>
                  <Grid item xs={12}>
                    {/* <Typography variant="h6">What is their confidence level?</Typography> */}
                    <FormLabel>Any reading below 2.8mmol/l?</FormLabel>
                  </Grid>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="readings.glucoseBelow2p8"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.readings.glucoseBelow2p8}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <Grid item xs={12}>
                    {/* <Typography variant="h6">What is their confidence level?</Typography> */}
                    <FormLabel>Patient has experienced hypo?</FormLabel>
                  </Grid>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="readings.experiencesHypos"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.readings.experiencesHypos}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Grid item xs={12}>
                  <FormLabel>Symptoms experienced during a hypo</FormLabel>
                </Grid>
                <Field
                  as={TextField}
                  size="small"
                  name="readings.symptomsDuringHypos"
                  error={
                    getIn(errors, "readings.symptomsDuringHypos") &&
                    getIn(touched, "readings.symptomsDuringHypos")
                  }
                  helperText={
                    <ErrorMessage name="readings.symptomsDuringHypos" />
                  }
                  fullWidth
                  select
                >
                  <MenuItem key="yes" value="yes">
                    Yes
                  </MenuItem>
                  <MenuItem key="no" value="no">
                    No
                  </MenuItem>
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Grid item xs={12}>
                  <FormLabel>
                    How many times have they needed help during hypo?
                  </FormLabel>
                </Grid>
                <TextField
                  variant="outlined"
                  type="number"
                  size="small"
                  name="readings.timesHelpDuringHypos"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    getIn(errors, "readings.timesHelpDuringHypos") &&
                    getIn(touched, "readings.timesHelpDuringHypos")
                  }
                  helperText={
                    <ErrorMessage name="readings.timesHelpDuringHypos" />
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <Grid item xs={12}>
                  <FormLabel>When was their hypo?</FormLabel>
                </Grid>
                <TextField
                  variant="outlined"
                  type="date"
                  size="small"
                  name="readings.dateLastHypo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    getIn(errors, "readings.dateLastHypo") &&
                    getIn(touched, "readings.dateLastHypo")
                  }
                  helperText={<ErrorMessage name="readings.dateLastHypo" />}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <Grid item xs={12}>
                  <FormLabel>
                    At what glucose level do they treat hypo?
                  </FormLabel>
                </Grid>
                <TextField
                  variant="outlined"
                  type="number"
                  size="small"
                  name="readings.bloodSugarToTreat"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    getIn(errors, "readings.bloodSugarToTreat") &&
                    getIn(touched, "readings.bloodSugarToTreat")
                  }
                  helperText={
                    <ErrorMessage name="readings.bloodSugarToTreat" />
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <Grid item xs={12}>
                  <FormLabel>{`Number of times a week blood sugar is <4?`}</FormLabel>
                </Grid>
                <TextField
                  variant="outlined"
                  type="number"
                  size="small"
                  name="readings.numTimesSugarLess4"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    getIn(errors, "readings.numTimesSugarLess4") &&
                    getIn(touched, "readings.numTimesSugarLess4")
                  }
                  helperText={
                    <ErrorMessage name="readings.numTimesSugarLess4" />
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <Grid item xs={12}>
                  <FormLabel>
                    What do they eat or drink to treat hypo?
                  </FormLabel>
                </Grid>
                <TextField
                  variant="outlined"
                  type="text"
                  size="small"
                  name="readings.eatDrinkToTreat"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    getIn(errors, "readings.eatDrinkToTreat") &&
                    getIn(touched, "readings.eatDrinkToTreat")
                  }
                  helperText={<ErrorMessage name="readings.eatDrinkToTreat" />}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl>
                  <Grid item xs={12}>
                    <FormLabel>
                      Do you check your blood sugar before driving?
                    </FormLabel>
                  </Grid>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="readings.checkSugarBeforeDriving"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.readings.checkSugarBeforeDriving}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl>
                  <Grid item xs={12}>
                    <FormLabel>Was patient fasting at time of hypo?</FormLabel>
                  </Grid>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="readings.fastingAtTimeOfHypo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.readings.fastingAtTimeOfHypo}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Button type="submit" variant="contained" size="small">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
