import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Formik, Form } from "formik";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  DateTime,
  combineDateTime,
  extractTime,
  fromDB,
} from "../../../../services/datetime.service";
import {
  engagementListAtom,
  getLoggedUser,
  selectedAssessmentAtom,
  selectedPatientId,
  selectedTemplateFieldsAtom,
} from "../../../../services/state.service";
import {
  AssessmentTemplate,
  assessmentTemplateOptions,
  IEngagement,
  EEngagementTypes,
  BloodPressureStats,
  IClinicalReview,
  EFollowUpTypes,
  FollowUp as FollowUpType,
} from "../../../../interfaces/engangement.interface";
import {
  getFieldsToShow,
  FieldsSubset,
} from "../../../../services/form-config.service";
import { ListOptions, OmitID, readable } from "../../../../interfaces/utils";
import { CommonFields } from "./CommonFields";
import { LifestyleQuestionnaire } from "./LifestyleQuestionnaire";
import { DiabetesQuestionnaire } from "./DiabetesQuestionnaire";
import { BloodPressuerStatistics } from "./BloodPressureStatistics";
import { UrgentDiabetes } from "./UrgentDiabetes";
import { UrgentHypertension } from "./UrgentHypertension";
import { RedFlagQuestionnaires } from "./RedFlagQuestionnaire";
import { CaseFinding } from "./CaseFinding";
import { BloodPressureCaseFinding } from "./BloodPressureCaseFinding";
import { HypertensionCaseFinding } from "./HypertensionCaseFinding";
import { PastMedicalHistory } from "./PastMedicalHistory";
import { DiabetesCaseFinding } from "./DiabetesCaseFinding";
import { MedicationReview } from "./MedicationReview";
import { Actions } from "./Actions";
import { AdviceGiven } from "./AdviceGiven";
import { OnwardReferral } from "./OnwardReferral";
import { FollowUp } from "./FollowUp";
import {
  HTTP_SERVICE,
  isErrorResponse,
  ErrorResponse,
} from "../../../../services/http.service";
import {
  getLastLifeStyleSubmission,
  getLastDiabetesSubmission,
  getBloodPressureStatisticsForDateRange,
  readingsToQuestionnaire,
} from "../../utils";
import {
  EFormType,
  FormType,
} from "../../../../interfaces/questionnaire.interface";
import {
  ETaskStatusTypes,
  TaskType,
} from "../../../../interfaces/task.interface";

interface InteractionFormProps {
  closeForm: () => void;
}

const AssessmentForm = ({ closeForm }: InteractionFormProps) => {
  const user = useRecoilValue(getLoggedUser);
  const patientId = useRecoilValue(selectedPatientId);
  const [selectedAssessment] = useRecoilState(selectedAssessmentAtom);
  const [assessmentTemplates, setAssessmentTemplates] = useState<string[]>([]);

  const setAssessmentTamplate = (
    assessmentTamplateValues: AssessmentTemplate[]
  ) => {
    useEffect(() => {
      setAssessmentTemplates(assessmentTamplateValues);
    }, [assessmentTamplateValues]);
    return assessmentTamplateValues;
  };

  const findFollowUpProps = (
    followUp: FollowUpType[],
    formType: EFormType | "book_follow_up",
    requiredValueOf: "formType" | "date" | "note" | "type"
  ) => {
    let followUpAttribute;
    if (formType === "book_follow_up") {
      followUpAttribute = followUp.find(
        (item) => item.type === "book_follow_up"
      );
    } else {
      followUpAttribute = followUp.find(
        (item) => item.type === "submit_form" && item.formType === formType
      );
    }
    return followUpAttribute ? followUpAttribute[requiredValueOf] : "";
  };

  const getAssessmentInitialValues = (
    selectedAssessmentParams: Partial<IClinicalReview | undefined>
  ) => ({
    assessmentTemplate: selectedAssessmentParams?.content?.assessmentTemplate
      ? setAssessmentTamplate(
          selectedAssessmentParams?.content?.assessmentTemplate
        )
      : (assessmentTemplates as AssessmentTemplate[]),
    appointment: selectedAssessmentParams?.content?.appointment
      ? selectedAssessmentParams?.content?.appointment
      : undefined,
    occurredAtDatetime: selectedAssessmentParams?.content?.occurredAtDatetime
      ? fromDB(selectedAssessmentParams?.content?.occurredAtDatetime as string)
      : "",
    occurredAtTime: selectedAssessmentParams?.content?.occurredAtDatetime
      ? `${extractTime(
          selectedAssessmentParams?.content?.occurredAtDatetime as string
        )}`
      : "",
    patientAttendance: selectedAssessmentParams?.content?.patientAttendance
      ? selectedAssessmentParams?.content?.patientAttendance
      : undefined,
    patientIdentityConfirmed: selectedAssessmentParams?.content
      ?.patientIdentityConfirmed
      ? selectedAssessmentParams?.content?.patientIdentityConfirmed
      : false,
    isPatientRepresentativePresent: selectedAssessmentParams?.content
      ?.isPatientRepresentativePresent
      ? selectedAssessmentParams?.content?.isPatientRepresentativePresent
      : false,
    isFailedEncounter: selectedAssessmentParams?.content?.isFailedEncounter
      ? selectedAssessmentParams?.content?.isFailedEncounter
      : false,
    isVideoCall: selectedAssessmentParams?.content?.isVideoCall
      ? selectedAssessmentParams?.content?.isVideoCall
      : false,
    lifestyleWeightAdvice: selectedAssessmentParams?.content
      ?.lifestyleWeightAdvice
      ? selectedAssessmentParams?.content?.lifestyleWeightAdvice
      : undefined,
    lifestyleSmokingAdvice: selectedAssessmentParams?.content
      ?.lifestyleSmokingAdvice
      ? selectedAssessmentParams?.content?.lifestyleSmokingAdvice
      : undefined,
    lifestyleDietAdvice: selectedAssessmentParams?.content?.lifestyleDietAdvice
      ? selectedAssessmentParams?.content?.lifestyleDietAdvice
      : undefined,
    lifestyleExerciseAdvice: selectedAssessmentParams?.content
      ?.lifestyleExerciseAdvice
      ? selectedAssessmentParams?.content?.lifestyleExerciseAdvice
      : undefined,
    urgentReviewDiabetes: selectedAssessmentParams?.content
      ?.urgentReviewDiabetes
      ? selectedAssessmentParams?.content?.urgentReviewDiabetes
      : [],
    urgentReviewHypertension: selectedAssessmentParams?.content
      ?.urgentReviewHypertension
      ? selectedAssessmentParams?.content?.urgentReviewHypertension
      : [],
    abdominalPain: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.abdominalPain
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire?.abdominalPain
      : undefined,
    acuteOnsetLimbSymptoms: selectedAssessmentParams?.content
      ?.redFlagQuestionnaire?.acuteOnsetLimbSymptoms
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire
          ?.acuteOnsetLimbSymptoms
      : undefined,
    assistanceToManageHypos: selectedAssessmentParams?.content
      ?.redFlagQuestionnaire?.assistanceToManageHypos
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire
          ?.assistanceToManageHypos
      : undefined,
    bloodInUrine: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.bloodInUrine
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire?.bloodInUrine
      : undefined,
    blurredVision: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.blurredVision
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire?.blurredVision
      : undefined,
    capillaryBloodGlucoseLess2p8: selectedAssessmentParams?.content
      ?.redFlagQuestionnaire?.capillaryBloodGlucoseLess2p8
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire
          ?.capillaryBloodGlucoseLess2p8
      : undefined,
    chestPain: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.chestPain
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire?.chestPain
      : undefined,
    dizziness: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.dizziness
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire?.dizziness
      : undefined,
    drySkinAndMouth: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.drySkinAndMouth
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire?.drySkinAndMouth
      : undefined,
    evidenceOfKetones: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.evidenceOfKetones
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire
          ?.evidenceOfKetones
      : undefined,
    fastDeepOrDifficultBreathing: selectedAssessmentParams?.content
      ?.redFlagQuestionnaire?.fastDeepOrDifficultBreathing
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire
          ?.fastDeepOrDifficultBreathing
      : undefined,
    flushedFace: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.flushedFace
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire?.flushedFace
      : undefined,
    fruitySmellingBreath: selectedAssessmentParams?.content
      ?.redFlagQuestionnaire?.fruitySmellingBreath
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire
          ?.fruitySmellingBreath
      : undefined,
    lethargyDrowsinessOrConfusion: selectedAssessmentParams?.content
      ?.redFlagQuestionnaire?.lethargyDrowsinessOrConfusion
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire
          ?.lethargyDrowsinessOrConfusion
      : undefined,
    muscleStiffnessOrAches: selectedAssessmentParams?.content
      ?.redFlagQuestionnaire?.muscleStiffnessOrAches
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire
          ?.muscleStiffnessOrAches
      : undefined,
    nauseaAndVomiting: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.nauseaAndVomiting
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire
          ?.nauseaAndVomiting
      : undefined,
    nosebleeds: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.nosebleeds
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire?.nosebleeds
      : undefined,
    palpitations: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.palpitations
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire?.palpitations
      : undefined,
    persistentGlucoseMore15: selectedAssessmentParams?.content
      ?.redFlagQuestionnaire?.persistentGlucoseMore15
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire
          ?.persistentGlucoseMore15
      : undefined,
    severeHeadache: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.severeHeadache
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire?.severeHeadache
      : undefined,
    shortnessOfBreath: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.shortnessOfBreath
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire
          ?.shortnessOfBreath
      : undefined,
    urinatingLotMoreThanUsual: selectedAssessmentParams?.content
      ?.redFlagQuestionnaire?.urinatingLotMoreThanUsual
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire
          ?.urinatingLotMoreThanUsual
      : undefined,
    veryThirsty: selectedAssessmentParams?.content?.redFlagQuestionnaire
      ?.veryThirsty
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire?.veryThirsty
      : undefined,
    safetyNettingForSelectedRedFlags: selectedAssessmentParams?.content
      ?.redFlagQuestionnaire?.safetyNettingForSelectedRedFlags
      ? selectedAssessmentParams?.content?.redFlagQuestionnaire
          ?.safetyNettingForSelectedRedFlags
      : false,
    hypertensionCaseFinding: selectedAssessmentParams?.content
      ?.hypertensionCaseFinding
      ? selectedAssessmentParams.content?.hypertensionCaseFinding
      : undefined,
    bloodPressureHistory: selectedAssessmentParams?.content
      ?.bloodPressureHistory
      ? selectedAssessmentParams?.content?.bloodPressureHistory
      : "",
    bloodPressurePlan: selectedAssessmentParams?.content?.bloodPressurePlan
      ? selectedAssessmentParams?.content?.bloodPressurePlan
      : "",
    bloodPressureMedicationChanges: selectedAssessmentParams?.content
      ?.bloodPressureMedicationChanges
      ? selectedAssessmentParams?.content?.bloodPressureMedicationChanges
      : undefined,
    bloodPressureMedicationChangeDetail: selectedAssessmentParams?.content
      ?.bloodPressureMedicationChangeDetail
      ? selectedAssessmentParams?.content?.bloodPressureMedicationChangeDetail
      : "",
    hypertensionHistory: selectedAssessmentParams?.content?.hypertensionHistory
      ? selectedAssessmentParams?.content?.hypertensionHistory
      : "",
    hypertensionPlan: selectedAssessmentParams?.content?.hypertensionPlan
      ? selectedAssessmentParams?.content?.hypertensionPlan
      : "",
    hypertensionMedicationChanges: selectedAssessmentParams?.content
      ?.hypertensionMedicationChanges
      ? selectedAssessmentParams?.content?.hypertensionMedicationChanges
      : undefined,
    hypertensionMedicationChangeDetail: selectedAssessmentParams?.content
      ?.hypertensionMedicationChangeDetail
      ? selectedAssessmentParams?.content?.hypertensionMedicationChangeDetail
      : "",
    diabetesHistory: selectedAssessmentParams?.content?.diabetesHistory
      ? selectedAssessmentParams?.content?.diabetesHistory
      : "",
    diabetesPlan: selectedAssessmentParams?.content?.diabetesPlan
      ? selectedAssessmentParams?.content?.diabetesPlan
      : "",
    diabetesMedicationChanges: selectedAssessmentParams?.content
      ?.diabetesMedicationChanges
      ? selectedAssessmentParams?.content?.diabetesMedicationChanges
      : undefined,
    diabetesMedicationChangeDetail: selectedAssessmentParams?.content
      ?.diabetesMedicationChangeDetail
      ? selectedAssessmentParams?.content?.diabetesMedicationChangeDetail
      : "",
    medicationReview: selectedAssessmentParams?.content?.medicationReview
      ? selectedAssessmentParams?.content?.medicationReview
      : [],
    investigationsOrdered: selectedAssessmentParams?.content
      ?.investigationsOrdered
      ? selectedAssessmentParams?.content?.investigationsOrdered
      : [],
    adviceGiven: selectedAssessmentParams?.content?.adviceGiven
      ? selectedAssessmentParams?.content?.adviceGiven
      : [],
    onwardReferralCompleted: selectedAssessmentParams?.content
      ?.onwardReferralCompleted
      ? selectedAssessmentParams?.content?.onwardReferralCompleted
      : [],
    followUpBpForm: selectedAssessment?.content?.followUp
      ? findFollowUpProps(
          selectedAssessment?.content?.followUp,
          EFormType.blood_pressure_reading_form,
          "formType"
        )
      : undefined,
    followUpBpFormDate: selectedAssessment?.content?.followUp
      ? (findFollowUpProps(
          selectedAssessment?.content?.followUp,
          EFormType.blood_pressure_reading_form,
          "date"
        ) as string)
      : undefined,
    followUpBpFormNotes: selectedAssessment?.content?.followUp
      ? (findFollowUpProps(
          selectedAssessment?.content?.followUp,
          EFormType.blood_pressure_reading_form,
          "note"
        ) as string)
      : undefined,
    followUpLifestyleForm: selectedAssessment?.content?.followUp
      ? findFollowUpProps(
          selectedAssessment?.content?.followUp,
          EFormType.lifestyle_questionnaire_form,
          "formType"
        )
      : undefined,
    followUpLifestyleFormDate: selectedAssessment?.content?.followUp
      ? (findFollowUpProps(
          selectedAssessment?.content?.followUp,
          EFormType.lifestyle_questionnaire_form,
          "date"
        ) as string)
      : undefined,
    followUpLifestyleFormNotes: selectedAssessment?.content?.followUp
      ? (findFollowUpProps(
          selectedAssessment?.content?.followUp,
          EFormType.lifestyle_questionnaire_form,
          "note"
        ) as string)
      : undefined,
    bookFollowUpReminder: selectedAssessment?.content?.followUp
      ? findFollowUpProps(
          selectedAssessment?.content?.followUp,
          "book_follow_up",
          "type"
        )
      : undefined,
    bookFollowUpReminderDate: selectedAssessment?.content?.followUp
      ? (findFollowUpProps(
          selectedAssessment?.content?.followUp,
          "book_follow_up",
          "date"
        ) as string)
      : undefined,
    bookFollowUpReminderNotes: selectedAssessment?.content?.followUp
      ? (findFollowUpProps(
          selectedAssessment?.content?.followUp,
          "book_follow_up",
          "note"
        ) as string)
      : undefined,
  });

  const [bloodPressStatistics, setBloodPressStatistics] =
    useState<BloodPressureStats>({
      maxSystolic: null,
      maxDiastolic: null,
      avgSystolic: null,
      avgDiastolic: null,
      from: "",
      to: "",
    });

  const patientEngagements = useRecoilValue(engagementListAtom);
  const setTemplateFields = useSetRecoilState<FieldsSubset>(
    selectedTemplateFieldsAtom
  );
  const lastLifeStyleSubmission =
    getLastLifeStyleSubmission(patientEngagements);
  const lastDiabetesSubmissionas =
    getLastDiabetesSubmission(patientEngagements);

  const getBloodPressureStatistics = (startDate?: string, endDate?: string) => {
    setBloodPressStatistics(
      getBloodPressureStatisticsForDateRange(
        patientEngagements,
        startDate ?? bloodPressStatistics?.from,
        endDate ?? bloodPressStatistics?.to
      )
    );
  };

  const handleAsessmentTemplateChange = (
    event: SelectChangeEvent<typeof assessmentTemplates>
  ) => {
    const {
      target: { value },
    } = event;
    setAssessmentTemplates(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleUpdateResponse = () => {
    closeForm();
    // setPatient(updatedPatient);
  };

  const creatFollowUpTasks = (followUps: FollowUpType[]) =>
    Promise.all(
      followUps.map((followUp) =>
        HTTP_SERVICE.createTask({
          type: followUp.type as TaskType,
          formType: followUp?.formType as FormType,
          followUp: true,
          user: user?._id as string,
          patient: patientId as string,
          assigne: user?._id as string,
          start: followUp.date,
          end: followUp.date,
          status: {
            type: ETaskStatusTypes.draft,
            datetime: new Date().toISOString(),
            user: user?._id as string,
          },
          note: followUp.note,
        })
      )
    );

  useEffect(() => {
    setTemplateFields(
      getFieldsToShow(assessmentTemplates as AssessmentTemplate[])
    );
    getBloodPressureStatistics();
  }, [assessmentTemplates]);

  return (
    <>
      <Box
        sx={{
          height: "50px",
          width: "100%",
        }}
      >
        <Typography variant="h6">
          {selectedAssessment ? `Edit Assessment` : `Assessment`}{" "}
        </Typography>
      </Box>
      <Formik
        initialValues={getAssessmentInitialValues(selectedAssessment)}
        onSubmit={(values) => {
          const {
            abdominalPain,
            acuteOnsetLimbSymptoms,
            assistanceToManageHypos,
            bloodInUrine,
            blurredVision,
            capillaryBloodGlucoseLess2p8,
            chestPain,
            dizziness,
            drySkinAndMouth,
            evidenceOfKetones,
            fastDeepOrDifficultBreathing,
            flushedFace,
            fruitySmellingBreath,
            lethargyDrowsinessOrConfusion,
            muscleStiffnessOrAches,
            nauseaAndVomiting,
            nosebleeds,
            palpitations,
            persistentGlucoseMore15,
            severeHeadache,
            shortnessOfBreath,
            urinatingLotMoreThanUsual,
            veryThirsty,
            safetyNettingForSelectedRedFlags,
            occurredAtDatetime,
            occurredAtTime,
            followUpBpForm,
            followUpBpFormDate,
            followUpBpFormNotes,
            followUpLifestyleForm,
            followUpLifestyleFormDate,
            followUpLifestyleFormNotes,
            bookFollowUpReminder,
            bookFollowUpReminderDate,
            bookFollowUpReminderNotes,
            ...other
          } = values;

          const followUp: FollowUpType[] = [];

          if (followUpBpForm && followUpBpFormDate) {
            followUp.push({
              type: EFollowUpTypes.submit_form,
              formType: EFormType.blood_pressure_reading_form,
              sendSMS: true,
              date: followUpBpFormDate,
              note: followUpBpFormNotes as string,
            });
          }

          if (followUpLifestyleForm && followUpLifestyleFormDate) {
            followUp.push({
              type: EFollowUpTypes.submit_form,
              formType: EFormType.lifestyle_questionnaire_form,
              sendSMS: true,
              date: followUpLifestyleFormDate,
              note: followUpLifestyleFormNotes as string,
            });
          }

          if (bookFollowUpReminder && bookFollowUpReminderDate) {
            followUp.push({
              type: EFollowUpTypes.book_follow_up,
              sendSMS: true,
              date: bookFollowUpReminderDate,
              note: bookFollowUpReminderNotes as string,
            });
          }

          const newAssessment: OmitID<IClinicalReview> = {
            type: EEngagementTypes.clinical_review,
            user: user?._id as string,
            patient: patientId as string,
            content: {
              ...other,
              bloodPressureStatistics: bloodPressStatistics,
              diabetesQuestionnaire: readingsToQuestionnaire(
                lastDiabetesSubmissionas
              ),
              lifestyleQuestionnaire: readingsToQuestionnaire(
                lastLifeStyleSubmission
              ),
              redFlagQuestionnaire: {
                abdominalPain,
                acuteOnsetLimbSymptoms,
                assistanceToManageHypos,
                bloodInUrine,
                blurredVision,
                capillaryBloodGlucoseLess2p8,
                chestPain,
                dizziness,
                drySkinAndMouth,
                evidenceOfKetones,
                fastDeepOrDifficultBreathing,
                flushedFace,
                fruitySmellingBreath,
                lethargyDrowsinessOrConfusion,
                muscleStiffnessOrAches,
                nauseaAndVomiting,
                nosebleeds,
                palpitations,
                persistentGlucoseMore15,
                severeHeadache,
                shortnessOfBreath,
                urinatingLotMoreThanUsual,
                veryThirsty,
                safetyNettingForSelectedRedFlags,
              },
              followUp,
              occurredAtDatetime: combineDateTime(
                occurredAtDatetime,
                occurredAtTime
              ) as string,
            },
            note: "assessment submitetd",
            datetime: DateTime.now().toString(),
          };

          if (selectedAssessment) {
            HTTP_SERVICE.updateEngagement(
              selectedAssessment._id as string,
              newAssessment as Partial<IEngagement>
            ).then((result: IEngagement | ErrorResponse): void => {
              if (!isErrorResponse(result)) {
                creatFollowUpTasks(followUp).then(() => {
                  handleUpdateResponse();
                });
              }
            });
          } else {
            HTTP_SERVICE.createEngagement(
              newAssessment as Partial<IEngagement>
            ).then((result: IEngagement | ErrorResponse): void => {
              if (!isErrorResponse(result)) {
                creatFollowUpTasks(followUp).then(() => {
                  handleUpdateResponse();
                });
              }
            });
          }
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl size="small" fullWidth>
                  <InputLabel>Assessment Type</InputLabel>
                  <Select
                    variant="outlined"
                    name="assessmentTemplate"
                    value={assessmentTemplates}
                    onChange={(e) => {
                      handleAsessmentTemplateChange(e);
                      formik.handleChange(e);
                    }}
                    input={<OutlinedInput label="Assessment Template" />}
                    renderValue={(selected) =>
                      selected.map(readable).join(", ")
                    }
                    style={{ whiteSpace: "pre-line" }}
                    multiple
                  >
                    {assessmentTemplateOptions().map(
                      (i: ListOptions<AssessmentTemplate>) => (
                        <MenuItem key={i.value} value={i.value}>
                          <Checkbox
                            checked={assessmentTemplates.indexOf(i.value) > -1}
                          />
                          <ListItemText primary={i.label} />
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <CommonFields />

              <PastMedicalHistory />

              <BloodPressuerStatistics
                bloodPressStatistics={
                  selectedAssessment?.content?.bloodPressureStatistics
                    ? selectedAssessment?.content?.bloodPressureStatistics
                    : bloodPressStatistics
                }
                getBloodPressureStatistics={getBloodPressureStatistics}
              />

              <LifestyleQuestionnaire
                lastLifeStyleSubmission={lastLifeStyleSubmission}
              />

              <DiabetesQuestionnaire
                lastDiabetesSubmission={lastDiabetesSubmissionas}
              />

              <UrgentDiabetes />

              <UrgentHypertension />

              <RedFlagQuestionnaires />

              <CaseFinding />

              <BloodPressureCaseFinding />

              <HypertensionCaseFinding />

              <DiabetesCaseFinding />

              <MedicationReview />

              <Actions />

              <AdviceGiven />

              <OnwardReferral />

              <FollowUp
                followUpSubmission={
                  selectedAssessment?.content?.followUp
                    ? selectedAssessment?.content?.followUp
                    : undefined
                }
              />

              <Grid item xs={6}>
                <Button type="submit" variant="contained" size="small">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AssessmentForm;
