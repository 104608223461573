import React from "react";
import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import MuiTheme from "./shared/theme/MuiTheme";
import Spiner from "./shared/components/Spiner";
import AppLayout from "./shared/components/Layout";
import AppRouter from "./AppRouter";

const App = () => (
  <ThemeProvider theme={MuiTheme}>
    <CssBaseline />
    <React.Suspense fallback={<Spiner />}>
      <AppLayout page={<AppRouter defaultRoute="/" />} />
    </React.Suspense>
  </ThemeProvider>
);

export default App;
