const URLS = {
  login: "/login",
  patientList: "/patients",
  patientDetail: "/patients/:id",
};

type Language = { [key: string]: string };

const other = {
  type: "Type",
  templateVersion: "Template Version",
  templateType: "Template Type",
  senderID: "Sender ID",
  recipient: "Recipient",
};
const tasksLable: Language = {
  general: "General",
  gp_referral: "GP Referal",
  registration: "Registration",
  opt_out_request: "Opt Out Discussion",
  other_task: "Other Task",
  attempt_to_engage: "Attempt to Engage",
  attempt_to_re_engage: "Attempt to Re-engage",
  attempt_to_engage_final: "Final Attempt to Engage",
  re_engage_window: "Re-engagement Window",
  submit_form: "Submit Form",
  reply_to_phone_call: "Reply to Phone Call",
  reply_to_voice_message: "Reply to Voice Message", // watsapp, sms etc
  reply_to_message: "Reply to Message", // watsapp, sms etc
  reply_to_email: "Reply to Email",
  urgent_clinical_assessment: "Urgent Assessment > Clinical",
  urgent_results_assessment: "Urgent Assessment > Result",
  clinical_assessment: "Assessment > Clinical",
  results_assessment: "Assessment > Result",
  check_investiagtions_results: "Check Investigations Results",
  book_follow_up: "Book Follow Up",
};

const patientLabels: Language = {
  abusive: "Abusive",
  opted_out: "Opted Out",
  changed_practice: "Changed Practice",
  practice_off_boarded: "Practice off Boarded",
  deceased: "Deceased",
  registered: "Registered",
  restarted: "Restarted",
  active: "Active",
  paused: "Paused",
};
const LabelMapper: Language = {
  active: "Active",
  additional_note: "additional note",
  admin_note: "Admin Note",
  advice_on_diabetic_eye_screening: "Advice On Diabetic Eye Screening",
  advice_on_vaccinations: "Advice on Vaccinations",
  ae: "999 / A&E",
  alcohol_units_per_week: "Alcohol units consumed per week",
  allergies_confirmed: "Allergies Confirmed",
  ambulatory_blood_pressure: "Ambulatory Blood Pressure",
  anxious_character_finding: "Anxious character finding",
  appointment: "Appointment",
  asthma: "Asthma",
  atrial_filbrillation: "Atrial Filbrillation",
  average: "Average",
  avoid_junk: "Avoid Junk",
  be_more_active: "be_more_active",
  blood_pressure_diastole: "Blood Pressure Diastole",
  blood_pressure_reading_form: "Bloodpressure Reading Form",
  blood_pressure_systole: "Blood Pressure Systole",
  blood_sugar_to_treat: "Blood sugar to treat hypo",
  blood_test: "Blood Test",
  bmi: "BMI",
  bp_checked: "BP Technique Checked",
  check_sugar_before_driving: "Check blood sugar before driving",
  clinical_reading: "Clinical Reading",
  clinical_review: "Clinical Assessment",
  clinical_submission_by_email: "Clinical Form Submission (Clinician Email)",
  clinical_submission_by_other: "Clinical Form Submission (Clinician Other)",
  clinical_submission_by_phone: "Clinical Form Submission (Clinician Phone)",
  clinical_submission_by_sms: "Clinical Form Submission (Clinician SMS)",
  clinician_consent_service: "Clinician Consent Service",
  confidence: "Confidence Level",
  confident: "Confident",
  consultation: "Consultation",
  continue: "Continue",
  copd: "COPD",
  curious_character_finding: "Curious character finding",
  currently_smoking: "Currently Smoking",
  date_last_hypo: "Date last hypo",
  dementia: "Dementia",
  diabetes_questionnaire_form: "Diabetes Questionnaire Form",
  diabetes_routine_review: "Diabetes Routine Assessment",
  diabetes_type_1: "Diabetes Type 1",
  diabetes_type_2: "Diabetes Type 2",
  diabetes_urgent_review: "Diabetes Urgent Assessment",
  diet: "Diet",
  dietary_sodium_intake_finding: "Dietary sodium intake finding",
  different_goal: "different_goal",
  do_1_mile_walk_daily: "do 1 mile walk daily",
  do_not_know: "Do Not know",
  do_regular_exercise: "do regular exercise",
  does_home_glucose: "Does home glucose monitoring",
  draft: "Draft",
  eat_drink_to_treat: "Eat drink to treat hypo",
  email_outgoing: "Email Outgoing",
  email_technical_support_request_incoming:
    "Email Technical Support Request Incoming",
  email_technical_support_request_outgoing:
    "Email Technical Support Request Outgoing",
  essential_hypertension: "Essential Hypertension",
  atrial_fibrillation: "Atrial Fibrillation",
  ex_smoker: "Ex-smoker",
  excessive_caffeine_intake: "Recent Excessive Caffeine Intake",
  existing_diagnosis_recorded: "Existing Diagnosis Recorded",
  experiences_hypos: "Experienced hypos",
  faile_encounter: "Failed Encounter",
  fasting_at_time_of_hypo: "Fasting at time of hypo",
  food_check: "Food Check",
  glucose_below_2p8: "Glucose readings below 2p8mmol",
  glucose_below_4: "Glucose readings below 4mmol",
  goal: "Goal",
  good: "Good",
  gp: "GP",
  heart_failure: "Heart Failure",
  height: "Height",
  hospital: "Hospital",
  hypertension_case_finding: "Hypertension Case Finding",
  hypertension_routine_review: "Hypertension Routine Assessment",
  hypertension_urgent_review: "Hypertension Urgent Assessment",
  hyperthyroidism: "Hyperthyroidism",
  hypothyroidism: "Hypothyroidism",
  identifying_barriers_to_goal_achievement_procedure:
    "identifying barriers to goal achievement procedure",
  identity_verified: "Identity Verified",
  improve_their_diet: "improve_their_diet",
  increase: "Increase",
  intro_call: "Intro Call",
  intro_message_ehr: "Intro Message EHR",
  leave: "Leave",
  less_than_1: "< 1",
  lifestyle_questionnaire_form: "Lifestyle Questionnaire Form",
  manage_their_condition_better: "manage_their_condition_better",
  medication_compliance_checked: "Medication Compliance Checked",
  medications_altered: "Medications Recently Altered/Initiated",
  follow_up_blood_pressure_reading_form: "Send SMS > Follow Up BP Form",
  follow_up_lifestyle_questionnaire_form: "Send SMS > Follow Up Lifestyle Form",
  follow_up_book_follow_up: "Remind Me > Book Follow Up",
  more_than_20: "20+",
  never: "Never",
  newly_diagnosed: "Newly Diagnosed",
  none: "None",
  not_hypertensive: "Not Hypertensive",
  not_present: "Not Present",
  not_recorded: "Not Recorded",
  not_very_active: "Not Very Active",
  num_times_sugar_less_4: "Num times in week blood sugar less than 4",
  no: "No",
  ongoing_investigation: "Ongoing Investigation",
  opt_out_call: "Opt-out Call",
  other: "Other",
  other_clinical_note: "Other Clinical Note",
  patient_alert_created: "Patient Alert Created",
  patient_blood_pressure_form_submission: "Blood Pressure Readings (Patient)",
  patient_consent_form_submission: "Consent Form (Patient)",
  patient_details_updated: "Patient Details Updated",
  patient_diabetes_form_submission: "Diabetes Questionnaire (Patient)",
  patient_lifestyle_form_submission: "Lifestyle Questionnaire (Patient)",
  patient_not_required: "Patient Not Required",
  phone_technical_support_request_incoming:
    "Phone Technical Support Request Incoming",
  phone_technical_support_request_outgoing:
    "Phone Technical Support Request Outgoing",
  physical_activity: "Level of physical activity observable entity",
  physical_examination: "Physical Examination",
  poor: "Poor",
  pre_history_dka: "Has Previous History of DKA",
  prescription_issued: "Prescription Issued",
  present: "Present",
  pulse_rate: "Pulse Rate",
  quit: "Quit",
  re_engagement_call: "Re-engagement Call",
  recent_exercise: "Recent Exercise",
  red_flag_questionnaire_form: "Redflag Questionnaire Form",
  reduce: "Reduce",
  reduce_exercise: "Reduce Exercise",
  representative_present: "Representative Present",
  resistant_hypertension: "Resistant Hypertension",
  side_effects_discussed: "Side Effects Discussed",
  slightly_active: "Slightly Active",
  smoking: "Smooking Status",
  smoking_year_quit: "Smooker Year Quit",
  sms_outgoing: "SMS Outgoing",
  sms_technical_support_request_incoming:
    "SMS Technical Support Request Incoming",
  sms_technical_support_request_outgoing:
    "SMS Technical Support Request Outgoing",
  socially: "Socially",
  stroke: "Stroke",
  symptoms_during_hypos: "Experienced hypos",
  ten_to_ninteen: "1-9",
  times_help_during_hypos: "Times needed help during hypos",
  times_needed_help_during_hypos_label:
    "How many times have they needed help during a hypo?",
  sms_successful: "SMS Sent",
  uncertain: "Uncertain",
  unconfident: "Unconfident",
  unplanned_appointment: "Unplanned Appointment",
  planned_appointment: "Planned Appointment",
  urine_test: "Urine Test",
  vaping: "Vaping status",
  very_active: "Very Active",
  very_confident: "Very Confident",
  very_unconfident: "Very Unconfident",
  video_call: "Video Call",
  weight: "Weight",
  yes: "Yes",
  ...tasksLable,
  ...patientLabels,
  ...other,
};

export { URLS, LabelMapper };
