import { IClinicalReadingTemplate } from "../../../interfaces/engangement.interface";
import {
  IReading,
  Parameter,
  EBloodPressureParameter,
} from "../../../interfaces/questionnaire.interface";
import { enumKeys } from "../../../interfaces/utils";
import { Reading } from "./Reading";

type BloodPressureReadingProps = {
  content: IClinicalReadingTemplate;
  bgColour: string;
};

const parameters = enumKeys(EBloodPressureParameter) as Parameter[];

const sortByParameter = (a: IReading, b: IReading) =>
  (parameters.includes(a.parameter) ? parameters.indexOf(a.parameter) : 100) -
  (parameters.includes(b.parameter) ? parameters.indexOf(b.parameter) : 100);

export const BloodPressureReading = ({
  content,
  bgColour,
}: BloodPressureReadingProps) => {
  const readings = content?.readings?.flat()?.sort(sortByParameter) ?? [];

  return <Reading bgColour={bgColour} readings={readings} />;
};
