import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Field, FieldProps } from "formik";
import { FieldsSubset } from "../../../../services/form-config.service";
import { selectedTemplateFieldsAtom } from "../../../../services/state.service";
import { fromDB, fromForm } from "../../../../services/datetime.service";
import { BloodPressureStats } from "../../../../interfaces/engangement.interface";

type BloodPressuerStatisticsProps = {
  bloodPressStatistics: BloodPressureStats;
  getBloodPressureStatistics: (startDate: string, endDate: string) => void;
};
export const BloodPressuerStatistics = ({
  getBloodPressureStatistics,
  bloodPressStatistics,
}: BloodPressuerStatisticsProps) => {
  const [startDate, setStartDate] = useState(bloodPressStatistics.from);
  const [endDate, setEndDate] = useState(bloodPressStatistics.to);

  const templateFields = useRecoilValue<FieldsSubset>(
    selectedTemplateFieldsAtom
  );

  useEffect(() => {
    setStartDate(bloodPressStatistics.from);
    setEndDate(bloodPressStatistics.to);
  }, [bloodPressStatistics, setStartDate, setEndDate]);

  return (
    <Grid
      item
      container
      alignItems="flex-end"
      xs={12}
      rowSpacing={4}
      spacing={1}
    >
      {templateFields?.bloodPressureStatistics && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}

      {templateFields?.bloodPressureStatistics && (
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h6">Clinical Readings</Typography>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <Button
                size="small"
                sx={{ textTransform: "none" }}
                endIcon={<OpenInNewIcon />}
              >
                Add BP Reading
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {templateFields?.bloodPressureStatistics && (
        <Grid item xs={4}>
          <Typography variant="subtitle2" sx={{ ml: 4 }}>
            Average
          </Typography>
          <Stack direction="row" alignItems="center" gap={1}>
            <FiberManualRecordIcon />
            <Typography variant="body1">
              {bloodPressStatistics.avgSystolic} /{" "}
              {bloodPressStatistics.avgDiastolic} mmHg
            </Typography>
          </Stack>
        </Grid>
      )}

      {templateFields?.bloodPressureStatistics && (
        <Grid item xs={4}>
          <Typography variant="subtitle2" sx={{ ml: 4 }}>
            Max Sys
          </Typography>
          <Stack direction="row" alignItems="center" gap={1}>
            <FiberManualRecordIcon />
            <Typography variant="body1">
              {bloodPressStatistics.maxSystolic}
            </Typography>
          </Stack>
        </Grid>
      )}

      {templateFields?.bloodPressureStatistics && (
        <Grid item xs={4}>
          <Typography variant="subtitle2" sx={{ ml: 4 }}>
            Max Dia
          </Typography>
          <Stack direction="row" alignItems="center" gap={1}>
            <FiberManualRecordIcon />
            <Typography variant="body1">
              {bloodPressStatistics.maxDiastolic}
            </Typography>
          </Stack>
        </Grid>
      )}

      {templateFields?.bloodPressureStatistics && (
        <Field name="bpStartDate">
          {(fieldProps: FieldProps<string[]>) => {
            const { handleChange, handleBlur } = fieldProps.form;
            const { error, touched } = fieldProps.meta;
            return (
              <Grid item xs={4} sx={{ mt: 2 }}>
                <TextField
                  label="Start Date"
                  variant="outlined"
                  size="small"
                  type="date"
                  name="bpStartDate"
                  defaultValue={fromDB(startDate as string)}
                  onChange={(e) => {
                    setStartDate(fromForm(e.target.value));
                    handleChange(fromForm(e.target.value));
                    getBloodPressureStatistics(fromForm(e.target.value), endDate);
                  }}
                  onBlur={handleBlur}
                  error={touched && Boolean(error)}
                  helperText={touched && error}
                  InputLabelProps={{ shrink: true }}
                  // inputProps={{
                  //   min: `${localFormat(new Date().toISOString())}`,
                  // }}
                  fullWidth
                />
              </Grid>
            );
          }}
        </Field>
      )}

      {templateFields?.bloodPressureStatistics && (
        <Field name="bpEndDate">
          {(fieldProps: FieldProps<string[]>) => {
            const { handleChange, handleBlur } = fieldProps.form;
            const { error, touched } = fieldProps.meta;
            return (
              <Grid item xs={4} sx={{ mt: 2 }}>
                <TextField
                  label="End Date"
                  variant="outlined"
                  size="small"
                  type="date"
                  name="bpEndDate"
                  defaultValue={fromDB(endDate as string)}
                  onChange={(e) => {
                    setEndDate(fromForm(e.target.value));
                    handleChange(fromForm(e.target.value));
                    getBloodPressureStatistics(startDate, fromForm(e.target.value));
                  }}
                  onBlur={handleBlur}
                  error={touched && Boolean(error)}
                  helperText={touched && error}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
            );
          }}
        </Field>
      )}
    </Grid>
  );
};
