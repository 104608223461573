import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button, IconButton, LinearProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useRecoilState, useSetRecoilState } from "recoil";
import { styled } from "@mui/material/styles";
import CloudUploadSharpIcon from "@mui/icons-material/CloudUploadSharp";
import {
  activeFormAtom,
  patientForms,
  selectedPatientAtom,
} from "../../../../services/state.service";
import {
  ErrorResponse,
  HTTP_SERVICE,
  isErrorResponse,
} from "../../../../services/http.service";
import {
  EPatientActiveStatusType,
  ISignedUrl,
} from "../../../../interfaces/patient.interface";
import { readable } from "../../../../interfaces/utils";

const ProfileCard = () => {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [uploadBar, setUploadBar] = useState(false);
  const [uploadBtnStyle, setUploadBtnStyle] = useState({ display: "none" });
  const [patient, setPatient] = useRecoilState(selectedPatientAtom);
  const setActiveForm = useSetRecoilState(activeFormAtom);
  const handleEdit = () => setActiveForm(patientForms.PatientDetailFrom);

  const [file, setFile] = useState<File>();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFile = e.target.files as FileList;
    const selectedFile = inputFile?.[0];
    if (
      selectedFile.type === "image/png" ||
      selectedFile.type === "image/jpeg"
    ) {
      setFile(selectedFile);
    }
  };

  useEffect(() => {
    if (file?.name !== undefined) {
      setUploadBar(true);
      HTTP_SERVICE.updateProfileImage(patient._id, file?.name).then(
        (result: ISignedUrl | ErrorResponse): void => {
          if (!isErrorResponse(result)) {
            HTTP_SERVICE.uploadFile(file, result.putUrl).then(() => {
              setUploadBar(false);
              setPatient({ ...patient, imageKey: result.getUrl });
            });
          }
        }
      );
    }
  }, [file, setFile]);

  return (
    <Card sx={{ display: "flex" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent
          sx={{ flex: "1 0 auto" }}
          onMouseEnter={() => {
            setUploadBtnStyle({ display: "block" });
          }}
          onMouseLeave={() => {
            setUploadBtnStyle({ display: "none" });
          }}
        >
          <Box sx={{ display: "flex", position: "relative" }}>
            <CardMedia
              sx={{ width: 120, height: 120, border: "1px", borderRadius: 1 }}
              image={
                patient?.imageKey ? `${patient?.imageKey}` : "/profile.png"
              }
              title="green iguana"
            />
            <Button
              component="label"
              variant="text"
              size="small"
              sx={{
                mt: 0,
                position: "absolute",
                "&.MuiButton-root:hover": { bgcolor: "transparent" },
              }}
              style={uploadBtnStyle}
            >
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
              <CloudUploadSharpIcon />
            </Button>
          </Box>
          {uploadBar && <LinearProgress />}
          <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1, marginLeft: 2}}>
            {readable(patient?.status.type) ??
              readable(EPatientActiveStatusType.registered)}
          </Typography>
        </CardContent>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" sx={{ mt: 2 }}>
            {patient ? `${patient.name.first} ${patient.name.last}😏` : ""}
          </Typography>
          <IconButton size="large" onClick={handleEdit}>
            <EditIcon color="primary" />
          </IconButton>
        </Box>
        <Box>
          <Box>
            <Typography variant="body2" color="text.secondary">
              {patient && patient.phoneNumbers
                ? patient?.phoneNumbers?.mobile1
                : ""}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {patient ? `${patient.emailAddr}` : ""}
            </Typography>
          </Box>
          <Box sx={{ my: 1 }}>
            <Typography variant="body2" color="text.secondary">
              {patient && patient.practice ? patient.practice.name : ""}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              SystemOne
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ProfileCard;
