import { useRecoilValue } from "recoil";
import { Box } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import AccessTime from "@mui/icons-material/AccessTime";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { engagementListAtom } from "../../../../services/state.service";
import { humanReadable } from "../../../../services/datetime.service";
import {
  EEngagementTypes,
  IEngagement,
  IClinicalReading,
  IConsultationEngagement,
  ConsultationType,
  OtherEngagementType,
  IOtherEngagement,
} from "../../../../interfaces/engangement.interface";
import {
  EFormType,
  FormType,
} from "../../../../interfaces/questionnaire.interface";
import { LabelMapper } from "../../../../shared/constants";
import { LifestyleReading } from "../../Shared/LifestyleReading";
import { BloodPressureReading } from "../../Shared/BloodpressureReading";
import { DiabetesReading } from "../../Shared/DiabetesReading";
import { ClinicalAssessment } from "../../Shared/ClinicalAssessment";
import { ClinicalConsultation } from "../../Shared/ClinicalConsultation";
import MuiTheme from "../../../../shared/theme/MuiTheme";
import { timelineStyle } from "../../utils";
import { OtherContent } from "../../Shared/OtherContent";

const getFormType = (engagement: IClinicalReading) =>
  engagement.content.formType as FormType;

const getConsulatationType = (engagement: IConsultationEngagement) =>
  engagement.content.type as ConsultationType;

const getOtherEngagementType = (engagement: IOtherEngagement) =>
  engagement.content.type as OtherEngagementType;

export const EngagementTimeline = () => {
  const patientEngagements = useRecoilValue(engagementListAtom);

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0,
        },
      }}
    >
      {patientEngagements.map((engagement: IEngagement) => {
        const formType =
          engagement.type === EEngagementTypes.clinical_reading
            ? engagement.content.formType
            : undefined;
        const headerStyle = timelineStyle(engagement.type, formType);
        return (
          <TimelineItem key={engagement._id}>
            <TimelineOppositeContent
              sx={{ m: "auto 0", color: headerStyle.backgroundColor }}
            />
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  backgroundColor: headerStyle.backgroundColor,
                  color: MuiTheme.palette.colors.white,
                }}
              >
                {headerStyle.icon}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography fontWeight={700}>
                      {` ${LabelMapper[engagement.type]}`}
                      {engagement.type === EEngagementTypes.clinical_reading &&
                        ` (${LabelMapper[getFormType(engagement)]})`}
                      {engagement.type === EEngagementTypes.consultation &&
                        ` (${LabelMapper[getConsulatationType(engagement)]})`}
                      {engagement.type === EEngagementTypes.other &&
                        ` (${LabelMapper[getOtherEngagementType(engagement)]})`}
                    </Typography>
                    <Typography component="span" sx={{ display: "flex" }}>
                      <AccessTime />
                      {` ${humanReadable(engagement.datetime as string)} - `}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    {engagement.type === EEngagementTypes.clinical_reading &&
                      engagement.content.formType ===
                        EFormType.lifestyle_questionnaire_form && (
                        <LifestyleReading
                          bgColour={headerStyle.backgroundColor}
                          content={engagement.content}
                        />
                      )}
                    {engagement.type === EEngagementTypes.clinical_reading &&
                      engagement.content.formType ===
                        EFormType.blood_pressure_reading_form && (
                        <BloodPressureReading
                          bgColour={headerStyle.backgroundColor}
                          content={engagement.content}
                        />
                      )}
                    {engagement.type === EEngagementTypes.clinical_reading &&
                      engagement.content.formType ===
                        EFormType.diabetes_questionnaire_form && (
                        <DiabetesReading
                          bgColour={headerStyle.backgroundColor}
                          content={engagement.content}
                        />
                      )}
                    {engagement.type === EEngagementTypes.clinical_review && (
                      <ClinicalAssessment
                        assessment={engagement}
                        bgColour={headerStyle.backgroundColor}
                        content={engagement.content}
                      />
                    )}
                    {engagement.type === EEngagementTypes.consultation && (
                      <ClinicalConsultation
                        consultation={engagement}
                        content={engagement.content}
                        bgColour={headerStyle.backgroundColor}
                      />
                    )}

                    {engagement.type === EEngagementTypes.other && (
                      <OtherContent
                        content={engagement.content}
                        bgColour={headerStyle.backgroundColor}
                      />
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};
