import { useRecoilValue } from "recoil";
import { Divider, Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { FieldsSubset } from "../../../../services/form-config.service";
import { selectedTemplateFieldsAtom } from "../../../../services/state.service";
import { RadioButtonField } from "../../../../shared/components";
import { IClinicalAssessmentTemplate, hypertensionCaseFindingeOptions } from "../../../../interfaces/engangement.interface";

export const CaseFinding = () => {
    const templateFields = useRecoilValue<FieldsSubset>(
        selectedTemplateFieldsAtom
    );

    const { values } = useFormikContext<IClinicalAssessmentTemplate>();

    return (
        <Grid item container alignItems="flex-end" xs={12} rowSpacing={4} spacing={1}>

            {templateFields?.hypertensionCaseFinding && (
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Case Finding (Hypertension)
                    </Typography>
                </Grid>
            )}

            {templateFields?.hypertensionCaseFinding && (
                <Grid item xs={12}>
                        <RadioButtonField
                            label="Outcome"
                            name="hypertensionCaseFinding"
                            defaultValue={ values.hypertensionCaseFinding ? values.hypertensionCaseFinding : '' }
                            position="horizontal"
                            options={hypertensionCaseFindingeOptions()}
                        />
                </Grid>
            )}

            {templateFields?.hypertensionCaseFinding && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}
        </Grid>
    );

}