import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Box, CardContent, Grid } from '@mui/material';

const ECGCard = () => (
        <Card sx={{  mt: 2 }} >
            <Box >
                <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <CardMedia
                                    sx={{ wmaxWidth:"xl", height: 120, border: '1px', borderRadius: 1 }}
                                    image="/afecg.png"
                                    title="green iguana"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CardMedia
                                    sx={{  maxWidth:"xl", height: 120, border: '1px', borderRadius: 1 }}
                                    image="/ecg.png"
                                    title="green iguana"
                                />
                            </Grid>
                        </Grid>
                </CardContent>
            </Box>
        </Card>
    );

export default ECGCard;
