import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { IPatientPopulated } from "../../interfaces/patient.interface";
import {
  HTTP_SERVICE,
  isErrorResponse,
  ErrorResponse,
} from "../../services/http.service";
import { engagementListAtom, patientListAtom } from "../../services/state.service";
import { localFormat } from "../../services/datetime.service";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    width: 150,
    editable: false,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.name.first || ""} ${params.row.name.last || ""}`,
  },
  {
    field: "nhsNumber",
    headerName: "NHS Number",
    type: "number",
    width: 150,
    editable: false,
  },
  {
    field: "dateOfBirth",
    headerName: "DOB",
    width: 150,
    editable: false,
    valueGetter: (params: GridValueGetterParams) =>
      `${localFormat(params.row.dateOfBirth)}`,
  },
];

export const PatientList = () => {
  const navigate = useNavigate();
  const [patients, setPatients] = useRecoilState(patientListAtom);
  const [, setEngagements] = useRecoilState(engagementListAtom);

  useEffect(() => {
    
    setEngagements([]);

    HTTP_SERVICE.getPatients().then(
      (result: IPatientPopulated[] | ErrorResponse): void => {
        if (!isErrorResponse(result)) {
          setPatients(result);
        }
      }
    );
  }, [setPatients]);

  const handleRowClick = (params: GridRowParams) =>
    navigate(`/patients/${params.row._id}`);

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        getRowId={(row) => row._id}
        onRowClick={handleRowClick}
        rows={patients}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};
