import { Suspense } from "react";
import { useRecoilState } from "recoil";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { activeFormAtom, patientForms, FormType } from "../../services/state.service";

import PatientDetailFrom from "./Forms/PatientDetailForm";
import { BloodPressureReadingFrom } from "./Forms/BloodPressureReadingForm";
import { MedicalHistoryForm } from "./Forms/MedicalHistoryForm";
import { TaskForm } from "./Forms/TaskForm";
import { AddAlertForm } from "./Forms/AddAlertForm";
import ConsultationForm from "./Forms/ConsultationForm";
import AssessmentForm from "./Forms/AssessmentForm";
import { DiabetesReadingForm } from "./Forms/DiabetesReadingForm";
import { LifestyleReadingForm } from "./Forms/LifestyleReadingForm";

interface FormComponentProps {
  activeForm: FormType;
  handleClose: () => void;
}

const FormComponent = ({ activeForm, handleClose }: FormComponentProps) => {
  switch (activeForm) {
    case "PatientDetailFrom":
      return <PatientDetailFrom closeForm={handleClose} />;
    case "MedicalHistoryForm":
      return <MedicalHistoryForm closeForm={handleClose} />;
    case "BloodPressureReadingFrom":
      return <BloodPressureReadingFrom closeForm={handleClose} />;
    case "TaskForm":
      return <TaskForm closeForm={handleClose} />;
    case "AddAlertForm":
      return <AddAlertForm closeForm={handleClose} />;
    case "ConsultationForm":
      return <ConsultationForm closeForm={handleClose} />;
    case "AssessmentForm":
      return <AssessmentForm closeForm={handleClose} />;
    case "DiabetesReadingForm":
      return <DiabetesReadingForm closeForm={handleClose}/>
    case "LifestyleReadingForm":
      return <LifestyleReadingForm closeForm={handleClose} />;
    default:
      return null;
  }
};

export const FormModal = () => {
  const [activeForm, setActiveForm] = useRecoilState(activeFormAtom);
  const handleClose = () => setActiveForm(patientForms.none);

  return (
    <Dialog
      open={activeForm !== patientForms.none} onClose={handleClose}
      fullWidth
      maxWidth="md">
      <DialogContent>
        <Suspense>
          <FormComponent activeForm={activeForm} handleClose={handleClose} />
        </Suspense>
      </DialogContent>
    </Dialog>
  );
};
