export const basic = {
  primary: {
    main: "#2196f3",
  },
  secondary: {
    main: "#00bcd4",
  },
};

export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  grey: {
    light: "#F9F9F9",
    main: "#B1B5BF",
    dark: "#42495F",
    contrastText: "#B1B5BF",
  },
  info: {
    light: "#E4F0FD",
    main: "#3D8EFF",
    dark: "#2443A4",
    contrastText: "#3D8EFF",
  },
  success: {
    light: "#E9FCEF",
    main: "#51E16E",
    dark: "#2DA53C",
    contrastText: "#51E16E",
  },
  warning: {
    light: "#FFE8DA",
    main: "#F87010",
    dark: "#D24301",
    contrastText: "#F87010",
  },
  error: {
    light: "#FCE2EC",
    main: "#BA284B",
    dark: "#961F3A",
    contrastText: "#BA284B",
  },
  yellow: {
    light: "#FFF8E7",
    main: "#FFD15A",
    dark: "#EAA902",
    contrastText: "#FFD15A",
  },
  greenGradient: {
    light: "linear-gradient(115deg, #7ceb51, #10bd55)",
    main: ' linear-gradient(115deg, #7ceb51, #10bd55)',
    dark: "linear-gradient(115deg, #ffe4c4, #c99564)",
    contrastText: "linear-gradient(115deg, #69cc41, #00963a)",
  },
  blueGradient: {
    light: "linear-gradient(115deg, #ade9ff, #2c67f2)",
    main: 'linear-gradient(115deg, #62cff4, #2c67f2)',
    dark: "linear-gradient(115deg, #26c7fc, #2c67f2)",
    contrastText: 'linear-gradient(115deg, #c2f0ff, #82a8ff)',
  },
  orangeGradient: {
    light: 'linear-gradient(115deg, #fec163, #de4313);',
    main: 'linear-gradient(115deg, #ffad5c, #e6220c)',
    dark: "linear-gradient(115deg, #ffb13d, #de4313)",
    contrastText: 'linear-gradient(115deg, #ffa220, #bd2c00)',
  },
  redGradient: {
    light: 'linear-gradient(115deg, #fec163, #de4313);',
    main: 'linear-gradient(115deg, #ed6ba5, #d9186f)',
    dark: "linear-gradient(115deg, #ffb13d, #de4313)",
    contrastText: 'linear-gradient(115deg, #ffa220, #bd2c00)',
  },
  voiletGradient: {
    light: 'linear-gradient(115deg, #c249ff, #6312de)',
    main: 'linear-gradient(115deg, #b428fa, #5318ab)',
    dark: 'linear-gradient(115deg, #9900e0, #351e57)',
    contrastText: 'linear-gradient(115deg, #ffa220, #bd2c00)',
  },
};
