import {
  AssessmentTemplate,
  IClinicalAssessmentTemplate,
} from "../interfaces/engangement.interface";
import { RedFlagQuestionnaire } from "../interfaces/questionnaire.interface";

export type Fields = Record<
  keyof IClinicalAssessmentTemplate | keyof RedFlagQuestionnaire | "note",
  true
>;

export type FieldsSubset = Partial<Fields>;

const patientContextFields: FieldsSubset = {
  appointment: true,
  isFailedEncounter: true,
  isPatientRepresentativePresent: true,
  isVideoCall: true,
  occurredAtDatetime: true,
  occurredAtTime: true,
  patientAttendance: true,
  patientIdentityConfirmed: true,
};

const redFlagQuestionnaireFields: FieldsSubset = {
  redFlagQuestionnaire: true,
};

const redFlagSafetyNettingFields: FieldsSubset = {
  safetyNettingForSelectedRedFlags: true,
};

const hypertensionRedFlagFields: FieldsSubset = {
  ...redFlagQuestionnaireFields,
  ...redFlagSafetyNettingFields,
  severeHeadache: true,
  chestPain: true,
  palpitations: true,
  shortnessOfBreath: true,
  dizziness: true,
  lethargyDrowsinessOrConfusion: true,
  bloodInUrine: true,
  blurredVision: true,
  nauseaAndVomiting: true,
  abdominalPain: true,
  nosebleeds: true,
  acuteOnsetLimbSymptoms: true,
};

const diabetesRedFlagFields: FieldsSubset = {
  ...redFlagQuestionnaireFields,
  ...redFlagSafetyNettingFields,
  severeHeadache: true,
  lethargyDrowsinessOrConfusion: true,
  nauseaAndVomiting: true,
  abdominalPain: true,
  fastDeepOrDifficultBreathing: true,
  veryThirsty: true,
  urinatingLotMoreThanUsual: true,
  drySkinAndMouth: true,
  flushedFace: true,
  muscleStiffnessOrAches: true,
  evidenceOfKetones: true,
  capillaryBloodGlucoseLess2p8: true,
  assistanceToManageHypos: true,
  persistentGlucoseMore15: true,
  acuteOnsetLimbSymptoms: true,
  fruitySmellingBreath: true,
};

const hypertensionCaseFindingFields: FieldsSubset = {
  hypertensionCaseFinding: true,
};

const bloodPressureFields: FieldsSubset = {
  bloodPressureHistory: true,
  bloodPressurePlan: true,
  bloodPressureMedicationChanges: true,
  bloodPressureMedicationChangeDetail: true,
};

const medicationReviewFields: FieldsSubset = {
  medicationReview: true
};

const bpClinicalReadings: FieldsSubset = {
  bloodPressureStatistics: true,
};

const hypertensionFields: FieldsSubset = {
  ...bpClinicalReadings,
  hypertensionHistory: true,
  hypertensionMedicationChangeDetail: true,
  hypertensionMedicationChanges: true,
  hypertensionPlan: true,
};

const hypertensionUrgentReviewFields: FieldsSubset = {
  urgentReviewHypertension: true,
};

const lifestyleQuestionnaireAndFields: FieldsSubset = {
  lifestyleQuestionnaire: true,
  lifestyleWeightAdvice: true,
  lifestyleSmokingAdvice: true,
  lifestyleDietAdvice: true,
  lifestyleExerciseAdvice: true,
};

const clinicalReferralFields: FieldsSubset = {
  onwardReferralCompleted: true,
};

const investigationFields: FieldsSubset = {
  investigationsOrdered: true,
};

const adviceGivenFields: FieldsSubset = {
  adviceGiven: true,
};

const diabetesFields: FieldsSubset = {
  diabetesHistory: true,
  diabetesPlan: true,
  diabetesMedicationChanges: true,
  diabetesMedicationChangeDetail: true,
};

const urgentReviewDiabetesFields: FieldsSubset = {
  urgentReviewDiabetes: true,
};

const diabetesInfoQuestionnaireFields: FieldsSubset = {
  diabetesQuestionnaire: true,
};

/**
 * Review Template Fields
 */

const htnRoutineReviewFields: FieldsSubset = {
  ...patientContextFields,
  ...hypertensionRedFlagFields,
  ...hypertensionFields,
  ...medicationReviewFields,
  ...lifestyleQuestionnaireAndFields,
  ...clinicalReferralFields,
  ...investigationFields,
  ...adviceGivenFields,
};

const htnUrgentReviewFields: FieldsSubset = {
  ...patientContextFields,
  ...hypertensionUrgentReviewFields,
  ...hypertensionRedFlagFields,
  ...hypertensionFields,
  ...medicationReviewFields,
  ...clinicalReferralFields,
  ...investigationFields,
  ...adviceGivenFields,
};

const htnCaseFindingFields: FieldsSubset = {
  ...patientContextFields,
  ...bpClinicalReadings,
  ...lifestyleQuestionnaireAndFields,
  ...hypertensionRedFlagFields,
  ...hypertensionCaseFindingFields,
  ...bloodPressureFields,
  ...medicationReviewFields,
  ...clinicalReferralFields,
  ...investigationFields,
  ...adviceGivenFields,
};

const diabetesRoutineReviewFields: FieldsSubset = {
  ...patientContextFields,
  ...bpClinicalReadings,
  ...lifestyleQuestionnaireAndFields,
  ...diabetesInfoQuestionnaireFields,
  ...diabetesRedFlagFields,
  ...medicationReviewFields,
  ...clinicalReferralFields,
  ...investigationFields,
  ...adviceGivenFields,
  ...diabetesFields,
};

const diabetesUrgentReviewFields: FieldsSubset = {
  ...patientContextFields,
  ...bpClinicalReadings,
  ...diabetesRedFlagFields,
  ...medicationReviewFields,
  ...clinicalReferralFields,
  ...investigationFields,
  ...adviceGivenFields,
  ...diabetesFields,
  ...urgentReviewDiabetesFields,
  ...diabetesInfoQuestionnaireFields,
  ...diabetesFields,
};

/**
 * Review Template Fields Maping Object
 */

export const reviewTemplateFieldSet: Record<AssessmentTemplate, FieldsSubset> =
  {
    hypertension_routine_review: htnRoutineReviewFields,
    hypertension_urgent_review: htnUrgentReviewFields,
    hypertension_case_finding: htnCaseFindingFields,
    diabetes_routine_review: diabetesRoutineReviewFields,
    diabetes_urgent_review: diabetesUrgentReviewFields,
  };

export const getFieldsToShow = (
  reviewTemplates: AssessmentTemplate[]
): FieldsSubset => {
  const reviewFields = reviewTemplates.map(
    (name) => reviewTemplateFieldSet[name]
  );
  const fields = reviewFields.reduce(
    (combinedFields, currentFields) => ({
      ...combinedFields,
      ...currentFields,
    }),
    {} // init empty combinedFields
  );

  return fields;
};
