import { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthSuccessResponse, AuthToken } from "../interfaces/auth.interface";
import { URLS } from "../shared/constants";

export const loginURL = () => {
  const location = useLocation();
  const redirectURL = location.pathname + location.search + location.hash;
  return `${URLS.login}?redirect=${encodeURIComponent(redirectURL)}`;
};

export const loginUrlJs = () => {
  const redirectURL = window.location.pathname + window.location.search + window.location.hash;
  return `${URLS.login}?redirect=${encodeURIComponent(redirectURL)}`;
};

const authTokenName = "_nutriCareDashboardAuthToken";

const setToken = (token: string) =>
  window.localStorage.setItem(authTokenName, token);

const getToken = () => window.localStorage.getItem(authTokenName);

const removeToken = () => window.localStorage.removeItem(authTokenName);

export const persistToken = (res: AuthSuccessResponse | null) => {
  if (res !== null) {
    const data: AuthToken = {
      access_token: res.token,
    };
    setToken(JSON.stringify(data));
  } else {
    removeToken();
  }
};

export const loadPersistedToken = (): AuthToken | null => {
  const fromStorage = getToken();
  if (fromStorage === null) return null;

  const data = JSON.parse(fromStorage) as AuthToken;
  if (data.access_token) {
    return {
      ...data,
    };
  }

  removeToken();
  return null;
};

export const tokenExists = (): boolean => loadPersistedToken() !== null;

export type AuthCheckProps = {
  page: ReactElement;
};

export const Authenticate = ({ page }: AuthCheckProps): ReactElement => {
  if (tokenExists()) return page;

  return <Navigate to={loginURL()} />;
};
