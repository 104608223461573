import { Routes, Route, useSearchParams } from "react-router-dom";
import PatientDetailPage from "./pages/PatientDetail";
import { PatientList } from "./pages/PatientList";
import { SignIn } from "./pages/SignIn";
import { URLS } from "./shared/constants";
import { Authenticate } from "./services/auth.service";

interface AppRouterProps {
  defaultRoute: string;
}

const AppRouter = ({ defaultRoute }: AppRouterProps) => {
  const [urlQuery] = useSearchParams();
  const redirectURL = urlQuery.get("redirect") ?? "/";

  return (
    <Routes>
      <Route path={URLS.login} element={<SignIn redirectURL={redirectURL} />} />
      <Route
        path={defaultRoute}
        element={<Authenticate page={<PatientList />} />}
      />
      <Route
        path={URLS.patientList}
        element={<Authenticate page={<PatientList />} />}
      />
      <Route
        path={URLS.patientDetail}
        element={<Authenticate page={<PatientDetailPage />} />}
      />
    </Routes>
  );
};

export default AppRouter;
