import {
  IClinicalReadingTemplate,
} from "../../../interfaces/engangement.interface";
import {
  IReading,
  Parameter,
  ELifestyleParameter,
} from "../../../interfaces/questionnaire.interface";
import { enumKeys } from "../../../interfaces/utils";
import { Reading } from "./Reading";

type LifestyleInfoProps = {
  content: IClinicalReadingTemplate;
  bgColour?: string | undefined;
};

const lifeStyleParameters = enumKeys(ELifestyleParameter) as Parameter[];

const sortByParameter = (a: IReading, b: IReading) =>
  (lifeStyleParameters.includes(a.parameter)
    ? lifeStyleParameters.indexOf(a.parameter)
    : 100) -
  (lifeStyleParameters.includes(b.parameter)
    ? lifeStyleParameters.indexOf(b.parameter)
    : 100);

export const LifestyleReading = ({bgColour, content }: LifestyleInfoProps) => {
  const readings = content?.readings?.flat()?.sort(sortByParameter) ?? [];

  return <Reading bgColour={bgColour} readings={readings} />;
};
