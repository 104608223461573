import { Box, Divider, Grid, Typography, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  activeFormAtom,
  patientForms,
  selectedPatientAtom,
  selectedTaskAtom,
} from "../../../../services/state.service";
import { IBaseTask, ITask } from "../../../../interfaces/task.interface";
import { readable } from "../../../../interfaces/utils";
import { tasKStyle } from "../../utils";

interface TaskProps {
  title: string | undefined;
}

const Tasks = ({ title }: TaskProps) => {
  const { tasks } = useRecoilValue(selectedPatientAtom);
  const setActiveForm = useSetRecoilState(activeFormAtom);
  const setEditTask = useSetRecoilState(selectedTaskAtom);

  const handleAdd = () => {
    setEditTask(null);
    setActiveForm(patientForms.TaskForm);
  };
  const handleEdit = (task: ITask) => {
    setEditTask(task);
    setActiveForm(patientForms.TaskForm);
  };

  return (
    <Box
      sx={{
        mt: 3,
        // height: "50%",
        width: "100%",
      }}
    >
      <Grid container>
        <Grid item md={10}>
          <Typography variant="h6">{title ? `${title}` : ""}</Typography>
        </Grid>
        <Grid item md={2} sx={{ textAlign: "right" }}>
          <IconButton onClick={handleAdd}>
            <AddIcon />
          </IconButton>
        </Grid>
        <Grid item md={12} sx={{ paddingTop: 1 }}>
          <Divider />
        </Grid>
        <Grid
          item
          md={12}
          sx={{ display: "flex", flexDirection: "column", paddingTop: 1 }}
        >
          {tasks &&
            tasks.map((task: IBaseTask) => {
              const taskStyle = tasKStyle[task.type] ?? tasKStyle.default;
              return (
                <Box
                  key={task._id}
                  sx={{
                    display: "flex",
                    border: 0,
                    borderRadius: "3px",
                    boxShadow: 3 ,
                    borderColor: "lightgrey",
                    marginBottom: "5px",
                    padding: "2px 8px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundImage: taskStyle?.backgroundColor,
                    color: taskStyle?.color,
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: 16 }}>
                      {readable(task.type)}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => {
                        handleEdit(task as ITask);
                      }}
                    >
                      <EditIcon
                        sx={{ color: taskStyle?.color, fontSize: 16 }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Tasks;
