import { useFormik } from "formik";
import * as z from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedPatientAtom } from "../../../services/state.service";
import {
  IAlert,
  IPatientPopulated,
} from "../../../interfaces/patient.interface";
import {
  HTTP_SERVICE,
  ErrorResponse,
  isErrorResponse,
} from "../../../services/http.service";

interface AddAlertFormProps {
  closeForm: () => void;
}

const schema = z.object({
  message: z.string().min(4).max(150),
  level: z.string(),
});

export const AddAlertForm = ({ closeForm }: AddAlertFormProps) => {
  const [patient, setPatient] = useRecoilState(selectedPatientAtom);

  const { alerts } = patient;

  const handleUpdateResponse = (updatedPatient: IPatientPopulated) => {
    if (updatedPatient) {
      closeForm();
      setPatient(updatedPatient);
    }
  };

  const formik = useFormik({
    initialValues: {
      message: "",
      level: "normal",
    } as IAlert,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values: IAlert) => {
      HTTP_SERVICE.updatePatient(patient._id, {
        alerts: [...alerts, values],
      }).then((result: IPatientPopulated | ErrorResponse): void => {
        if (!isErrorResponse(result)) {
          handleUpdateResponse(result);
        }
      });
    },
  });

  return (
    <div>
      <Box
        sx={{
          mt: 4,
          height: "50px",
          width: "100%",
        }}
      >
        <Typography variant="h6">Add Alert</Typography>
      </Box>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Message"
              variant="outlined"
              size="small"
              type="text"
              name="message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Level"
              variant="outlined"
              size="small"
              name="level"
              select
              defaultValue=""
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.level && Boolean(formik.errors.level)}
              helperText={formik.touched.level && formik.errors.level}
              fullWidth
            >
              <MenuItem key="normal" value="normal">
                Normal
              </MenuItem>
              <MenuItem key="urgent" value="urgent">
                Urgent
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <Button type="submit" variant="contained" size="small">
              Add
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AddAlertForm;
