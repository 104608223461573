import { Field, FieldProps } from "formik";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { ListOptions, checkValueExists } from "../../../interfaces/utils";

// type SelectOptionType = {
//     key: string;
//     value: string;
// }

interface CheckBoxFieldProps {
  name: string;
  label: string | undefined | null;
  position: "horizontal" | "vertical";
  selectedValue?: string[] | string | unknown[];
  options: ListOptions<unknown>[];
  disabledField?: boolean;
}

const CheckBoxField = (props: CheckBoxFieldProps) => {
  const { label, name, position, selectedValue, options, disabledField } = props;
  return (
    <Field name={name} >
      {(fieldProps: FieldProps<string>) => {
        const { handleChange } = fieldProps.form;
        return (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">{label}</Typography>
            </Grid>
            <FormGroup
              sx={
                position === "horizontal"
                  ? {
                    position: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }
                  : {}
              }
            >
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                options.map((option: ListOptions<any>, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={checkValueExists(selectedValue, option.value)}
                        name={name}
                        value={option.value}
                        onChange={handleChange}
                        disabled={disabledField}
                      />
                    }
                    label={option.label}
                  />
                ))
              }
            </FormGroup>
          </>
        );
      }}
    </Field>
  );
};

export default CheckBoxField;
