import { Divider, Grid, TextField, Typography } from "@mui/material";
import { Field, FieldProps, useFormikContext } from "formik";
import { CheckBoxField, InputField } from "../../../../shared/components";
import { localFormat } from "../../../../services/datetime.service";
import { FollowUp as FollowUpType } from "../../../../interfaces/engangement.interface";
import { EFormType } from "../../../../interfaces/questionnaire.interface";

type FollowUpProps = {
  followUpSubmission: FollowUpType[] | undefined;
};

export const FollowUp = ({ followUpSubmission }: FollowUpProps) => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values } = useFormikContext<any>();

  const findFollowUpProps = (followUp: FollowUpType[] | undefined, formType: EFormType | 'book_follow_up',) => {
    let followUpAttribute;
    if (formType === 'book_follow_up') {
      followUpAttribute = followUp?.find(item => item.type === 'book_follow_up')
    } else {
      followUpAttribute = followUp?.find(item => item.type === 'submit_form' && item.formType === formType)
    }
    return followUpAttribute ? followUpAttribute.type : '';
  }

  return (
    <Grid item container alignItems="flex-end" xs={12} rowSpacing={4} spacing={1}>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Follow Up</Typography>
      </Grid>

      {(followUpSubmission && findFollowUpProps(followUpSubmission, EFormType.blood_pressure_reading_form)) && (
        <Grid item xs={5}>
          <CheckBoxField
            label=""
            name="followUpBpForm"
            position="horizontal"
            selectedValue={values.followUpBpForm ? values.followUpBpForm : ''}
            disabledField={findFollowUpProps(followUpSubmission, EFormType.blood_pressure_reading_form) ? Boolean(true) : Boolean(false)}
            options={[
              {
                key: "blood_pressure_reading_form",
                value: "blood_pressure_reading_form",
                label: "Send SMS > Follow Up BP Form",
              },
            ]}
          />
        </Grid>
      )}

      {(!followUpSubmission) && (
        <Grid item xs={5}>
          <CheckBoxField
            label=""
            name="followUpBpForm"
            position="horizontal"
            selectedValue={values.followUpBpForm ? values.followUpBpForm : ''}

            options={[
              {
                key: "blood_pressure_reading_form",
                value: "blood_pressure_reading_form",
                label: "Send SMS > Follow Up BP Form",
              },
            ]}
          />
        </Grid>
      )}

      {(followUpSubmission && findFollowUpProps(followUpSubmission, EFormType.blood_pressure_reading_form)) && (
        <Field name="followUpBpFormDate">
          {(fieldProps: FieldProps<string[]>) => {
            const { handleChange, handleBlur } = fieldProps.form;
            const { error, touched } = fieldProps.meta;
            return (
              <Grid item xs={3}>
                <TextField
                  label="Follow Up Date"
                  variant="outlined"
                  size="small"
                  type="date"
                  name="followUpBpFormDate"
                  defaultValue={values.followUpBpFormDate ? values.followUpBpFormDate : localFormat(new Date().toISOString())}
                  disabled={findFollowUpProps(followUpSubmission, EFormType.blood_pressure_reading_form) ? Boolean(true) : Boolean(false)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched && Boolean(error)}
                  helperText={touched && error}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: `${localFormat(new Date().toISOString())}`,
                  }}
                  fullWidth
                />
              </Grid>
            );
          }}
        </Field>
      )}

      {(!followUpSubmission) && (
        <Field name="followUpBpFormDate">
          {(fieldProps: FieldProps<string[]>) => {
            const { handleChange, handleBlur } = fieldProps.form;
            const { error, touched } = fieldProps.meta;
            return (
              <Grid item xs={3}>
                <TextField
                  label="Follow Up Date"
                  variant="outlined"
                  size="small"
                  type="date"
                  name="followUpBpFormDate"
                  defaultValue={values.followUpBpFormDate ?? values.followUpBpFormDate }

                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched && Boolean(error)}
                  helperText={touched && error}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: `${localFormat(new Date().toISOString())}`,
                  }}
                  fullWidth
                />
              </Grid>
            );
          }}
        </Field>
      )}

      {(followUpSubmission && findFollowUpProps(followUpSubmission, EFormType.blood_pressure_reading_form)) && (
        <Grid item xs={4}>
          <InputField
            label="Notes"
            name="followUpBpFormNotes"
            defaultValue={values.followUpBpFormNotes ? values.followUpBpFormNotes : ''}
            disabledField={findFollowUpProps(followUpSubmission, EFormType.blood_pressure_reading_form) ? Boolean(true) : Boolean(false)}
          />
        </Grid>
      )}

      {(!followUpSubmission) && (
        <Grid item xs={4}>
          <InputField
            label="Notes"
            name="followUpBpFormNotes"
            defaultValue={values.followUpBpFormNotes ? values.followUpBpFormNotes : ''}

          />
        </Grid>
      )}

      {(followUpSubmission && findFollowUpProps(followUpSubmission, EFormType.lifestyle_questionnaire_form)) && (
        <Grid item xs={5}>
          <CheckBoxField
            label=""
            name="followUpLifestyleForm"
            position="horizontal"
            selectedValue={values.followUpLifestyleForm ? values.followUpLifestyleForm : ''}
            disabledField={findFollowUpProps(followUpSubmission, EFormType.lifestyle_questionnaire_form) ? Boolean(true) : Boolean(false)}
            options={[
              {
                key: "lifestyle_questionnaire_form",
                value: "lifestyle_questionnaire_form",
                label: "Send SMS > Follow Up Lifestyle Form",
              },
            ]}
          />
        </Grid>
      )}

      {(!followUpSubmission) && (
        <Grid item xs={5}>
          <CheckBoxField
            label=""
            name="followUpLifestyleForm"
            position="horizontal"
            selectedValue={values.followUpLifestyleForm ? values.followUpLifestyleForm : ''}

            options={[
              {
                key: "lifestyle_questionnaire_form",
                value: "lifestyle_questionnaire_form",
                label: "Send SMS > Follow Up Lifestyle Form",
              },
            ]}
          />
        </Grid>
      )}

      {(followUpSubmission && findFollowUpProps(followUpSubmission, EFormType.lifestyle_questionnaire_form)) && (
        <Field name="followUpLifestyleFormDate">
          {(fieldProps: FieldProps<string[]>) => {
            const { handleChange, handleBlur } = fieldProps.form;
            const { error, touched } = fieldProps.meta;
            return (
              <Grid item xs={3}>
                <TextField
                  label="Follow Up Date"
                  variant="outlined"
                  size="small"
                  type="date"
                  name="followUpLifestyleFormDate"
                  defaultValue={values.followUpLifestyleFormDate ? values.followUpLifestyleFormDate : localFormat(new Date().toISOString())}
                  disabled={findFollowUpProps(followUpSubmission, EFormType.lifestyle_questionnaire_form) ? Boolean(true) : Boolean(false)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched && Boolean(error)}
                  helperText={touched && error}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: `${localFormat(new Date().toISOString())}`,
                  }}
                  fullWidth
                />
              </Grid>
            );
          }}
        </Field>
      )}

      {(!followUpSubmission) && (
        <Field name="followUpLifestyleFormDate">
          {(fieldProps: FieldProps<string[]>) => {
            const { handleChange, handleBlur } = fieldProps.form;
            const { error, touched } = fieldProps.meta;
            return (
              <Grid item xs={3}>
                <TextField
                  label="Follow Up Date"
                  variant="outlined"
                  size="small"
                  type="date"
                  name="followUpLifestyleFormDate"
                  defaultValue={values.followUpLifestyleFormDate ?? values.followUpLifestyleFormDate }

                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched && Boolean(error)}
                  helperText={touched && error}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: `${localFormat(new Date().toISOString())}`,
                  }}
                  fullWidth
                />
              </Grid>
            );
          }}
        </Field>
      )}

      {(followUpSubmission && findFollowUpProps(followUpSubmission, EFormType.lifestyle_questionnaire_form)) && (
        <Grid item xs={4}>
          <InputField label="Notes"
            name="followUpLifestyleFormNotes"
            defaultValue={values.followUpLifestyleFormNotes ? values.followUpLifestyleFormNotes : ''}
            disabledField={findFollowUpProps(followUpSubmission, EFormType.lifestyle_questionnaire_form) ? Boolean(true) : Boolean(false)}
          />
        </Grid>
      )}

      {(!followUpSubmission) && (
        <Grid item xs={4}>
          <InputField label="Notes"
            name="followUpLifestyleFormNotes"
            defaultValue={values.followUpLifestyleFormNotes ? values.followUpLifestyleFormNotes : ''}

          />
        </Grid>
      )}

      {(followUpSubmission && findFollowUpProps(followUpSubmission, 'book_follow_up')) && (
        <Grid item xs={5}>
          <CheckBoxField
            label=""
            name="bookFollowUpReminder"
            position="horizontal"
            selectedValue={values.bookFollowUpReminder ? values.bookFollowUpReminder : ''}
            disabledField={findFollowUpProps(followUpSubmission, 'book_follow_up') ? Boolean(true) : Boolean(false)}
            options={[
              {
                key: "book_follow_up",
                value: "book_follow_up",
                label: "Remind Me > Book Follow Up",
              },
            ]}
          />
        </Grid>
      )}

      {(!followUpSubmission) && (
        <Grid item xs={5}>
          <CheckBoxField
            label=""
            name="bookFollowUpReminder"
            position="horizontal"
            selectedValue={values.bookFollowUpReminder ? values.bookFollowUpReminder : ''}

            options={[
              {
                key: "book_follow_up",
                value: "book_follow_up",
                label: "Remind Me > Book Follow Up",
              },
            ]}
          />
        </Grid>
      )}

      {(followUpSubmission && findFollowUpProps(followUpSubmission, 'book_follow_up')) && (
        <Field name="bookFollowUpReminderDate">
          {(fieldProps: FieldProps<string[]>) => {
            const { handleChange, handleBlur } = fieldProps.form;
            const { error, touched } = fieldProps.meta;
            return (
              <Grid item xs={3}>
                <TextField
                  label="Follow Up Date"
                  variant="outlined"
                  size="small"
                  type="date"
                  name="bookFollowUpReminderDate"
                  defaultValue={values.bookFollowUpReminderDate ? values.bookFollowUpReminderDate : localFormat(new Date().toISOString())}
                  disabled={findFollowUpProps(followUpSubmission, 'book_follow_up') ? Boolean(true) : Boolean(false)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched && Boolean(error)}
                  helperText={touched && error}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: `${localFormat(new Date().toISOString())}`,
                  }}
                  fullWidth
                />
              </Grid>
            );
          }}
        </Field>
      )}

      {(!followUpSubmission) && (
        <Field name="bookFollowUpReminderDate">
          {(fieldProps: FieldProps<string[]>) => {
            const { handleChange, handleBlur } = fieldProps.form;
            const { error, touched } = fieldProps.meta;
            return (
              <Grid item xs={3}>
                <TextField
                  label="Follow Up Date"
                  variant="outlined"
                  size="small"
                  type="date"
                  name="bookFollowUpReminderDate"
                  defaultValue={values.bookFollowUpReminderDate ?? values.bookFollowUpReminderDate }

                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched && Boolean(error)}
                  helperText={touched && error}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: `${localFormat(new Date().toISOString())}`,
                  }}
                  fullWidth
                />
              </Grid>
            );
          }}
        </Field>
      )}

      {(followUpSubmission && findFollowUpProps(followUpSubmission, 'book_follow_up')) && (
        <Grid item xs={4}>
          <InputField label="Notes"
            name="bookFollowUpReminderNotes"
            defaultValue={values.bookFollowUpReminderNotes ? values.bookFollowUpReminderNotes : ''}
            disabledField={findFollowUpProps(followUpSubmission, 'book_follow_up') ? Boolean(true) : Boolean(false)}
          />
        </Grid>
      )}

      {(!followUpSubmission) && (
        <Grid item xs={4}>
          <InputField label="Notes"
            name="bookFollowUpReminderNotes"
            defaultValue={values.bookFollowUpReminderNotes ? values.bookFollowUpReminderNotes : ''}

          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  )
};
