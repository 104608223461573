import { ReactElement } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

type AppLayoutProps = { page: ReactElement };

const Copyright = () => (
  <Typography variant="body2" color="text.secondary" align="center">
    {"Copyright © "}
    <Link color="inherit" href="/">
      Nutrisphere
    </Link>{" "}
    {new Date().getFullYear()}
  </Typography>
);

const AppLayout = ({ page }: AppLayoutProps) => (
  <>
    <AppBar position="fixed">
      <Toolbar>
        <Link href="/">
          <Box
            component="img"
            sx={{ height: 48 }}
            alt="Logo"
            src='/logo.png'
          />
        </Link>
      </Toolbar>
    </AppBar>
    {/* Page Content */}
    <Box sx={{ my: 2, height: "auto" }}>
      <Container sx={{ py: 8 }} maxWidth="xl">
        {page}
      </Container>
    </Box>
    {/* End Page Content */}
    {/* Footer */}
    <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
      <Typography
        variant="subtitle1"
        align="center"
        color="text.secondary"
        // component="p"
      >
        Health Care Solution
      </Typography>
      <Copyright />
    </Box>
    {/* End footer */}
  </>
);

export default AppLayout;
