import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { Box, Button, Grid, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  bloodPressureStyle,
  BpStage,
  getBloodPressureStatisticsForDateRange,
  getStage,
} from "../../utils";
import {
  BloodPressureStats,
  IEngagement,
} from "../../../../interfaces/engangement.interface";
import {
  DateTime,
  fromDB,
  fromForm,
} from "../../../../services/datetime.service";
import {
  activeFormAtom,
  patientForms,
} from "../../../../services/state.service";

type BloodPressureCardProps = {
  engagements: IEngagement[];
};

const BloodPressureCard = ({ engagements }: BloodPressureCardProps) => {
  const setActiveForm = useSetRecoilState(activeFormAtom);

  const [bloodPressStatistics, setBloodPressStatistics] =
    useState<BloodPressureStats>({
      maxSystolic: null,
      maxDiastolic: null,
      avgSystolic: null,
      avgDiastolic: null,
      from: DateTime.now().minus({ days: 30 }).toISO() as string,
      to: DateTime.now().toISO() as string,
    });
  const [startDate, setStartDate] = useState(bloodPressStatistics.from);
  const [endDate, setEndDate] = useState(bloodPressStatistics.to);

  const handleAdd = () => setActiveForm(patientForms.BloodPressureReadingFrom);

  const getBloodPressureStatistics = (start?: string, end?: string) => {
    setBloodPressStatistics(
      getBloodPressureStatisticsForDateRange(engagements, start, end)
    );
  };

  useEffect(() => {
    getBloodPressureStatistics(startDate, endDate);
  }, [engagements]);

  return (
    <Card sx={{ p: 2, mt: 2 }}>
      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={handleAdd}>Add Blood Pressure Reading</Button>
      </Box>

      <Box sx={{ width: "100%", mb: 2 }}>
        <Card
          sx={{ p: 2 }}
          style={{
            backgroundColor:
              bloodPressureStyle[
                getStage(
                  BpStage.SYSTOLIC,
                  parseInt(bloodPressStatistics.maxSystolic as string, 10)
                )
              ].backgroundColor,
          }}
        >
          <Grid container>
            <Grid item xs={11}>
              <Box sx={{ display: "flex" }}>
                <WaterDropIcon color="primary" fontSize="large" />
                <Typography variant="h5" color="#d4a373" sx={{ mr: 8 }}>
                  Average
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center", pl: 0 }}>
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }} style={{ fontFamily: "DigitalDreamFat", fontSize: 24 }} >
                  {bloodPressStatistics.avgSystolic}/{bloodPressStatistics.avgDiastolic} mmHg
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box sx={{ backgroundColor: "#ef476f", borderRadius: 1, mb: 1 }}>
                {getStage(
                  BpStage.SYSTOLIC,
                  parseInt(bloodPressStatistics.avgSystolic as string, 10)
                ) === "HIGH" ? (
                  <ArrowForwardIcon
                    fontSize="small"
                    style={{ paddingTop: "5px", marginLeft: -22 }}
                  />
                ) : (
                  <FiberManualRecordIcon
                    fontSize="small"
                    style={{
                      paddingTop: "5px",
                      color: "#ef476f",
                      marginLeft: -22,
                    }}
                  />
                )}
              </Box>
              <Box sx={{ backgroundColor: "#ffd166", borderRadius: 1, mb: 1 }}>
                {getStage(
                  BpStage.SYSTOLIC,
                  parseInt(bloodPressStatistics.avgSystolic as string, 10)
                ) === "MODERATE" ? (
                  <ArrowForwardIcon
                    fontSize="small"
                    style={{ paddingTop: "5px", marginLeft: -22 }}
                  />
                ) : (
                  <FiberManualRecordIcon
                    fontSize="small"
                    style={{
                      paddingTop: "5px",
                      color: "#ffd166",
                      marginLeft: -22,
                    }}
                  />
                )}
              </Box>
              <Box sx={{ backgroundColor: "#06d6a0", borderRadius: 1, mb: 1 }}>
                {getStage(
                  BpStage.SYSTOLIC,
                  parseInt(bloodPressStatistics.avgSystolic as string, 10)
                ) === "MILD" ? (
                  <ArrowForwardIcon
                    fontSize="small"
                    style={{ paddingTop: "5px", marginLeft: -22 }}
                  />
                ) : (
                  <FiberManualRecordIcon
                    fontSize="small"
                    style={{
                      paddingTop: "5px",
                      color: "#06d6a0",
                      marginLeft: -22,
                    }}
                  />
                )}
              </Box>
              <Box sx={{ backgroundColor: "#118ab2", borderRadius: 1, mb: 1 }}>
                {getStage(
                  BpStage.SYSTOLIC,
                  parseInt(bloodPressStatistics.avgSystolic as string, 10)
                ) === "NORMAL" ? (
                  <ArrowForwardIcon
                    fontSize="small"
                    style={{ paddingTop: "5px", marginLeft: -22 }}
                  />
                ) : (
                  <FiberManualRecordIcon
                    fontSize="small"
                    style={{
                      paddingTop: "5px",
                      color: "#118ab2",
                      marginLeft: -22,
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Card
              sx={{ p: 2 }}
              style={{
                backgroundColor:
                  bloodPressureStyle[
                    getStage(
                      BpStage.SYSTOLIC,
                      parseInt(bloodPressStatistics.maxSystolic as string, 10)
                    )
                  ].backgroundColor,
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box style={{ marginTop: "4px" }}>
                  <HealthAndSafetyIcon color="primary" />
                </Box>
                <Typography variant="h5" color="#d4a373" sx={{ my: 0 }}>
                  Max Sys
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", pl: 0 }}>
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }} style={{ fontFamily: "DigitalDreamFat", fontSize: 24 }}>
                  {bloodPressStatistics.maxSystolic}
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item md={6} xs={12}>
            <Card
              sx={{ p: 2 }}
              style={{
                backgroundColor:
                  bloodPressureStyle[
                    getStage(
                      BpStage.SYSTOLIC,
                      parseInt(bloodPressStatistics.maxDiastolic as string, 10)
                    )
                  ].backgroundColor,
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box style={{ marginTop: "4px" }}>
                  <HealthAndSafetyIcon color="primary" />
                </Box>
                <Typography variant="h5" color="#d4a373" sx={{ my: 0 }}>
                  Max Dia
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", pl: 0 }}>
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }} style={{ fontFamily: "DigitalDreamFat", fontSize: 24 }}>
                  {bloodPressStatistics.maxDiastolic}
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item md={6} xs={12}>
            <TextField
              label="Start Date"
              variant="outlined"
              size="small"
              type="date"
              defaultValue={fromDB(startDate as string) ?? DateTime.now()}
              onChange={(e) => {
                setStartDate(fromForm(e.target.value));
                getBloodPressureStatistics(fromForm(e.target.value), endDate);
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="End Date"
              variant="outlined"
              size="small"
              type="date"
              defaultValue={fromDB(endDate as string)}
              onChange={(e) => {
                setEndDate(fromForm(e.target.value));
                getBloodPressureStatistics(startDate, fromForm(e.target.value));
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default BloodPressureCard;
