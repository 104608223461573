import { FormType } from "./questionnaire.interface";
import { enumToKeyValuePair } from "./utils";

export enum EGeneralTaskTypes {
  general = "general",
  gp_referral = "gp_referral",
  registration = "registration",
  other_task = "other_task",
}

export type GeneralTaskType = keyof typeof EGeneralTaskTypes;

export const generalTaskTypes = enumToKeyValuePair(EGeneralTaskTypes);

export enum EPatientTaskTypes {
  submit_form = "submit_form", // Consent Form, BP Form, Lifestyle Form, Diabetes Form
}

export type PatientTaskType = keyof typeof EPatientTaskTypes;

export const patientTaskTypes = enumToKeyValuePair(EPatientTaskTypes);

export enum EStaffTaskTypes {
  // Engage
  opt_out_request = 'opt_out_request',
  attempt_to_engage = 'attempt_to_engage',
  attempt_to_re_engage = 'attempt_to_re_engage',
  attempt_to_engage_final = 'attempt_to_engage_final',
  re_engage_window = 're_engage_window',

  // Assessment & Consultation
  clinical_assessment = 'clinical_assessment',
  results_assessment = 'results_assessment',
  urgent_clinical_assessment = 'urgent_clinical_assessment',
  urgent_results_assessment = 'urgent_results_assessment',
  check_results = 'check_results',
  book_follow_up = 'book_follow_up',

  // Reply
  reply_to_phone_call = 'reply_to_phone_call',
  reply_to_voice_message = 'reply_to_voice_message', // watsapp, sms etc
  reply_to_message = 'reply_to_message', // watsapp, sms etc
  reply_to_email = 'reply_to_email',
}

export type StaffTaskType = keyof typeof EStaffTaskTypes;

export const staffTaskTypes = enumToKeyValuePair(EStaffTaskTypes);

export const allTaskTypes = [
  ...generalTaskTypes,
  ...patientTaskTypes,
  ...staffTaskTypes,
];

export type TaskType = GeneralTaskType | PatientTaskType | StaffTaskType;

export enum ETaskStatusTypes {
  active = "active",
  complete = "complete",
  canceled = "canceled",
  draft = "draft",
}

export const taskStatusTypes = enumToKeyValuePair(ETaskStatusTypes);

export type TaskStatusType = keyof typeof ETaskStatusTypes;

export interface ITaskStatus {
  type: TaskStatusType;
  datetime: string;
  user: string;
  note?: string;
}

export enum ETaskNotificationTypes {
  sms = 'sms',
  email = 'email',
  slack = 'slack',
}

export const taskNotificationTypes = enumToKeyValuePair(ETaskNotificationTypes);

export type TaskNotificatioType = keyof typeof ETaskNotificationTypes;

export interface ITaskNotification {
  type: TaskNotificatioType;
  user:string;
  engagement?: string;
  datetime: string;
  reminder?: boolean;
  note?: string;
}

export interface IBaseTask {
  _id?: string;
  type: TaskType;
  user: string;
  patient: string;
  assigne: string;
  start: string;
  end: string;
  status: ITaskStatus;
  notifications?: ITaskNotification[];
  note: string;
  createdAt?: string;
  createdBy?: string;
}

export type IGeneralTask = Omit<IBaseTask, "type"> & {
  type: GeneralTaskType;
};

export type IStaffTask = Omit<IBaseTask, "type"> & {
  type: StaffTaskType;
};

export type IPatientTask = Omit<IBaseTask, "type"> & {
  type: PatientTaskType;
  formType: FormType;
  sets?: number;
  followUp?: boolean;
};

export type ITask = IGeneralTask | IStaffTask | IPatientTask;
