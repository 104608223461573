import { atom, selector } from "recoil";
import { IUser } from "../interfaces/user.interface";
import { IPatientPopulated } from "../interfaces/patient.interface";
import { ITask } from "../interfaces/task.interface";
import { HTTP_SERVICE, isErrorResponse } from "./http.service";
import { IClinicalReview, IConsultationEngagement, IEngagement } from "../interfaces/engangement.interface";
import { FieldsSubset } from "./form-config.service";

export const patientForms = {
  PatientDetailFrom: "PatientDetailFrom",
  MedicalHistoryForm: "MedicalHistoryForm",
  BloodPressureReadingFrom: "BloodPressureReadingFrom",
  TaskForm: "TaskForm",
  AddAlertForm: "AddAlertForm",
  ConsultationForm: "ConsultationForm",
  AssessmentForm: "AssessmentForm",
  DiabetesReadingForm: "DiabetesReadingForm",
  LifestyleReadingForm: "LifestyleReadingForm",
  none: "none",
} as const;

export type FormKey = keyof typeof patientForms;
export type FormType = (typeof patientForms)[FormKey];

export const activeFormAtom = atom<FormType>({
  key: "activeFormAtom",
  default: "none",
});

export const selectedPatientAtom = atom<IPatientPopulated>({
  key: "selectedPatientAtom",
  default: undefined,
});

export const selectedPatientId = selector<string | null>({
  key: "selectedPatientId",
  get: ({ get }) => get(selectedPatientAtom)._id,
});

export const selectedTaskAtom = atom<ITask | null>({
  key: "selectedTaskAtom",
  default: undefined,
});

export const selectedAssessmentAtom = atom<IClinicalReview | undefined>({
  key: "selectedAssessmentAtom",
  default: undefined,
});

export const selectedConsultationAtom = atom<IConsultationEngagement | null>({
  key: "selectedConsultationAtom",
  default: undefined,
});


export const patientListAtom = atom<IPatientPopulated[]>({
  key: "patientListAtom",
  default: [],
});

export const getLoggedUser = selector<IUser | null>({
  key: "loggedUser",
  get: async () => {
    const response = await HTTP_SERVICE.loggedUser();
    if (!isErrorResponse(response)) {
      return response;
    }
    return null;
  },
});

export const userListAtom = atom<IUser[]>({
  key: "userListAtom",
  default: [],
});

export const engagementListAtom = atom<IEngagement[]>({
  key: "engagementListAtom",
  default: [],
});

export const selectedTemplateFieldsAtom = atom<FieldsSubset>({
  key: "selectedTemplateFieldsAtom",
  default: {},
});
